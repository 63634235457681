import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
    name: 'loggedIn',
    initialState: {
        value: 'waiting'
    },
    reducers: {
        setLoggedIn: (state, action) => {
            state.value = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setLoggedIn } = loginSlice.actions

export default loginSlice.reducer