import React, {
  useEffect,
  useState,
  useMemo,
} from 'react'
import {
  Navigate,
  useLocation,
} from 'react-router-dom'
import axios from "axios"
import qs from 'qs'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ExcelJS from 'exceljs'
import Joyride, {
  ACTIONS,
  EVENTS,
  STATUS,
} from 'react-joyride'

import {
  hidePreloader,
  showPreloader,
  ROUTE_PATHS,
} from '../../../library/helper'

function EmissionScope3Category1And2SpendBasedCreateInBulk() {
  const { t, i18n } = useTranslation()
  const account = useSelector((state) => state.account.value)
  const location = useLocation()

  const [redirectEmission, setRedirectEmission] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(0)
  const [selectedOffice, setSelectedOffice] = useState(0)
  const [listCompany, setListCompany] = useState([])
  const [listOffice, setListOffice] = useState([])
  const [selectedFile, setSelectedFile] = useState()

  const [tourSteps, setTourSteps] = useState([])
  const [tourRun, setTourRun] = useState(false)
  const [tourStepIndex, setTourStepIndex] = useState(0)

  const tourInitialSteps = useMemo(
    () => [
      {
        content: t('emission.tour.company2'),
        spotlightClicks: true,
        disableBeacon: true,
        target: '#tour__company',
        title: t('emission.tour.company1'),
      },
      {
        spotlightClicks: true,
        target: '#tour__location',
        title: t('emission.tour.location'),
      },
      {
        spotlightClicks: true,
        target: '#tour__template',
        title: t('emission.tour.template'),
      },
      {
        spotlightClicks: true,
        target: '#tour__instruction',
        title: t('emission.tour.instruction'),
      },
      {
        spotlightClicks: true,
        target: '#tour__attachment',
        title: t('emission.tour.attachment.button'),
      },
      {
        target: '#tour__save',
        title: t('emission.tour.save.bulk'),
      },
    ], [i18n.language])

  useEffect(() => {
    // Wrap inside the if condition below to prevent
    // invalid step warning from Joyride
    if (
      Array.isArray(tourInitialSteps) &&
      tourInitialSteps.length > 0
    ) {
      setTourSteps(tourInitialSteps)
    }
  }, [tourInitialSteps])

  useEffect(() => {
    (async () => {
      showPreloader()

      const optionsGetCompany = {
        withCredentials: true,
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: `${process.env.REACT_APP_BASE_SERVER}/client/company`
      };
      const responseGetCompany = await axios(optionsGetCompany)
      if (responseGetCompany.data.success) {
        setListCompany(responseGetCompany.data.data)
      }

      hidePreloader()
    })()
  }, [i18n.language])

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data

    if (([STATUS.FINISHED, STATUS.SKIPPED].includes(status))) {
      // Need to set our running state to false,
      // so we can restart if we click start again.
      setTourRun(false)
      setTourStepIndex(0)
    } else if (type === EVENTS.TARGET_NOT_FOUND) {
      setTourStepIndex(index - 1)
    } else if (type === EVENTS.STEP_AFTER) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setTourStepIndex(nextStepIndex)
    }
  }

  const startGuideline = () => {
    setTourRun(true)
  }

  const onSelectCompany = async (e) => {
    const companyValue = e.target.value
    const data = { 'coid': companyValue }
    const optionsGetOffice = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/client/office`
    }
    const responseGetOffice = await axios(optionsGetOffice)
    if (responseGetOffice.data.success) {
      setSelectedCompany(companyValue)
      setListOffice(responseGetOffice.data.data)
      setSelectedOffice(0)
    }
  }

  const onSelectOffice = (e) => {
    setSelectedOffice(e.target.value)
  }

  const onSaveRecord = async (e) => {
    e.preventDefault()
    try {
      const data = new FormData();
      data.append('scope', 3)
      data.append('companyId', selectedCompany)
      data.append('locationId', selectedOffice)
      data.append('attachment', selectedFile)

      if (location.pathname === ROUTE_PATHS.scope3category1Create) {
        data.append('scope3CategoryId', 1)
      } else if (location.pathname === ROUTE_PATHS.scope3category2Create) {
        data.append('scope3CategoryId', 2)
      }

      const options = {
        withCredentials: true,
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: data,
        url: `${process.env.REACT_APP_BASE_SERVER}/purchased-and-capital-goods-emission-record-bulk-submission`
      }
      const response = await axios(options)

      if (response.data.ok) {
        const message1 = t('scope3.cat6.submissionSuccessMessage.part1')
        const message2 = t('scope3.cat6.submissionSuccessMessage.part2')
        const message3 = t('scope3.cat6.submissionSuccessMessage.part3')
        Swal.fire({
          title: '',
          html: `<p>${message1} ${response.data?.data?.affectedRows} ${message2} ${response.data?.data?.attachmentAffectedRows} ${message3}</p>`,
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            setRedirectEmission(true)
          }
        })
      }
    } catch (error) {
      console.error(error)
      let message
      if (typeof error.response?.data?.message === 'string') {
        message = error.response.data.message
      } else if (error.message) {
        message = error.message
      }
      Swal.fire({
        title: '',
        icon: 'error',
        html: `<p>${message || 'Please fill all information needs'}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      })
    }
  }

  const onAttachFile = () => {
    document.getElementById('formFile').click()
  }

  const onChangeFile = (e) => {
    const files = e.target.files
    setSelectedFile(files?.[0])
  }

  const onDownloadTemplate = async () => {
    try {
      // Create xlsx file
      const wb = new ExcelJS.Workbook()

      // Excel Properties
      wb.creator = 'TruCount'
      wb.lastModifiedBy = 'Trucount System'
      wb.created = new Date()
      wb.modified = new Date()

      const sheetSetting = {
        views: [
          {
            state: 'frozen',
            xSplit: 1,
            ySplit: 1,
            activeCell: 'A2'
          },
        ],
      }

      let scope3CategoryId
      const tabColor = '0B5394' // #0B5394
      if (location.pathname === ROUTE_PATHS.scope3category1Create) {
        scope3CategoryId = 1
      } else if (location.pathname === ROUTE_PATHS.scope3category2Create) {
        scope3CategoryId = 2
      }

      // Create main sheet
      const ws = wb.addWorksheet(`S3 Cat ${scope3CategoryId}`, {
        ...sheetSetting,
        properties: {
          tabColor: { argb: tabColor },
        }
      })

      // Create example sheet
      const exampleTabWs = wb.addWorksheet(`Example of S3 Cat ${scope3CategoryId}`, sheetSetting)

      // Create helper sheet
      const helperWs = wb.addWorksheet('Helper', {
        state: 'hidden',
      })

      ws.headerFooter.oddFooter = "&LGRI&C&F&RPage &P of &N"
      exampleTabWs.headerFooter.oddFooter = "&LGRI&C&F&RPage &P of &N"

      const response = await axios({
        withCredentials: true,
        method: 'GET',
        headers: { 'content-type': 'application/json' },
        params: {
          scope3CategoryId,
        },
        url: `${process.env.REACT_APP_BASE_SERVER}/purchased-and-capital-goods-template-lookup`,
      })
      if (response.data.ok) {
        if (Array.isArray(response.data.data.factors)) {
          const columns = response.data.data.columns
          const worksheets = [ws, exampleTabWs]

          // TruClimate Category
          const categoryLabels = response.data.data.factors.map(factor => {
            return factor.emission_name
          })
          if (categoryLabels.length > 0) {
            helperWs.getColumn('A').values = categoryLabels

            const rangeStart = 'A1'
            const rangeEnd = `A${categoryLabels.length}`
            wb.definedNames
              .add(
                `Helper!${rangeStart}:${rangeEnd}`,
                'categories',
              )
          }

          // Facility
          const facilityLabels = response.data.data.facilities.map(factor => {
            return factor.office_name
          })
          if (facilityLabels.length > 0) {
            helperWs.getColumn('B').values = facilityLabels

            const rangeStart = 'B1'
            const rangeEnd = `B${facilityLabels.length}`
            wb.definedNames
              .add(
                `Helper!${rangeStart}:${rangeEnd}`,
                'facilities',
              )
          }

          worksheets.forEach(tab => {
            tab.columns = columns
            const firstRow = tab.getRow(1)
            columns.forEach(col => {
              firstRow.getCell(col.key).font = {
                color: { argb: 'FFFFFF' },
              }
              firstRow.getCell(col.key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: tabColor },
              }
              firstRow.getCell(col.key).alignment = {
                vertical: 'middle', horizontal: 'left'
              }
              firstRow.height = 42.5
            })

            // Row
            tab.dataValidations.add("A2:A999", {
              type: 'decimal',
              allowBlank: true,
              operator: 'greaterThanOrEqual',
              formulae: [1],
              error: 'Please provide a numeric value that is greater than or equal to 1',
              errorTitle: 'Invalid Data',
              showErrorMessage: true,
            })
            // TruClimate Category
            tab.dataValidations.add("D2:D999", {
              type: 'list',
              allowBlank: true,
              formulae: [`INDIRECT("categories")`],
              error: 'Please use the drop down to select a valid value',
              errorTitle: 'Invalid Selection',
              showErrorMessage: true,
            })
            // Purchased Date
            const limitYear = new Date().getFullYear() - 2
            const dateDataValidation = {
              type: 'date',
              operator: 'greaterThanOrEqual',
              showErrorMessage: true,
              allowBlank: true,
              // formulae: [new Date(new Date().getFullYear() - 2, 0, 1)], // Limit: from the past 2 years
              error: `Dates prior to January 1, ${limitYear}, are not permitted. Please select a date on or after January 1, ${limitYear}`,
              errorTitle: 'Invalid Date',
            }
            tab.dataValidations.add("E2:E999", dateDataValidation)
            // Quantity
            tab.dataValidations.add("F2:F999", {
              type: 'decimal',
              allowBlank: true,
              operator: 'greaterThan',
              formulae: [0],
              error: 'Please provide a numeric value that is greater than or equal to 0',
              errorTitle: 'Invalid Data Type',
              showErrorMessage: true,
            })
            // Amount spent
            tab.dataValidations.add("H2:H999", {
              type: 'decimal',
              allowBlank: true,
              operator: 'greaterThan',
              formulae: [0],
              error: 'Please provide a numeric value that is greater than or equal to 0',
              errorTitle: 'Invalid Data Type',
              showErrorMessage: true,
            })
            // Currency
            tab.dataValidations.add("I2:I999", {
              type: 'list',
              allowBlank: true,
              formulae: ['"IDR, EUR, USD"'],
              error: 'Please use the drop down to select a valid value',
              errorTitle: 'Invalid Selection',
              showErrorMessage: true,
            })
            // Facility
            tab.dataValidations.add("J2:J999", {
              type: 'list',
              allowBlank: true,
              formulae: [`INDIRECT("facilities")`],
              error: 'Please use the drop down to select a valid value',
              errorTitle: 'Invalid Selection',
              showErrorMessage: true,
            })
            // Has Attachment
            tab.dataValidations.add("N2:N999", {
              type: 'list',
              allowBlank: true,
              formulae: ['"Yes, No"'],
              error: 'Please use the drop down to select a valid value',
              errorTitle: 'Invalid Selection',
              showErrorMessage: true,
            })

          })
          const rows = exampleTabWs.getRows(2, 4)

          const emission_factors = response.data.data.factors
          const dummyCurrency = ['IDR', 'EUR', 'USD']

          rows.forEach((row, rowIndex) => {
            row.getCell('rowNumber').value = rowIndex + 1
            row.getCell('dataSource').value = `Invoice ${rowIndex + 1}`
            row.getCell('itemName').value = `Kertas HVS A${rowIndex + 4} (Example #${rowIndex + 1})`

            const randomFactorIndex = Math.floor(Math.random() * emission_factors.length)

            row.getCell('truClimateCategory').value = emission_factors[randomFactorIndex]?.emission_name

            let purchasedDate = new Date()
            purchasedDate.setDate(purchasedDate.getDate() - 6 - rowIndex)
            row.getCell('purchasedDate').value = purchasedDate

            row.getCell('quantity').value = rowIndex === 3 ? 4 : 5
            row.getCell('unit').value = 'Rim'
            row.getCell('amountSpent').value = 1000
            row.getCell('currency').value = dummyCurrency[rowIndex] ? dummyCurrency[rowIndex] : dummyCurrency[0]
            row.getCell('facility').value = facilityLabels[rowIndex] ? facilityLabels[rowIndex] : facilityLabels[0]
            row.getCell('dataOwner').value = 'Procurement Department'
            row.getCell('verifiedBy').value = 'Finance Department'
            row.getCell('hasAttachment').value = rowIndex === 1 ? 'Yes' : ''
          })

          const fileName = `Category ${scope3CategoryId} Scope 3 TruCount Template_${account.client_name}`
          wb.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
            const url = window.URL.createObjectURL(blob)
            const anchor = document.createElement("a")
            anchor.href = url
            anchor.download = `${fileName}.xlsx`
            anchor.click()
            window.URL.revokeObjectURL(url)
          })
        }
      }
    } catch (e) {
      console.error(e)
      let message
      if (typeof e.response?.data?.message === 'string') {
        message = e.response.data.message
      } else if (e.message) {
        message = e.message
      }
      Swal.fire({
        icon: 'error',
        title: '',
        html: `<p style="font-size:0.75rem;">${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      })
    }
  }

  let pageTitle
  let navigateTo
  if (location.pathname === ROUTE_PATHS.scope3category1Create) {
    pageTitle = t('menu.scope3Category1Title')
    navigateTo = ROUTE_PATHS.scope3category1
  } else if (location.pathname === ROUTE_PATHS.scope3category2Create) {
    pageTitle = t('menu.scope3Category2Title')
    navigateTo = ROUTE_PATHS.scope3category2
  }

  if (redirectEmission) {
    return <Navigate to={navigateTo} />
  } else {
    const optionCompany = [
      ...selectedCompany <= 0
        ? [<option key={0} value={0}>
          {t('emission.form.placeholder1')}
        </option>]
        : [],
      ...listCompany.map((item) => {
        return <option key={item.company_id} value={item.company_id}>
          {item.company_name}
        </option>
      }),
    ]

    const optionOffice = [
      ...selectedOffice <= 0
        ? [<option key={0} value={0}>
          {t('emission.form.placeholder2')}
        </option>]
        : [],
      ...listOffice.map((item) => {
        return <option key={item.office_id} value={item.office_id}>
          {item.office_name}
        </option>
      }),
    ]

    return (
      <div className="page-content">
        <div className="container-fluid">

          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <div className="d-flex gap-3 mb-sm-0 align-items-baseline">
                  <h4 id="tour__header">
                    {pageTitle}
                  </h4>
                  <button
                    onClick={startGuideline}
                    className="btn btn-outline-info btn-sm"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Start Guideline"
                  >
                    <i className="ri-question-fill"></i>
                  </button>
                  <Joyride
                    callback={handleJoyrideCallback}
                    continuous
                    run={tourRun}
                    scrollToFirstStep
                    showProgress
                    showSkipButton
                    stepIndex={tourStepIndex}
                    steps={tourSteps}
                    styles={{
                      options: {
                        primaryColor: '#000',
                        width: 500,
                        zIndex: 10000,
                      }
                    }}
                    locale={{
                      back: t('emission.tour.back'),
                      close: t('emission.tour.close'),
                      last: t('emission.tour.last'),
                      next: t('emission.tour.next'),
                      open: t('emission.tour.open'),
                      skip: t('emission.tour.skip'),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {t('general.btnCreateInBulk')}
                  </h4>
                </div>

                <div className="card-body">
                  <h5>{t('emission.form.info1')}</h5>
                  <p className='mb-3'>{t('emission.form.subInfo1')}</p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3" id="tour__company">
                        <label htmlFor="company" className="form-label">
                          {t('emission.form.label1')}
                        </label>
                        <select
                          onChange={onSelectCompany}
                          value={selectedCompany}
                          id="company"
                          name="company"
                          className="form-select mb-3"
                        >
                          {optionCompany}
                        </select>
                      </div>
                    </div>
                    {selectedCompany > 0 ? <div className="col-md-6">
                      <div className="mb-3" id="tour__location">
                        <label htmlFor="office" className="form-label">
                          {t('emission.table.label2')}
                        </label>
                        <select
                          onChange={onSelectOffice}
                          value={selectedOffice}
                          id="company"
                          name="company"
                          className="form-select mb-3"
                        >
                          {optionOffice}
                        </select>
                      </div>
                    </div> : ""}
                  </div>

                  <div className="row">
                    <h5 className="pt-2">{t('emission.form.info2')}</h5>
                    <p className="mb-3">{t('emission.form.subInfo2')}</p>

                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <button
                            id="tour__template"
                            className='btn btn-success'
                            onClick={onDownloadTemplate}
                          >
                            {t('general.btnDownloadTemplate')}
                          </button>
                          <div className="mb-3 mt-3">
                            <label htmlFor="tour__instruction" className="form-label">
                              {t('emission.form.label15')}
                            </label>
                            <div style={{ width: 'fit-content' }}>
                              <a
                                className='d-block mb-3'
                                id="tour__instruction"
                                target="_blank"
                                rel="noreferrer"
                                // TODO: Update instruction link to the correct one
                                href="https://truclimate.notion.site/truclimate/6509a1381b794aaca4ebe012891259af"
                              >
                                {t('emission.form.instruction')}
                              </a>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'baseline',
                                gap: '0.5rem',
                              }}
                            >
                              <button
                                onClick={onAttachFile}
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                                id="tour__attachment"
                              >
                                {t('emission.form.label17')}
                              </button>
                              <p className="text-muted mb-0">{selectedFile?.name}</p>
                            </div>
                            <input
                              onChange={onChangeFile}
                              style={{ display: "none" }}
                              className="form-control"
                              type="file"
                              id="formFile"
                              accept=".zip"
                            />
                          </div>
                          <div className="col-md-6">
                            <p>{t('emission.form.label18')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="text-end">
                        <button
                          onClick={onSaveRecord}
                          type="submit"
                          className="btn btn-primary"
                          id="tour__save"
                        >
                          {t('general.btnSave')}
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EmissionScope3Category1And2SpendBasedCreateInBulk
