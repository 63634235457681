import React, { Component } from 'react'

import { Chart } from 'react-google-charts'

import { connect } from 'react-redux'
import { increment, decrement } from '../../features/counter/counterSlice'
import { setLoggedIn } from '../../features/account/loginSlice'
import { setAccount } from '../../features/account/accountSlice'
import { setCompany } from '../../features/account/companySlice'

import axios from 'axios'
import qs from 'qs'

import html2canvas from 'html2canvas'
import ReactApexChart from 'react-apexcharts'
import ApexCharts from 'apexcharts'

import BoxItem from '../../components/Dashboard/Cards/BoxItem'
import Grid from '../../components/Dashboard/Layouts/Grid'
import BarChartLegend from '../../components/ApexCharts/BarChartLegend'
import Download from '../../components/Download'

import {
  hidePreloader,
  showPreloader,
  twoDecimalFormat,
  CHART_COLORS,
} from '../../library/helper';

import { withTranslation } from 'react-i18next';

const emptySeries = [
  {
    "name": "Scope 1",
    "nameId": "Cakupan 1",
    "data": [
      0.61,
      0.59,
      0.09,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0
    ]
  },
  {
    "name": "Scope 2",
    "nameId": "Cakupan 2",
    "data": [
      4.78,
      4.49,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0
    ]
  },
  {
    "name": "Scope 2",
    "nameId": "Cakupan 3",
    "data": [
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0
    ]
  }
]

const Value = ({
  value,
}) => {
  if (!value) {
    return <span style={{ fontSize: '13px' }}>No Data</span>
  } else {
    return (
      <span>
        {value}
      </span>
    )
  }
}
const ValueAndUnit = ({
  value,
  divisor = null,
  unit = null,
}) => {
  if (!value) {
    return (
      <span style={{ fontSize: '13px' }}>
        No Data
      </span>
    )
  } else {
    return (
      <>
        <span>
          {value}
        </span>{' '}
        <span
          style={{
            fontSize: "var(--vz-body-font-size)",
            textWrap: "nowrap",
          }}
          className="text-muted"
        >
          {unit ? unit : <span>tCO<sub>2</sub>e{divisor && `/${divisor}`}</span>}
        </span>
      </>
    )
  }
}

export class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {

      listLocation: [],
      listYear: [],

      selectedLocation: 0,
      selectedYear: 0,

      totalEmissionAnalytics: 0,
      highestEmissionCategory: "",
      highestEmissionCategoryId: "",

      totalScopeMonthlyEmission: [],
      totalCategoryMonthlyEmission: [],
      monthlyTotalEmissionByScope: [],

      // old data
      currentYear: new Date().getFullYear() - 1,


      //dashboard
      totalSummaryMonthlyEmission: 0,

      selectedMode: 0,
      selectedCompany: 1,
      totalSummaryYearlyEmission: [],


      totalSummaryYearlyEmissionDummy: [
        ["Company", "Total Emission", "Total Reduction"],
        ["Jan", 156, -33],
        ["Feb", 110, -43],
        ["Mar", 123, -10],
        ["Apr", 156, -33],
        ["May", 110, -43],
        ["Jun", 123, -10],
        ["Jul", 156, -33],
        ["Aug", 110, -43],
        ["Sep", 123, -10],
        ["Oct", 156, -33],
        ["Nov", 110, -43],
        ["Dec", 123, -10],
      ],

      revenueUnit: "",

      selectedScenario: 1,

      scenarioAnalysisData: [],

      divisionYearlyEmission: [],

      hiddenSeries: [],
    }
  }

  componentDidMount = async () => {
    showPreloader();

    let selectedLanguage = this.props.i18n.language;

    let listLocation = [];
    let listYear = [];

    let listCompany = [];
    let listCategory = [];
    let storeScopeMonthlyEmission = [];
    let storeCategoryMonthlyEmission = [];

    let storeSummaryMonthlyEmission = [];
    let labelCompanySummary = [];
    let dataSummary1 = [];
    let dataSummary2 = [];
    let dataSummary3 = [];
    let dataSummary4 = [];
    let dataSummary5 = [];
    let dataSummary6 = [];
    let dataSummary7 = [];
    let dataSummary8 = [];
    let dataSummary9 = [];
    let dataSummary10 = [];
    let dataSummary11 = [];
    let dataSummary12 = [];
    labelCompanySummary.push('Company');
    dataSummary1.push('Jan');
    dataSummary2.push('Feb');
    dataSummary3.push('Mar');
    dataSummary4.push('Apr');
    dataSummary5.push('May');
    dataSummary6.push('Jun');
    dataSummary7.push('Jul');
    dataSummary8.push('Aug');
    dataSummary9.push('Sep');
    dataSummary10.push('Oct');
    dataSummary11.push('Nov');
    dataSummary12.push('Dec');

    let selectedCompany = [];

    let scenarioAnalysisData = [];

    const data = {
      'year': this.state.selectedYear
    };

    const optionsGetYearRecorded = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/year_recorded`
    };
    const responseGetYearRecorded = await axios(optionsGetYearRecorded);
    if (responseGetYearRecorded.data.success) {
      listYear = responseGetYearRecorded.data.data
    }

    const optionsGetEmissionCategory = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/category_all`
    };
    const responseGetEmissionCategory = await axios(optionsGetEmissionCategory);
    if (responseGetEmissionCategory.data.success) {
      listCategory = responseGetEmissionCategory.data.data
    }

    let labelCategorySummary = [];
    labelCategorySummary.push('Category');
    listCategory.forEach(element => {
      if (selectedLanguage === "en") {
        labelCategorySummary.push(element.category_name);
      } else {
        labelCategorySummary.push(element.category_name_id);
      }
    })

    this.getLocation()
      .then((result) => {
        if (result !== null) {
          listLocation = result;
        }
      }).then(() => {

      })

    this.getCompany()
      .then((result) => {
        if (result !== null) {
          listCompany = result;
        }
      }).then(() => {

        listCompany.map(async element => {
          selectedCompany.push(element.company_id)
          this.getSummaryMonthly(this.state.selectedYear, element.company_id)
            .then((responseGetSummaryMonthly) => {
              if (responseGetSummaryMonthly !== null) {
                labelCompanySummary.push(element.company_name);

                let dataRows = responseGetSummaryMonthly;
                dataRows.forEach((element, index) => {
                  // eslint-disable-next-line
                  if (index == 0) {
                    dataSummary1.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 1) {
                    dataSummary2.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 2) {
                    dataSummary3.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 3) {
                    dataSummary4.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 4) {
                    dataSummary5.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 5) {
                    dataSummary6.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 6) {
                    dataSummary7.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 7) {
                    dataSummary8.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 8) {
                    dataSummary9.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 9) {
                    dataSummary10.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 10) {
                    dataSummary11.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 11) {
                    dataSummary12.push(element.total);
                  }
                });
              }
            }).then(() => {

            })

          this.getScopeMonthly(element.company_id, this.state.selectedYear)
            .then((responseGetScopeMonthly) => {
              if (responseGetScopeMonthly !== null) {
                let labelScopeSummary = [];

                labelScopeSummary.push('Emission');
                labelScopeSummary.push('Scope 1');
                labelScopeSummary.push('Scope 2');
                // eslint-disable-next-line
                if (this.props.account['client_scope'] == 1) {
                  labelScopeSummary.push('Scope 3');
                }

                let resultScopeSummary = [];
                resultScopeSummary.push(labelScopeSummary);
                let dataRows = responseGetScopeMonthly;
                dataRows.forEach(element => {
                  let dataScopeSummary = [];
                  dataScopeSummary.push(element.month_name)
                  dataScopeSummary.push(element.total_1)
                  dataScopeSummary.push(element.total_2)
                  // eslint-disable-next-line
                  if (this.props.account['client_scope'] == 1) {
                    dataScopeSummary.push(element.total_3)
                  }
                  resultScopeSummary.push(dataScopeSummary)
                });
                storeScopeMonthlyEmission.push(resultScopeSummary);
              }
            }).then(() => {
              storeSummaryMonthlyEmission[0] = labelCompanySummary;
              storeSummaryMonthlyEmission[1] = dataSummary1;
              storeSummaryMonthlyEmission[2] = dataSummary2;
              storeSummaryMonthlyEmission[3] = dataSummary3;
              storeSummaryMonthlyEmission[4] = dataSummary4;
              storeSummaryMonthlyEmission[5] = dataSummary5;
              storeSummaryMonthlyEmission[6] = dataSummary6;
              storeSummaryMonthlyEmission[7] = dataSummary7;
              storeSummaryMonthlyEmission[8] = dataSummary8;
              storeSummaryMonthlyEmission[9] = dataSummary9;
              storeSummaryMonthlyEmission[10] = dataSummary10;
              storeSummaryMonthlyEmission[11] = dataSummary11;
              storeSummaryMonthlyEmission[12] = dataSummary12;

              this.setState({
                listCompany: listCompany,
                totalScopeMonthlyEmission: storeScopeMonthlyEmission,
                totalSummaryYearlyEmission: storeSummaryMonthlyEmission,
              })
            })

          this.getCategoryMonthly(element.company_id, this.state.selectedYear)
            .then((responseGetCategoryMonthly) => {
              if (responseGetCategoryMonthly !== null) {
                let resultCategorySummary = [];
                resultCategorySummary.push(labelCategorySummary);
                let dataRows = responseGetCategoryMonthly;
                dataRows.forEach(element => {
                  let dataCategorySummary = [];
                  dataCategorySummary.push(element.month_name)
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 1).length > 0) {
                    dataCategorySummary.push(element.total_1)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 2).length > 0) {
                    dataCategorySummary.push(element.total_2)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 3).length > 0) {
                    dataCategorySummary.push(element.total_3)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 4).length > 0) {
                    dataCategorySummary.push(element.total_4)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 5).length > 0) {
                    dataCategorySummary.push(element.total_5)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 6).length > 0) {
                    dataCategorySummary.push(element.total_6)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 7).length > 0) {
                    dataCategorySummary.push(element.total_7)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 8).length > 0) {
                    dataCategorySummary.push(element.total_8)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 9).length > 0) {
                    dataCategorySummary.push(element.total_9)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 10).length > 0) {
                    dataCategorySummary.push(element.total_10)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 11).length > 0) {
                    dataCategorySummary.push(element.total_11)
                  }
                  if (listCategory.filter(p => p.category_id == 12).length > 0) {
                    dataCategorySummary.push(element.total_12)
                  }
                  if (listCategory.filter(p => p.category_id == 13).length > 0) {
                    dataCategorySummary.push(element.total_13)
                  }
                  if (listCategory.filter(p => p.category_id == 14).length > 0) {
                    dataCategorySummary.push(element.total_14)
                  }
                  if (listCategory.filter(p => p.category_id == 15).length > 0) {
                    dataCategorySummary.push(element.total_15)
                  }
                  if (listCategory.filter(p => p.category_id == 16).length > 0) {
                    dataCategorySummary.push(element.total_16)
                  }
                  if (listCategory.filter(p => p.category_id == 17).length > 0) {
                    dataCategorySummary.push(element.total_17)
                  }
                  resultCategorySummary.push(dataCategorySummary)
                });
                storeCategoryMonthlyEmission.push(resultCategorySummary);
              }
            }).then(() => {
              this.setState({
                totalCategoryMonthlyEmission: storeCategoryMonthlyEmission,
              })
            })
        })
      })

    const optionsTotalEmissionCurrentYear = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission_current`
    };
    let calcTotalEmissionCurrentYear = [];
    let growth = 0;
    let growthValue = true;
    await axios(optionsTotalEmissionCurrentYear).then((responseTotalEmissionCurrentYear) => {
      if (responseTotalEmissionCurrentYear.data.success) {
        responseTotalEmissionCurrentYear.data.data.forEach(element => {
          calcTotalEmissionCurrentYear.push(element.total);
        });
        growth = ((parseFloat(calcTotalEmissionCurrentYear[0]) - parseFloat(calcTotalEmissionCurrentYear[1])) / parseFloat(calcTotalEmissionCurrentYear[1])) * 100;
        let growthNumber = Math.sign(growth)
        if (Math.sign(growthNumber) === 1) {
          growthValue = false;
        }
        if (isNaN(growth)) {
          growthValue = false;
        }
        this.setState({
          totalEmissionGrowth: growth,
          totalEmissionGrowthValue: growthValue,
        })
      }
    });

    const optionsTotalCategoryEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission_category`
    };
    const responseTotalCategoryEmission = await axios(optionsTotalCategoryEmission);
    const categoryMonthlyEmission = [];
    let calcHighestCategory = 0;
    let highestCategory = "";
    categoryMonthlyEmission.push(["Category", "Emission (tCO<sub>2</sub>e)"]);
    if (responseTotalCategoryEmission.data.success) {
      responseTotalCategoryEmission.data.data.forEach(element => {
        if (element.total_emission > calcHighestCategory) {
          calcHighestCategory = element.total_emission;
          if (selectedLanguage === "en") {
            highestCategory = element.category_name;
          } else {
            highestCategory = element.category_name_id;
          }
        }
        if (selectedLanguage === "en") {
          categoryMonthlyEmission.push([element.category_name, element.total_emission])
        } else {
          categoryMonthlyEmission.push([element.category_name_id, element.total_emission])
        }
      });
    }

    const optionsDivisionYearlyEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission_division`
    };
    const responseDivisionYearlyEmission = await axios(optionsDivisionYearlyEmission);
    const divisionYearlyEmission = [];
    //let calcHighestCategory = 0;
    //let highestCategory = "";
    divisionYearlyEmission.push(["Division", "Emission (tCO<sub>2</sub>e)"]);
    if (responseDivisionYearlyEmission.data.success) {
      responseDivisionYearlyEmission.data.data.forEach(element => {
        /*if(element.total_emission > calcHighestCategory){
            calcHighestCategory = element.total_emission;
            highestCategory = element.category_name;
        }*/
        divisionYearlyEmission.push([element.group_name, element.total_emission])
      });
    }

    const optionsCompanyYearlyEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/company_yearly_emission`
    };
    const responseCompanyYearlyEmission = await axios(optionsCompanyYearlyEmission);
    const companyYearlyEmission = [];
    let highestCompany = "";
    companyYearlyEmission.push(["Company", "Emission (tCO<sub>2</sub>e)"]);
    if (responseCompanyYearlyEmission.data.success) {
      responseCompanyYearlyEmission.data.data.forEach((element, index) => {
        // eslint-disable-next-line
        if (index == 0) {
          highestCompany = element.company_name;
        }
        companyYearlyEmission.push([element.company_name, element.total_emission])
      });
    }

    const optionsTotalEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission`
    };
    const responseTotalEmission = await axios(optionsTotalEmission);
    let totalEmission = [];
    if (responseTotalEmission.data.success) {
      totalEmission = responseTotalEmission.data.data;

      this.setState({
        totalEmission: totalEmission,
      })
    }

    const optionsTotalRevenue = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_revenue`
    };
    const responseTotalRevenue = await axios(optionsTotalRevenue);
    let totalRevenue = [];
    if (responseTotalRevenue.data.success) {
      totalRevenue = responseTotalRevenue.data.data;
      this.setState({
        totalRevenue: totalRevenue,
      })
    }

    /*let ctrLastyear = 0;
    let ctrPrevyear = 0;
    ctrLastyear = parseFloat(totalEmission[0].emission_total) / parseFloat(totalRevenue[1].revenue_total);
    ctrPrevyear = parseFloat(totalEmission[0].emission_total) / parseFloat(totalRevenue[1].revenue_total);*/

    const optionsSetting = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/account/setting/read`
    };
    const responseSetting = await axios(optionsSetting);
    let tempRevenueUnit = "";
    if (responseSetting.data.success) {
      // eslint-disable-next-line
      if (responseSetting.data.data[0].client_revenue_unit == 1) {
        tempRevenueUnit = "IDR mn";
      } else {
        tempRevenueUnit = "USD k";
      }
    }

    this.setState({
      categoryMonthlyEmission: categoryMonthlyEmission,
      companyYearlyEmission: companyYearlyEmission,
      totalEmissionCurrentYear: calcTotalEmissionCurrentYear,
      divisionYearlyEmission: divisionYearlyEmission,
      highestCategoryEmission: highestCategory,
      highestCompanyEmission: highestCompany,
      revenueUnit: tempRevenueUnit,
      listYear: listYear,
      listLocation: listLocation,
      selectedScenario: responseSetting.data.data[0].client_scenario,

      scenarioAnalysisData: scenarioAnalysisData,
    })


    hidePreloader();
  }

  onReload = async () => {
    showPreloader();
    let listCompany = [];

    let selectedLanguage = this.props.i18n.language;

    let listCategory = [];
    let storeScopeMonthlyEmission = [];
    let storeCategoryMonthlyEmission = [];

    let storeSummaryMonthlyEmission = [];
    let labelCompanySummary = [];
    let dataSummary1 = [];
    let dataSummary2 = [];
    let dataSummary3 = [];
    let dataSummary4 = [];
    let dataSummary5 = [];
    let dataSummary6 = [];
    let dataSummary7 = [];
    let dataSummary8 = [];
    let dataSummary9 = [];
    let dataSummary10 = [];
    let dataSummary11 = [];
    let dataSummary12 = [];
    labelCompanySummary.push('Company');
    dataSummary1.push('Jan');
    dataSummary2.push('Feb');
    dataSummary3.push('Mar');
    dataSummary4.push('Apr');
    dataSummary5.push('May');
    dataSummary6.push('Jun');
    dataSummary7.push('Jul');
    dataSummary8.push('Aug');
    dataSummary9.push('Sep');
    dataSummary10.push('Oct');
    dataSummary11.push('Nov');
    dataSummary12.push('Dec');

    let selectedCompany = [];

    const data = {
      'year': this.state.selectedYear
    };

    const optionsGetEmissionCategory = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/category_all`
    };
    const responseGetEmissionCategory = await axios(optionsGetEmissionCategory);
    if (responseGetEmissionCategory.data.success) {
      listCategory = responseGetEmissionCategory.data.data
    }

    let labelCategorySummary = [];
    labelCategorySummary.push('Category');
    listCategory.forEach(element => {
      if (selectedLanguage === "en") {
        labelCategorySummary.push(element.category_name);
      } else {
        labelCategorySummary.push(element.category_name_id);
      }
    })

    this.getCompany()
      .then((result) => {
        if (result !== null) {
          listCompany = result;
        }
      }).then(() => {

        listCompany.map(async element => {
          selectedCompany.push(element.company_id)
          this.getSummaryMonthly(this.state.selectedYear, element.company_id)
            .then((responseGetSummaryMonthly) => {
              if (responseGetSummaryMonthly !== null) {
                labelCompanySummary.push(element.company_name);

                let dataRows = responseGetSummaryMonthly;
                dataRows.forEach((element, index) => {
                  // eslint-disable-next-line
                  if (index == 0) {
                    dataSummary1.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 1) {
                    dataSummary2.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 2) {
                    dataSummary3.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 3) {
                    dataSummary4.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 4) {
                    dataSummary5.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 5) {
                    dataSummary6.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 6) {
                    dataSummary7.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 7) {
                    dataSummary8.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 8) {
                    dataSummary9.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 9) {
                    dataSummary10.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 10) {
                    dataSummary11.push(element.total);
                    // eslint-disable-next-line
                  } else if (index == 11) {
                    dataSummary12.push(element.total);
                  }
                });
              }
            }).then(() => {

            })

          this.getScopeMonthly(element.company_id, this.state.selectedYear)
            .then((responseGetScopeMonthly) => {
              if (responseGetScopeMonthly !== null) {
                let labelScopeSummary = [];

                labelScopeSummary.push('Emission');
                labelScopeSummary.push('Scope 1');
                labelScopeSummary.push('Scope 2');
                // eslint-disable-next-line
                if (this.props.account['client_scope'] == 1) {
                  labelScopeSummary.push('Scope 3');
                }

                let resultScopeSummary = [];
                resultScopeSummary.push(labelScopeSummary);
                let dataRows = responseGetScopeMonthly;
                dataRows.forEach(element => {
                  let dataScopeSummary = [];
                  dataScopeSummary.push(element.month_name)
                  dataScopeSummary.push(element.total_1)
                  dataScopeSummary.push(element.total_2)
                  // eslint-disable-next-line
                  if (this.props.account['client_scope'] == 1) {
                    dataScopeSummary.push(element.total_3)
                  }
                  resultScopeSummary.push(dataScopeSummary)
                });
                storeScopeMonthlyEmission.push(resultScopeSummary);
              }
            }).then(() => {
              storeSummaryMonthlyEmission[0] = labelCompanySummary;
              storeSummaryMonthlyEmission[1] = dataSummary1;
              storeSummaryMonthlyEmission[2] = dataSummary2;
              storeSummaryMonthlyEmission[3] = dataSummary3;
              storeSummaryMonthlyEmission[4] = dataSummary4;
              storeSummaryMonthlyEmission[5] = dataSummary5;
              storeSummaryMonthlyEmission[6] = dataSummary6;
              storeSummaryMonthlyEmission[7] = dataSummary7;
              storeSummaryMonthlyEmission[8] = dataSummary8;
              storeSummaryMonthlyEmission[9] = dataSummary9;
              storeSummaryMonthlyEmission[10] = dataSummary10;
              storeSummaryMonthlyEmission[11] = dataSummary11;
              storeSummaryMonthlyEmission[12] = dataSummary12;

              this.setState({
                listCompany: listCompany,
                totalScopeMonthlyEmission: storeScopeMonthlyEmission,
                totalSummaryYearlyEmission: storeSummaryMonthlyEmission,
              })
            })

          this.getCategoryMonthly(element.company_id, this.state.selectedYear)
            .then((responseGetCategoryMonthly) => {
              if (responseGetCategoryMonthly !== null) {
                let resultCategorySummary = [];
                resultCategorySummary.push(labelCategorySummary);
                let dataRows = responseGetCategoryMonthly;
                dataRows.forEach(element => {
                  let dataCategorySummary = [];
                  dataCategorySummary.push(element.month_name)
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 1).length > 0) {
                    dataCategorySummary.push(element.total_1)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 2).length > 0) {
                    dataCategorySummary.push(element.total_2)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 3).length > 0) {
                    dataCategorySummary.push(element.total_3)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 4).length > 0) {
                    dataCategorySummary.push(element.total_4)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 5).length > 0) {
                    dataCategorySummary.push(element.total_5)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 6).length > 0) {
                    dataCategorySummary.push(element.total_6)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 7).length > 0) {
                    dataCategorySummary.push(element.total_7)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 8).length > 0) {
                    dataCategorySummary.push(element.total_8)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 9).length > 0) {
                    dataCategorySummary.push(element.total_9)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 10).length > 0) {
                    dataCategorySummary.push(element.total_10)
                  }
                  // eslint-disable-next-line
                  if (listCategory.filter(p => p.category_id == 11).length > 0) {
                    dataCategorySummary.push(element.total_11)
                  }
                  if (listCategory.filter(p => p.category_id == 12).length > 0) {
                    dataCategorySummary.push(element.total_12)
                  }
                  resultCategorySummary.push(dataCategorySummary)
                });
                storeCategoryMonthlyEmission.push(resultCategorySummary);
              }
            }).then(() => {
              this.setState({
                totalCategoryMonthlyEmission: storeCategoryMonthlyEmission,
              })
            })
        })
      })

    const optionsTotalEmissionCurrentYear = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission_current`
    };
    let calcTotalEmissionCurrentYear = [];
    let growth = 0;
    let growthValue = true;
    await axios(optionsTotalEmissionCurrentYear).then((responseTotalEmissionCurrentYear) => {
      if (responseTotalEmissionCurrentYear.data.success) {
        responseTotalEmissionCurrentYear.data.data.forEach(element => {
          calcTotalEmissionCurrentYear.push(element.total);
        });
        growth = ((parseFloat(calcTotalEmissionCurrentYear[0]) - parseFloat(calcTotalEmissionCurrentYear[1])) / parseFloat(calcTotalEmissionCurrentYear[1])) * 100;
        let growthNumber = Math.sign(growth)
        if (Math.sign(growthNumber) === 1) {
          growthValue = false;
        }
        if (isNaN(growth)) {
          growthValue = false;
        }
        this.setState({
          totalEmissionGrowth: growth,
          totalEmissionGrowthValue: growthValue,
        })
      }
    });

    const optionsTotalCategoryEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission_category`
    };
    const responseTotalCategoryEmission = await axios(optionsTotalCategoryEmission);
    const categoryMonthlyEmission = [];
    let calcHighestCategory = 0;
    let highestCategory = "";
    categoryMonthlyEmission.push(["Category", "Emission (tCO<sub>2</sub>e)"]);
    if (responseTotalCategoryEmission.data.success) {
      responseTotalCategoryEmission.data.data.forEach(element => {
        if (element.total_emission > calcHighestCategory) {
          calcHighestCategory = element.total_emission;
          if (selectedLanguage === "en") {
            highestCategory = element.category_name;
          } else {
            highestCategory = element.category_name_id;
          }
        }
        if (selectedLanguage === "en") {
          categoryMonthlyEmission.push([element.category_name, element.total_emission])
        } else {
          categoryMonthlyEmission.push([element.category_name_id, element.total_emission])
        }
      });
    }

    const optionsDivisionYearlyEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission_division`
    };
    const responseDivisionYearlyEmission = await axios(optionsDivisionYearlyEmission);
    const divisionYearlyEmission = [];
    //let calcHighestCategory = 0;
    //let highestCategory = "";
    divisionYearlyEmission.push(["Division", "Emission (tCO<sub>2</sub>e)"]);
    if (responseDivisionYearlyEmission.data.success) {
      responseDivisionYearlyEmission.data.data.forEach(element => {
        /*if(element.total_emission > calcHighestCategory){
            calcHighestCategory = element.total_emission;
            highestCategory = element.category_name;
        }*/
        divisionYearlyEmission.push([element.group_name, element.total_emission])
      });
    }

    const optionsCompanyYearlyEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/company_yearly_emission`
    };
    const responseCompanyYearlyEmission = await axios(optionsCompanyYearlyEmission);
    const companyYearlyEmission = [];
    let highestCompany = "";
    companyYearlyEmission.push(["Company", "Emission (tCO<sub>2</sub>e)"]);
    if (responseCompanyYearlyEmission.data.success) {
      responseCompanyYearlyEmission.data.data.forEach((element, index) => {
        // eslint-disable-next-line
        if (index == 0) {
          highestCompany = element.company_name;
        }
        companyYearlyEmission.push([element.company_name, element.total_emission])
      });
    }

    const optionsTotalEmission = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_emission`
    };
    const responseTotalEmission = await axios(optionsTotalEmission);
    let totalEmission = [];
    if (responseTotalEmission.data.success) {
      totalEmission = responseTotalEmission.data.data;
      this.setState({
        totalEmission: totalEmission,
      })
    }

    const optionsTotalRevenue = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/total_revenue`
    };
    const responseTotalRevenue = await axios(optionsTotalRevenue);
    let totalRevenue = [];
    if (responseTotalRevenue.data.success) {
      totalRevenue = responseTotalRevenue.data.data;
      this.setState({
        totalRevenue: totalRevenue,
      })
    }

    /*let ctrLastyear = 0;
    let ctrPrevyear = 0;
    ctrLastyear = parseFloat(totalEmission[0].emission_total) / parseFloat(totalRevenue[1].revenue_total);
    ctrPrevyear = parseFloat(totalEmission[0].emission_total) / parseFloat(totalRevenue[1].revenue_total);*/

    const optionsSetting = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/account/setting/read`
    };
    const responseSetting = await axios(optionsSetting);
    let tempRevenueUnit = "";
    if (responseSetting.data.success) {
      // eslint-disable-next-line
      if (responseSetting.data.data[0].client_revenue_unit == 1) {
        tempRevenueUnit = "IDR mn";
      } else {
        tempRevenueUnit = "USD k";
      }
    }

    this.setState({
      categoryMonthlyEmission: categoryMonthlyEmission,
      companyYearlyEmission: companyYearlyEmission,
      totalEmissionCurrentYear: calcTotalEmissionCurrentYear,
      divisionYearlyEmission: divisionYearlyEmission,
      highestCategoryEmission: highestCategory,
      highestCompanyEmission: highestCompany,
      revenueUnit: tempRevenueUnit,
    })
    hidePreloader();
  }

  getCompany = async () => {
    const data = {};
    const optionsGetCompany = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/client/company`
    };
    const responseGetCompany = await axios(optionsGetCompany);
    if (responseGetCompany.data.success) {
      return responseGetCompany.data.data;
    } else {
      return null;
    }
  }

  getLocation = async () => {
    const data = {};
    const optionsGetLocation = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/client/office`
    };
    const responseGetLocation = await axios(optionsGetLocation);
    if (responseGetLocation.data.success) {
      return responseGetLocation.data.data;
    } else {
      return null;
    }
  }

  getSummaryMonthly = async (year, company) => {
    const data = {
      'year': year,
      'company': company,
    };
    const optionsGetSummaryMonthly = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/dashboard/summary_yearly_emission`
    };
    const responseGetSummaryYearly = await axios(optionsGetSummaryMonthly);
    if (responseGetSummaryYearly.data.success) {
      return responseGetSummaryYearly.data.data;
    } else {
      return null;
    }
  }

  getScopeMonthly = async () => {
    const data = {
      'location': this.state.selectedLocation,
      'year': this.state.selectedYear
    };
    const optionsGetScopeEmission = {
      withCredentials: true,
      method: 'GET',
      params: {
        ...data,
        gwp: new URLSearchParams(window.location.search).get('gwp'),
      },
      url: `${process.env.REACT_APP_BASE_SERVER}/analytics-location-scope-emission`
    };
    const responseGetScopeEmission = await axios(optionsGetScopeEmission);
    if (responseGetScopeEmission.data.ok) {
      if (responseGetScopeEmission.data.data.length > 0) {
        this.setState({
          monthlyTotalEmissionByScope: responseGetScopeEmission.data.data,
        })
        return responseGetScopeEmission.data.data;
      } else {
        return null;
      }
    }
  }

  getCategoryMonthly = async () => {
    const data = {
      'location': this.state.selectedLocation,
      'year': this.state.selectedYear
    };
    const optionsGetCategoryEmission = {
      withCredentials: true,
      method: 'GET',
      params: {
        ...data,
        gwp: new URLSearchParams(window.location.search).get('gwp'),
      },
      url: `${process.env.REACT_APP_BASE_SERVER}/analytics-location-category-emission`
    };
    const responseGetCategoryEmission = await axios(optionsGetCategoryEmission);
    if (responseGetCategoryEmission.data.ok) {
      if (responseGetCategoryEmission.data.data.length > 0) {
        return responseGetCategoryEmission.data.data;
      } else {
        return null;
      }
    }
  }

  onSelectMode = async (e) => {
    let modeSelected = e.target.value;
    await this.reloadSummaryYearly(this.state.listCompany, this.state.companySelected, modeSelected).then((result) => {
      this.setState({
        selectedMode: modeSelected,
        totalSummaryYearlyEmission: result,
      })
    })
  }

  onSelectYear = async (e) => {
    let yearSelected = e.target.value;

    this.setState({
      selectedYear: yearSelected,
    }, () => {
      this.onFilterApply();
    })
  }

  onSelectLocation = async (e) => {
    let locationSelected = e.target.value;

    this.setState({
      selectedLocation: locationSelected,
    }, () => {
      this.onFilterApply();
    })
  }

  onFilterApply = async () => {
    let listCategory = [];
    var totalEmission = 0;
    var highestEmission = "";
    let storeScopeMonthlyEmission = [];
    let storeCategoryMonthlyEmission = [];

    const data = {
      'location': this.state.selectedLocation,
      'year': this.state.selectedYear
    };

    const optionsGetEmissionCategory = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify({
        ...data,
        gwp: new URLSearchParams(window.location.search).get('gwp'),
      }),
      url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/category_all`
    };
    const responseGetEmissionCategory = await axios(optionsGetEmissionCategory);
    if (responseGetEmissionCategory.data.success) {
      listCategory = responseGetEmissionCategory.data.data
    }

    if (this.state.selectedLocation > 0 && this.state.selectedYear > 0) {
      const optionsGetTotalEmission = {
        withCredentials: true,
        method: 'GET',
        params: {
          ...data,
          gwp: new URLSearchParams(window.location.search).get('gwp'),
        },
        url: `${process.env.REACT_APP_BASE_SERVER}/analytics-location-total-emission`
      };
      const responseGetTotalEmission = await axios(optionsGetTotalEmission);
      if (responseGetTotalEmission.data.ok) {
        totalEmission = responseGetTotalEmission.data.data[0].total;

        this.setState({
          totalEmissionAnalytics: totalEmission
        })
      }

      const optionsGetHighestEmission = {
        withCredentials: true,
        method: 'GET',
        params: {
          ...data,
          gwp: new URLSearchParams(window.location.search).get('gwp'),
        },
        url: `${process.env.REACT_APP_BASE_SERVER}/analytics-location-highest-emission`
      };
      const responseGetHighestEmission = await axios(optionsGetHighestEmission);
      if (responseGetHighestEmission.data.ok) {
        if (responseGetHighestEmission.data.data.length > 0) {
          highestEmission = responseGetHighestEmission.data.data[0].category_name;

          this.setState({
            highestEmissionCategory: highestEmission,
            highestEmissionCategoryId: responseGetHighestEmission.data.data[0].category_name_id,
          })
        }
      }

      this.getScopeMonthly()
        .then((responseGetScopeMonthly) => {
          if (responseGetScopeMonthly !== null) {
            let labelScopeSummary = [];

            labelScopeSummary.push('Emission');
            labelScopeSummary.push('Scope 1');
            labelScopeSummary.push('Scope 2');
            // eslint-disable-next-line
            if (this.props.account['client_scope'] == 1) {
              labelScopeSummary.push('Scope 3');
            }

            let resultScopeSummary = [];
            resultScopeSummary.push(labelScopeSummary);
            let dataRows = responseGetScopeMonthly;
            dataRows.forEach(element => {
              let dataScopeSummary = [];
              dataScopeSummary.push(element.record_month)
              dataScopeSummary.push(element.total_1)
              dataScopeSummary.push(element.total_2)
              // eslint-disable-next-line
              if (this.props.account['client_scope'] == 1) {
                dataScopeSummary.push(element.total_3)
              }
              resultScopeSummary.push(dataScopeSummary)
            });
            storeScopeMonthlyEmission.push(resultScopeSummary);

            this.setState({
              totalScopeMonthlyEmission: storeScopeMonthlyEmission
            })
          }
        })

      this.getCategoryMonthly()
        .then((responseGetCategoryMonthly) => {
          if (responseGetCategoryMonthly !== null) {
            let labelCategorySummary = [];
            labelCategorySummary.push('Emission');

            listCategory.forEach(element => {
              if (this.state.selectedLanguage === "en") {
                labelCategorySummary.push(element.category_name);
              } else {
                labelCategorySummary.push(element.category_name_id);
              }
            })

            let resultCategorySummary = [];
            resultCategorySummary.push(labelCategorySummary);
            let dataRows = responseGetCategoryMonthly;
            dataRows.forEach(element => {
              let dataCategorySummary = [];
              dataCategorySummary.push(element.record_month)
              if (listCategory.filter((item) => item.category_id == 1).length > 0) {
                dataCategorySummary.push(element.total_1)
              }
              if (listCategory.filter((item) => item.category_id == 2).length > 0) {
                dataCategorySummary.push(element.total_2)
              }
              if (listCategory.filter((item) => item.category_id == 3).length > 0) {
                dataCategorySummary.push(element.total_3)
              }
              if (listCategory.filter((item) => item.category_id == 4).length > 0) {
                dataCategorySummary.push(element.total_4)
              }
              if (listCategory.filter((item) => item.category_id == 5).length > 0) {
                dataCategorySummary.push(element.total_5)
              }
              if (listCategory.filter((item) => item.category_id == 6).length > 0) {
                dataCategorySummary.push(element.total_6)
              }
              if (listCategory.filter((item) => item.category_id == 7).length > 0) {
                dataCategorySummary.push(element.total_7)
              }
              resultCategorySummary.push(dataCategorySummary)
            });
            storeCategoryMonthlyEmission.push(resultCategorySummary);

            this.setState({
              totalCategoryMonthlyEmission: storeCategoryMonthlyEmission
            })
          }
        })
    }
  }

  onDownloadYearlyCategory = async () => {
    this.onDownloadImage("yearlyCategory", "yearly-emission-by-category")
  }

  onDownloadYearlyCompany = async () => {
    this.onDownloadImage("yearlyCompany", "yearly-emission-by-company")
  }

  onDownloadYearlyDivision = async (index) => {
    this.onDownloadImage("yearlyDivision", "yearly-emission-by-division")
  }

  onDownloadMonthlyCompany = async () => {
    this.onDownloadImage("monthlyCompany", "monthly-emission-by-company")
  }

  onDownloadMonthlyScope = async (index) => {
    this.onDownloadImage("monthlyScope" + index, "monthly-emission-by-scope-for-" + this.state.listCompany[index].company_name.toLowerCase())
  }

  onDownloadImage = async (element, label) => {
    const canvas = await html2canvas(document.getElementById(element));
    const image = canvas.toDataURL("image/png", 1.0);

    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = label;

    fakeLink.href = image;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  }

  render() {
    const { t, i18n } = this.props;

    let optionLocation = [];
    optionLocation.push(this.state.selectedLocation > 0 ? null : <option key={-1} value={0}>{t('analytics.location.filter.location')}</option>)
    // eslint-disable-next-line
    if (this.state.listLocation != undefined) {
      if (this.state.listLocation.length > 0) {
        this.state.listLocation.map(async (item, index) => {
          optionLocation.push(<option key={index + 1} value={item.office_id}>{item.office_name}</option>)
        })
      }
    }

    let optionYear = [];
    optionYear.push(this.state.selectedYear > 0 ? null : <option key={-1} value={0}>{t('analytics.location.filter.year')}</option>)
    this.state.listYear.map(async (item, index) => {
      optionYear.push(
        <option key={index} value={item.year}>{item.year}</option>
      )
    })

    // old data
    const presetColors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const pieOptions = {
      pieHole: 0.2,
      height: 350,
      is3D: false,
      legend: { position: 'top', maxLines: 5 },
      colors: ['#4C638C', '#65A2CB', '#CDCDCD', '#B0CCDA', '#3EB6AE', '#41997C', '#96E8CF', '#8CD587', '#BFCD4A', '#E0CA3C', '#FC9A61', '#CF7C95', '#E46C65', '#BC9BCA', '#91A4F5', '#F3B1AA']
    };

    const columnOptions = {
      legend: { position: 'top', maxLines: 5 },
      isStacked: true,
      vAxis: {
        format: '#,### tCO\u2082e'
      },
      colors: ['#4C638C', '#65A2CB', '#CDCDCD', '#B0CCDA', '#3EB6AE', '#41997C', '#96E8CF', '#8CD587', '#BFCD4A', '#E0CA3C', '#FC9A61', '#CF7C95', '#E46C65', '#BC9BCA', '#91A4F5', '#F3B1AA']
    };

    const columnAltOptions = {
      legend: { position: 'top', maxLines: 5 },
      isStacked: true,
      vAxis: {
        format: '#,### tCO\u2082e'
      },
      colors: ['#4C638C', '#65A2CB', '#CDCDCD', '#B0CCDA', '#3EB6AE', '#41997C', '#96E8CF', '#8CD587', '#BFCD4A', '#E0CA3C', '#FC9A61', '#CF7C95', '#E46C65', '#BC9BCA', '#91A4F5', '#F3B1AA'] // 16 items
    };

    const monthsInEnglish = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const options = {
      chart: {
        id: 'chartTotalEmissionByScopeLocationAnalytics',
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      colors: CHART_COLORS,
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: i18n.language === 'id'
          ? ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agt', 'Sep', 'Okt', 'Nov', 'Des']
          : monthsInEnglish,
      },
      yaxis: {
        title: {
          text: 'tCO₂e'
        },
        labels: {
          formatter: function (value) {
            return twoDecimalFormat(value, i18n.language)
          },
        },
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return twoDecimalFormat(val, i18n.language) + " tCO₂e"
          },
        },
      },
    }

    const scopeLabels = [
      {
        label: 'Scope 1',
        labelId: 'Cakupan 1',
        key: 'total_1',
      },
      {
        label: 'Scope 2',
        labelId: 'Cakupan 2',
        key: 'total_2',
      },
    ]
    if (this.props.account['client_scope'] === 1) {
      scopeLabels.push({
        label: 'Scope 2',
        labelId: 'Cakupan 3',
        key: 'total_3',
      },)
    }
    const series = this.state.monthlyTotalEmissionByScope.length > 0
      ? scopeLabels.map((label, labelIndex) => {
        return {
          name: label.label,
          nameId: label.labelId,
          data: monthsInEnglish.map(month => {
            const findData = this.state.monthlyTotalEmissionByScope
              .find(d => d.record_month === month)
            if (findData) {
              return parseFloat(findData[label.key].toFixed(2))
            }
            return 0
          }),
          color: CHART_COLORS[labelIndex],
        }
      })
      : []

    options.tooltip.y.title = {
      formatter: function (seriesName) {
        if (series.length && i18n.language === 'id') {
          const seriesByName = series.find(s => s.name === seriesName)
          if (seriesByName) {
            return `${seriesByName.nameId}:`
          } else {
            return `${seriesName}:`
          }
        } else {
          return `${seriesName}:`
        }
      },
    }

    const FilterSeries = (seriesName = "") => {
      if (series.every(item => item?.name === undefined)) return

      ApexCharts.getChartByID(options?.chart?.id).toggleSeries(seriesName)

      let currentData = [...this.state.hiddenSeries]
      const checkIndex = currentData.findIndex(item => item === seriesName)

      if (checkIndex < 0) currentData.push(seriesName)
      else currentData.splice(checkIndex, 1)

      this.setState({
        hiddenSeries: currentData,
      })
    }


    let optionScope = [];
    this.state.totalScopeMonthlyEmission.map(async (item, index) => {
      optionScope.push(
        // eslint-disable-next-line
        this.state.selectedCompany == (index + 1)
          ? <div key={index} className="col-12">
            <div className="card card-animate">
              <div className='align-items-start d-flex gap-3 justify-content-between pt-3 px-3 w-100'>
                <p
                  className='fw-semibold m-0'
                  style={{ fontSize: 12 }}
                >
                  {this.props.t('dashboard.card.title10')}
                </p>
                <Download
                  onClick={() => this.onDownloadMonthlyScope(index)}
                  isDisabled={!Array.isArray(series) || !series.length}
                />
              </div>

              <div
                key={index}
                className="card-body"
                id={"monthlyScope" + index}
              >
                <div className='px-3'>
                  <ReactApexChart
                    options={options}
                    series={series.length > 0 ? series : emptySeries}
                    type="bar"
                    height={350}
                  />
                </div>
                {
                  series.length > 0 && (
                    <BarChartLegend
                      series={series}
                      filterSeries={FilterSeries}
                      hiddenSeries={this.state.hiddenSeries}
                    />
                  )
                }
              </div>
            </div>
          </div> : <div key={index}></div>
      )
    })

    let optionCategory = [];
    this.state.totalCategoryMonthlyEmission.map((item, index) => {
      optionCategory.push(
        this.state.selectedCompany == (index + 1) ?
          <div key={index} className="col-12">
            <div className="card card-animate">
              <div className="card-header align-items-center d-flex justify-content-between">
                <h4 className="card-title mb-0 flex-grow-1">
                  <strong>{this.props.t('dashboard.card.title11')}</strong>
                </h4>
                <button
                  type="button"
                  onClick={() => this.onDownloadMonthlyCategory(index)}
                  className="btn btn-info"
                >
                  {this.props.t('dashboard.btnDownload')}
                </button>
              </div>

              <div
                key={index}
                className="card-body"
                id={"monthlyCategory" + index}
              >
                {(() => {
                  return <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="250px"
                    data={item}
                    options={columnAltOptions}
                  />
                })()}
              </div>
            </div>
          </div> : <div key={index}></div>
      )
      return null
    })

    return (
      <div className="page-content">
        <div className="container-fluid">

          <div className="row">
            <div className="col-12">
              <div className='align-items-center d-flex justify-content-between mb-4'>
                <h4 className='mb-sm-0' style={{ color: '#09564B' }}>
                  {this.props.t('menu.label45')}
                </h4>
                <div className="d-sm-flex align-items-center justify-content-between gap-3">
                  <select
                    style={{ width: "auto" }}
                    className="form-select"
                    id="selectedLocationPicker"
                    value={this.state.selectedLocation}
                    onChange={e => this.onSelectLocation(e)}
                  >
                    {optionLocation}
                  </select>
                  <select
                    style={{ width: "auto" }}
                    className="form-select"
                    id="selectedYearPicker"
                    value={this.state.selectedYear}
                    onChange={e => this.onSelectYear(e)}
                  >
                    {optionYear}
                  </select>
                </div>
              </div>
            </div>
          </div>

          {
            this.state.selectedLocation > 0 &&
              this.state.selectedYear > 0
              ?
              <div className="row">
                <Grid
                  itemCount={4}
                >
                  <BoxItem
                    label={this.props.t('dashboard.card.title1')}
                    value={(
                      <ValueAndUnit
                        value={twoDecimalFormat(this.state.totalEmissionAnalytics || NaN, this.props.i18n.language)}
                      />
                    )}
                  />
                  <BoxItem
                    label={this.props.t('dashboard.card.title2')}
                    value={(
                      <Value
                        value={this.props.i18n.language === 'id'
                          ? this.state.highestEmissionCategoryId || this.state.highestEmissionCategory
                          : this.state.highestEmissionCategory}
                      />
                    )}
                  />
                </Grid>
              </div>
              : <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div
                      className="card-body row align-items-center justify-content-center"
                      style={{ 'height': '200px' }}
                    >
                      <center>
                        <p className="text-muted">
                          {this.props.t('analytics.location.filter.empty')}
                        </p>
                      </center>
                    </div>
                  </div>
                </div>
              </div>}

          {this.state.selectedLocation > 0 && this.state.selectedYear > 0 ?
            <div className="row">

              {optionScope}

              {/*optionCategory*/}

            </div> : null}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  count: state.counter.value,
  loggedIn: state.loggedIn.value,
  account: state.account.value,
  company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Location));