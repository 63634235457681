import axios from 'axios'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'

import { useEffect, useState } from 'react'

import {
    CHART_COLORS,
    twoDecimalFormat,
    percentageFormat,
} from '../../library/helper'
import ApexCharts from 'apexcharts'

import Download from '../Download'
import EmptyDonutChart from '../ApexCharts/EmptyDonutChart'
import EmptyPolarAreaChart from '../ApexCharts/EmptyPolarAreaChart'

const NoData = ({
    chartId,
}) => {
    return (
        <div className='d-flex flex-column gap-2 pb-3 px-3'
            style={{ minHeight: chartId === 'yearlyCategory' ? '60px' : '60px' }}
        >
            <div style={{
                position: 'absolute',
                borderStyle: 'solid',
                borderWidth: '1px',
                padding: '0.5rem',
                borderRadius: 14,
                fontSize: '12px',
                fontWeight: 'bold',
                backgroundColor: 'white',
                alignSelf: 'center',
            }}>
                No Data
            </div>
        </div>
    )
}

function YearlyEmission(props) {
    const {
        chartHeight,
        chartType,
        chartId,
        onDownloadImage,
        options: chartOptions,
        emptyOptions: emptyChartOptions,
        pdfLable,
        selectedYear,
        selectedValue = "",
        title,
        apiRoute,
    } = props
    const { i18n } = useTranslation()
    const [data, setData] = useState([])
    const [hiddenSeries, setHiddenSeries] = useState([])

    const series = data?.length > 0 ? data.map(item => parseFloat(item?.totalEmission?.toFixed(2))) : []
    const options = {
        ...chartOptions,
        labels: i18n.language === 'id'
            ? data.map(d => d.nameId ? d.nameId : d.name)
            : data.map(d => d.name),
    }

    useEffect(() => {
        const controller = new AbortController()

        const getData = async () => {
            try {
                const year = Number(selectedYear)
                const response = await axios({
                    withCredentials: true,
                    method: 'GET',
                    headers: { 'content-type': 'application/json' },
                    params: {
                        year,
                    },
                    url: `${process.env.REACT_APP_BASE_SERVER}${apiRoute}`,
                    signal: controller.signal,
                })
                if (response.data.ok) {
                    setData(response.data.data.dataChart)
                } else {
                    setData([])
                }
            } catch (error) {
                console.error(error)
            }
        }
        if (selectedYear && apiRoute) {
            getData()
        }

        return () => controller.abort()

    }, [selectedYear, apiRoute])

    const onDownload = () => {
        onDownloadImage(chartId, pdfLable)
    }

    const FilterSeries = (seriesName = "") => {
        if (series.every(item => item?.name === undefined)) return;

        ApexCharts.getChartByID(options?.chart?.id).toggleSeries(seriesName);

        let currentData = [...hiddenSeries];
        const checkIndex = currentData.findIndex(item => item === seriesName);

        if (checkIndex < 0) currentData.push(seriesName);
        else currentData.splice(checkIndex, 1);

        setHiddenSeries(currentData);
    }

    return (
        <div
            className='bg-white d-flex flex-column gap-3 position-relative'
            style={{
                borderRadius: '8px',
                flex: 1,
            }}
            id={chartId}
        >
            <div className='align-items-start d-flex gap-3 justify-content-between pt-3 px-3 w-100'>
                <p
                    className={`fw-semibold m-0 ${(!Array.isArray(data) || !data.length) ? 'text-muted' : ''}`}
                    style={{ fontSize: 12 }}
                >
                    {title}
                </p>
                <Download
                    onClick={onDownload}
                    isDisabled={!Array.isArray(data) || !data.length}
                />
            </div>
            {
                chartType === 'donut'
                    ? <div className='position-relative px-3'>
                        {
                            series.length > 0
                                ? <>
                                    <ReactApexChart
                                        options={options}
                                        series={series}
                                        type={chartType}
                                        height={chartHeight}
                                    />
                                    {
                                        selectedValue && (
                                            <p
                                                className='bottom-0 start-50 mb-0 position-absolute translate-middle-x z-1'
                                                style={{ fontSize: 12 }}
                                            >
                                                {twoDecimalFormat(selectedValue, i18n.language)} tCO₂e
                                            </p>
                                        )
                                    }
                                </>
                                : <EmptyDonutChart />
                        }
                    </div>
                    : chartId === 'yearlyCategory'
                        ? <div className='position-relative px-3'>
                            {
                                series.length > 0
                                    ? <ReactApexChart
                                        options={options}
                                        series={series}
                                        type={chartType}
                                        height={chartHeight}
                                    />
                                    : <EmptyPolarAreaChart />
                            }
                        </div>
                        : ''
            }
            {
                data.length > 0
                    ? <div className='d-flex flex-column gap-2 pb-3 px-3'>
                        {data.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className='d-flex gap-2 cursor-pointer'
                                    onClick={() => FilterSeries(item?.name)}
                                >
                                    <div
                                        style={{
                                            backgroundColor: hiddenSeries?.includes(item?.name)
                                                ? 'transparent'
                                                : CHART_COLORS?.[index],
                                            border: hiddenSeries?.includes(item?.name)
                                                ? `1px solid ${CHART_COLORS?.[index]}`
                                                : 'none',
                                            borderRadius: '4px',
                                            height: 12,
                                            marginTop: 2,
                                            minHeight: 12,
                                            minWidth: 12,
                                            width: 12,
                                        }}
                                    >
                                    </div>
                                    <p
                                        className='flex-grow-1 m-0'
                                        style={{ fontSize: 10 }}
                                    >
                                        {
                                            i18n.language === 'id'
                                                ? item?.nameId || item?.name
                                                : item?.name
                                        } ({percentageFormat(item?.percentage, i18n.language === 'id' ? 'id-ID' : 'en-US')})
                                    </p>
                                    <p
                                        className='m-0'
                                        style={{ fontSize: 10 }}
                                    >
                                        {twoDecimalFormat(item?.totalEmission, i18n.language)}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                    : series.length === 0 && <NoData chartId={chartId} />
            }
        </div>
    )
}

export default YearlyEmission
