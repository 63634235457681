import {
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ImNewTab } from "react-icons/im"

import {
  AREA_CHART_COLORS,
  ROUTE_PATHS,
} from '../../library/helper'

const ScenarioLegendItem = ({
  index,
  filterSeries,
  hiddenSeries,
  item,
  color,
  lines
}) => {
  const { i18n, t } = useTranslation()

  const line = lines.find(line => line.label === item.name)
  let titleText
  if (line && i18n.language === 'id') {
    titleText = line.labelId || item.name
  } else {
    titleText = item.name
  }

  return (
    <div
      key={index}
      className='d-flex gap-2 cursor-pointer pb-1 px-1 gap-2'
    >
      <div
        className='d-flex gap-2'
        onClick={() => filterSeries(item?.name)}
      >
        <div
          style={{
            backgroundColor: hiddenSeries?.includes(item?.name)
              ? 'transparent'
              : color || AREA_CHART_COLORS?.[index],
            border: hiddenSeries?.includes(item?.name)
              ? `1px solid ${color || AREA_CHART_COLORS?.[index]}`
              : 'none',
            borderRadius: '4px',
            height: 12,
            marginTop: 2,
            minHeight: 12,
            minWidth: 12,
            width: 12,
          }}
        >
        </div>
        <p
          className='flex-grow-1 m-0'
          style={{ fontSize: 10, textTransform: 'capitalize' }}
        >
          {titleText}
        </p>
      </div>
      {
        line && line.briefExplanation && (
          <div
            className='flex-grow-1 m-0 gap-2'
            style={{
              fontSize: 9,
              display: 'flex',
            }}
          >
            <span className="flex-shrink-0">
              <span className="dropdown">
                <button
                  id="dropdownMenuLink1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    background: 'none',
                    border: 'none',
                    padding: '0',
                    font: 'inherit',
                    cursor: 'pointer',
                  }}
                >
                  <i className='ri-information-line'></i>
                </button>
                <span
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownMenuLink1"
                  style={{
                    fontSize: '10px',
                    padding: '0.5rem',
                    backgroundColor: 'rgb(41, 46, 51)',
                    color: 'rgb(173, 181, 189)',
                    textTransform: 'none',
                  }}
                >
                  <p>
                    {line.briefExplanation[i18n.language]}
                  </p>
                  <span>
                    <a
                      href={line.referenceLink || ''}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <span
                        style={{
                          display: 'flex',
                          gap: '0.25rem',
                          alignItems: 'center',
                          color: 'rgb(53, 119, 241)',
                          marginTop: '0.5rem',
                        }}
                      >
                        {t('scenario.learnMore')} <ImNewTab />
                      </span>
                    </a>
                  </span>
                </span>
              </span>
            </span>
          </div>
        )
      }
    </div>
  )
}
export default function MultilineChartLegend({
  series,
  filterSeries,
  hiddenSeries,
  onDashboard,
  lines,
  averageGrowthRate,
  setGrowthRate,
}) {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const account = useSelector((state) => state.account.value)
  const [localGrowthRate, setLocalGrowthRate] = useState(averageGrowthRate)

  const onInputPercentage = (values) => {
    setLocalGrowthRate(values.value)
  }

  const onSubmitGrowthRate = () => {
    setGrowthRate(localGrowthRate)
  }

  const group1 = lines.filter(line => line.group === 1)
  const group2 = lines.filter(line => line.group === 2)
  const group3 = lines.filter(line => line.group === 3)
  const seriesGroup1 = series.filter(s => group1.some(line => line.label === s.name))
  const seriesGroup2 = series.filter(s => group2.some(line => line.label === s.name))
  const seriesGroup3 = series.filter(s => group3.some(line => line.label === s.name))

  if (onDashboard) {
    const lengendOnDashboard = [...seriesGroup1, ...seriesGroup2]

    return (
      <div
        className='d-flex justify-content-center gap-2 pb-3 px-3'
      >
        {
          lengendOnDashboard.length > 0 && lengendOnDashboard.map((item) => {
            const colorIndex = series.findIndex(s => s.name === item.name)
            const line = lines.find(line => line.label === series[colorIndex]?.name)
            if (colorIndex === -1) {
              return null
            }
            return (
              <div
                key={colorIndex}
                className='d-flex gap-2 cursor-pointer'
                onClick={() => filterSeries(item?.name)}
              >
                <div
                  style={{
                    backgroundColor: hiddenSeries?.includes(item?.name)
                      ? 'transparent'
                      : series[colorIndex].color,
                    border: hiddenSeries?.includes(item?.name)
                      ? `1px solid ${series[colorIndex].color}`
                      : 'none',
                    borderRadius: '4px',
                    height: 12,
                    marginTop: 2,
                    minHeight: 12,
                    minWidth: 12,
                    width: 12,
                  }}
                >
                </div>
                <p
                  className='flex-grow-1 m-0'
                  style={{ fontSize: 10, textTransform: 'capitalize' }}
                >
                  {i18n.language === 'id' ? line.labelId || item?.name : item?.name}
                </p>
              </div>
            )
          })
        }
      </div>

    )
  } else {
    return (
      <div
        className='pb-3 px-3 gap-2'
        style={{
          display: 'flex',
          gap: '1rem',
          margin: '1rem 0',
          flexWrap: 'wrap',
          alignContent: 'flex-start',
          justifyContent: seriesGroup2.length > 0 && seriesGroup3.length > 0 ? 'space-evenly' : 'center',
          alignItems: 'flex-start',
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              fontSize: '11px',
            }}
          >
            <div className="d-flex">
              <div className='d-flex gap-2 pb-1 px-1 gap-2'>
                <p
                  className='flex-grow-1 m-0'
                  style={{ fontSize: 10, fontWeight: 500 }}
                >
                  {account.client_baseline} {t('setting.scenario.baselineYear')}
                </p>
              </div>
            </div>
          </div>
          {
            seriesGroup1.map((item, index) => {
              const colorIndex = series.findIndex(s => s.name === item.name)
              return <ScenarioLegendItem
                item={item}
                key={index}
                index={colorIndex}
                color={series[colorIndex].color}
                filterSeries={filterSeries}
                hiddenSeries={hiddenSeries}
                lines={group1}
              />
            })
          }
        </div>
        {
          seriesGroup2.length > 0 && (
            <div>
              {
                seriesGroup2.map((item, index) => {
                  const colorIndex = series.findIndex(s => s.name === item.name)
                  return <ScenarioLegendItem
                    item={item}
                    key={index}
                    index={colorIndex}
                    color={series[colorIndex].color}
                    filterSeries={filterSeries}
                    hiddenSeries={hiddenSeries}
                    lines={group2}
                  />
                })
              }
            </div>
          )
        }
        {
          seriesGroup3.length > 0 && (
            <div>
              {
                seriesGroup3.map((item, index) => {
                  const colorIndex = series.findIndex(s => s.name === item.name)
                  return <ScenarioLegendItem
                    item={item}
                    key={index}
                    index={colorIndex}
                    color={series[colorIndex].color}
                    filterSeries={filterSeries}
                    hiddenSeries={hiddenSeries}
                    lines={group3}
                  />
                })
              }
            </div>
          )
        }
        {
          location.pathname === ROUTE_PATHS.scenario && (
            <div className="row justify-content-end" style={{ position: 'relative' }}>
              <div style={{ top: '-3px' }}>
                <div className='d-flex align-items-end gap-2'>
                  <div>
                    <label htmlFor="amount" className="form-label" style={{ fontSize: '10px' }}>
                      {t('scenario.growthRate')} (%)
                    </label>
                    <NumericFormat
                      value={localGrowthRate} // 10003.21 would be 10.003,21 in ID and 10,003.21 in US format
                      allowNegative
                      className="form-control"
                      style={{ width: '100%', padding: '0.3rem 1.1rem' }}
                      onValueChange={onInputPercentage}
                      decimalSeparator={i18n.language === 'id' ? ',' : '.'}
                      thousandSeparator={i18n.language === 'id' ? '.' : ','}
                    />
                  </div>
                  <button
                    onClick={onSubmitGrowthRate}
                    type="button"
                    className="btn btn-icon btn-soft-primary"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t('scenario.showProjection')}
                    style={{
                      padding: '0.3rem 1.1rem',
                      height: 'calc(1rem + 1.1em + 2px)',
                      width: 'calc(1rem + 1.1em + 2px)',
                    }}
                  >
                    <i className="ri-line-chart-line"></i>
                  </button>
                </div>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}