import React, { Component } from 'react'
// eslint-disable-next-line
import { Link, Navigate, Route, Routes, useSearchParams } from 'react-router-dom'

import { connect } from "react-redux"
import { increment, decrement } from "../features/counter/counterSlice"
import { setLoggedIn } from "../features/account/loginSlice"
import { setAccount } from "../features/account/accountSlice"
import { setCompany } from "../features/account/companySlice"

import axios from "axios";
// eslint-disable-next-line
import qs from 'qs';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";

import { hidePreloader, showPreloader } from '../library/helper';
import { withTranslation } from 'react-i18next';

export class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectSetting: false,

            selectedIndustry: 0,
            selectedEmployees: 0,
            selectedStandard: 0,
            selectedBaseline: 0,
            selectedRevenueUnit: 0,
            selectedGoal: 0,
            selectedBoundary: 0,

            inputPasswordOld: "",
            inputPasswordNew: "",
            inputPasswordConfirm: "",

            countTargetRecords: 1,
            countBAURecords: 1,

            selectedScenario: 1,

            listGoalRecord: [
                {
                    year: "",
                    value: "",
                    note: "",
                }
            ],

            listGoalBau: [
                {
                    year: "",
                    value: "",
                    note: "",
                }
            ],
        }
    }

    componentDidMount = async () => {
        showPreloader();

        console.log("company");
        // eslint-disable-next-line
        const data = {}

        const optionsGetBoundaries = {
            withCredentials: true,
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_SERVER}/client/boundary`
        };
        let listBoundary = [];
        const responseGetBoundaries = await axios(optionsGetBoundaries);
        if (responseGetBoundaries.data.success) {
            listBoundary = responseGetBoundaries.data.data
        }

        const optionsClientIndustry = {
            withCredentials: true,
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_SERVER}/client/industry`
        };
        const responseClientIndustry = await axios(optionsClientIndustry);
        let listIndustry = [];
        if (responseClientIndustry.data.success) {
            listIndustry = responseClientIndustry.data.data;
        }
        console.log(listIndustry)

        const optionsGetSetting = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/account/setting/read`
        };
        const responseGetSetting = await axios(optionsGetSetting);
        let setting = [];
        if (responseGetSetting.data.success) {
            setting = responseGetSetting.data.data[0]
        }
        console.log(setting)

        const optionsSettingRevenueProductionUnitList = {
            withCredentials: true,
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_SERVER}/setting-revenue-production-unit-list`
        };
        const responseSettingRevenueProductionUnitList = await axios(optionsSettingRevenueProductionUnitList);
        let listRevenueProductionUnit = [];
        if (responseSettingRevenueProductionUnitList.data.ok) {
            listRevenueProductionUnit = responseSettingRevenueProductionUnitList.data.data;
        }

        this.setState({
            listIndustry: listIndustry,
            listBoundary: listBoundary,
            listRevenueProductionUnit: listRevenueProductionUnit,
            selectedIndustry: setting.client_industry,
            selectedEmployees: setting.client_employees,
            selectedStandard: setting.client_standard,
            selectedBoundary: setting.client_boundary,
            selectedRevenueUnit: setting.client_revenue_unit,
            selectedBaseline: new Date(setting.client_baseline, 0),
            selectedGoal: setting.client_goal,
            selectedScenario: setting.client_scenario,
            selectedGwp: setting.client_gwp || 'ar5',
        })

        hidePreloader();
    }

    onSelectIndustry = async (e) => {
        let companyIndustry = e.target.value;
        this.setState({
            selectedIndustry: companyIndustry
        })
    }

    onSelectStandard = async (e) => {
        let companyStandard = e.target.value;
        this.setState({
            selectedStandard: companyStandard
        })
    }

    onSelectBoundary = async (e) => {
        let companyBoundaryApproach = e.target.value;
        this.setState({
            selectedBoundary: companyBoundaryApproach
        })
    }

    onSelectRevenueUnit = async (e) => {
        let revenueUnit = e.target.value;
        this.setState({
            selectedRevenueUnit: revenueUnit
        })
    }

    onSelectGoal = async (e) => {
        let companyGoal = e.target.value;
        this.setState({
            selectedGoal: companyGoal
        })
    }

    onSelectGwp = async (e) => {
        this.setState({
            selectedGwp: e.target.value,
        })
    }

    onSaveRecordCall = async (data) => {
        const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/account/setting/update`
        };
        const response = await axios(options);
        if (response.data.success) {
            return true;
        } else {
            return false;
        }
    }

    onSaveSetting = async () => {
        let invalidData = false;

        let industry = this.state.selectedIndustry;
        let employees = this.state.selectedEmployees;
        let standard = this.state.selectedStandard;
        let revenue = this.state.selectedRevenueUnit;

        // eslint-disable-next-line
        if (revenue != "" && industry > 0 && employees > 0 && standard > 0) {

            const data = {
                'industry': this.state.selectedIndustry,
                'employee': this.state.selectedEmployees,
                'standard': this.state.selectedStandard,
                'revenue': this.state.selectedRevenueUnit,
                'boundary': this.state.selectedBoundary,
                'baseline': new Date(this.state.selectedBaseline).getFullYear(),
                'goal': this.state.selectedGoal,
                gwp: this.state.selectedGwp,
            };

            const result = this.onSaveRecordCall(data);
            if (result) {
                invalidData = false;
            }
            console.log("success")
        } else {
            invalidData = true;
            console.log("failed")
        }
        if (invalidData) {
            Swal.fire({
                title: '',
                html: '<p>Please fill all information needs</p>',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            })
        } else {
            Swal.fire({
                title: '',
                html: '<p>Successfully update settings</p>',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            })
        }
    }

    onInputEmployee = async (e) => {
        let employee = e.target.value;
        this.setState({
            selectedEmployees: employee
        })
    }

    onInputRevenue = async (e) => {
        let revenueUnit = e.target.value;
        this.setState({
            selectedRevenueUnit: revenueUnit
        })
    }

    onSelectDate = async (e) => {
        var datePick = new Date(new Date(e).getFullYear(), 1, 7);
        console.log(datePick)
        this.setState({
            selectedBaseline: datePick,
        })
    }

    onInputPasswordOld = async (e) => {
        let passwordOld = e.target.value;
        this.setState({
            inputPasswordOld: passwordOld
        })
    }

    onInputPasswordNew = async (e) => {
        let passwordNew = e.target.value;
        this.setState({
            inputPasswordNew: passwordNew
        })
    }

    onInputPasswordConfirm = async (e) => {
        let passwordConfirm = e.target.value;
        this.setState({
            inputPasswordConfirm: passwordConfirm
        })
    }

    onChangePasswordCall = async (data) => {
        const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/account/setting/change_password`
        };
        const response = await axios(options);
        if (response.data.success) {
            return true;
        } else {
            return false;
        }
    }

    onChangePassword = async () => {
        let invalidData = false;
        let notMatchData = false;

        let passwordOld = this.state.inputPasswordOld;
        let passwordNew = this.state.inputPasswordNew;
        let passwordConfirm = this.state.inputPasswordConfirm;

        // eslint-disable-next-line
        if (passwordOld != "" && passwordNew != "" && passwordConfirm != "") {
            // eslint-disable-next-line
            if (passwordNew == passwordConfirm) {

                const data = {
                    'passwordOld': this.state.inputPasswordOld,
                    'passwordNew': this.state.inputPasswordNew,
                };

                const result = this.onChangePasswordCall(data);
                if (result) {
                    //invalidData = true;
                }
                console.log("success")
                this.setState({
                    inputPasswordOld: "",
                    inputPasswordNew: "",
                    inputPasswordConfirm: ""
                })
            } else {
                notMatchData = true;
                console.log("failed")
            }
        } else {
            invalidData = true;
            console.log("failed")
        }
        if (invalidData) {
            Swal.fire({
                title: '',
                html: '<p>Please fill all information needs</p>',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            })
        } else {
            if (notMatchData) {
                Swal.fire({
                    title: '',
                    html: "<p>Confirm password doesn't match</p>",
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            } else {
                Swal.fire({
                    title: '',
                    html: '<p>Successfully change password</p>',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            }
        }
    }

    onSelectScenario = async (e) => {
        let scenario = e.target.value;
        this.setState({
            selectedScenario: scenario
        })
    }

    onUpdateScenario = async () => {
        console.log(this.state.selectedScenario)
    }

    onInsertGoalTarget = async () => {
        let data = this.state.listGoalRecord;
        data.push({
            year: "",
            value: "",
            note: "",
        })
        this.setState({
            countTargetRecords: this.state.countTargetRecords + 1,
        })
    }

    onInsertGoalBau = async () => {
        let data = this.state.listGoalBau;
        data.push({
            year: "",
            value: "",
            note: "",
        })
        this.setState({
            countBAURecords: this.state.countBAURecords + 1,
        })
    }

    onDeleteGoalTarget = async (index) => {
        let data = this.state.listGoalRecord;
        data.splice(index, 1)
        this.setState({
            countTargetRecords: this.state.countTargetRecords - 1,
        })
    }

    onDeleteGoalBau = async (index) => {
        let data = this.state.listGoalBau;
        data.splice(index, 1)
        this.setState({
            countBAURecords: this.state.countBAURecords - 1,
        })
    }

    render() {
        const { redirectSetting, listIndustry } = this.state;

        if (redirectSetting) {
            return <Navigate to={'/setting'} />
        }

        let optionIndustry = [];
        // eslint-disable-next-line
        if (listIndustry != undefined) {
            if (listIndustry.length > 0) {
                listIndustry.map(async (item, index) => {
                    optionIndustry.push(<option key={index + 1} value={item.industry_id}>{item.industry_name}</option>)
                })
            }
        }

        let optionBoundary = [];
        // eslint-disable-next-line
        if (this.state.listBoundary != undefined) {
            if (this.state.listBoundary.length > 0) {
                this.state.listBoundary.map(async (item, index) => {
                    optionBoundary.push(<option key={index + 1} value={item.boundary_id}>{item.boundary_name}</option>)
                })
            }
        }

        let optionRevenueProductionUnit = [];

        // eslint-disable-next-line
        if (this.state.listRevenueProductionUnit != undefined) {
            if (this.state.listRevenueProductionUnit.length > 0) {
                this.state.listRevenueProductionUnit.map(async (item, index) => {
                    optionRevenueProductionUnit.push(<option key={index} value={item.unit_id}>{item.unit_label}</option>)
                })
            }
        }

        let targetGoal = [];
        for (let i = 0; i < this.state.countTargetRecords; i++) {
            targetGoal.push(
                <tr key={i}>
                    <td>
                        <DatePicker
                            id="date"
                            selected={this.state.selectedBaseline}
                            onChange={(date) => this.onSelectDate(date)}
                            dateFormat="yyyy"
                            className="form-select w-remove"
                            showYearPicker
                        />
                    </td>
                    <td>
                        <div className="d-flex gap-2">
                            <input
                                onChange={e => this.onInputAsset(e, i)}
                                className="form-control"
                                placeholder="percentage (%)"
                            />
                        </div>
                    </td>
                    <td>
                        <input
                            value={""}
                            onChange={() => this.onDeleteRow(i)}
                            className="form-control"
                            placeholder="note"
                        ></input>
                    </td>
                    <td>
                        <button onClick={() => this.onDeleteGoalTarget(i)} type="button" className="btn btn-icon btn-ghost-danger"><i className="ri-delete-bin-5-line"></i></button>
                    </td>
                </tr>
            )
        }

        let targetBau = [];
        for (let i = 0; i < this.state.countBAURecords; i++) {
            targetBau.push(
                <tr key={i}>
                    <td>
                        <DatePicker
                            id="date"
                            selected={this.state.selectedBaseline}
                            onChange={(date) => this.onSelectDate(date)}
                            dateFormat="yyyy"
                            className="form-select w-remove"
                            showYearPicker
                        />
                    </td>
                    <td>
                        <div className="d-flex gap-2">
                            <input
                                onChange={e => this.onInputAsset(e, i)}
                                className="form-control"
                                placeholder="percentage (%)"
                            />
                        </div>
                    </td>
                    <td>
                        <input
                            value={""}
                            onChange={() => this.onDeleteRow(i)}
                            className="form-control"
                            placeholder="note"
                        ></input>
                    </td>
                    <td>
                        <button onClick={() => this.onDeleteGoalBau(i)} type="button" className="btn btn-icon btn-ghost-danger"><i className="ri-delete-bin-5-line"></i></button>
                    </td>
                </tr>
            )
        }

        return (
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">{this.props.t('menu.label6')}</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className='card subscribe'>
                                <p className='fw-bold fs-4'>Subscribe to get full access</p>
                                <p className='fs-5 text-center w-25'>You are currently using the free trial mode, which limits the information you can access or request. Get in touch to get full access.</p>
                                <button type="button" className="btn btn-primary fw-semibold fs-5 px-5">Get Full Access</button>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                                                <i className="fas fa-home"></i> {this.props.t('menu.label63')}
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                                                <i className="far fa-user"></i> {this.props.t('menu.label7')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body p-4">
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="personalDetails" role="tabpanel">
                                            <form action="#">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="lastnameInput" className="form-label">Industry</label>
                                                            <select onChange={e => this.onSelectIndustry(e)} value={this.state.selectedIndustry} className="form-select mb-3" id="companyIndustry">
                                                                {optionIndustry}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="lastnameInput" className="form-label">Boundaries Approach</label>
                                                            <select onChange={e => this.onSelectBoundary(e)} value={this.state.selectedBoundary} className="form-select mb-3" id="companyBoundary">
                                                                {optionBoundary}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="emailInput" className="form-label">Number of Employees</label>
                                                            <input type="number" className="form-control" id="emailInput" placeholder="Enter number of employees" value={this.state.selectedEmployees} onChange={(e) => this.onInputEmployee(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="revenueUnit" className="form-label">Denominator Unit</label>
                                                            <select className="form-select mb-3" id="companyStandard" value={this.state.selectedRevenueUnit} onChange={e => this.onSelectRevenueUnit(e)} >
                                                                <option value="1">IDR mn</option>
                                                                <option value="2">USD k</option>
                                                                {optionRevenueProductionUnit}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {
                                                        <SearchParams
                                                            render={({ searchParams }) => {
                                                                return searchParams.get('gwp') === 'true' && <div className="col-lg-6">
                                                                    <div className="mb-3">
                                                                        <label
                                                                            htmlFor="revenueUnit"
                                                                            className="form-label"
                                                                        >
                                                                            Global Warming Potential
                                                                        </label>
                                                                        <select
                                                                            className="form-select mb-3"
                                                                            id="companyStandard"
                                                                            value={this.state.selectedGwp}
                                                                            onChange={e => this.onSelectGwp(e)}
                                                                        >
                                                                            <option value="ar5">AR-5</option>
                                                                            <option value="ar6">AR-6</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            }}
                                                        />
                                                    }
                                                    {/*<div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="phonenumberInput" className="form-label">Standard</label>
                                                            <select className="form-select mb-3" id="companyStandard" value={this.state.selectedStandard} onChange={e => this.onSelectStandard(e)} >
                                                                <option value="1">GHG Protocol</option>
                                                                <option value="2">ISO 14064-1</option>
                                                                <option value="3">GRI 305</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="firstnameInput" className="form-label">Baseline Year</label>
                                                            <DatePicker
                                                                id="date"
                                                                selected={this.state.selectedBaseline}
                                                                onChange={(date) => this.onSelectDate(date)}
                                                                dateFormat="yyyy"
                                                                className="form-select mb-3"
                                                                showYearPicker
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="phonenumberInput" className="form-label">Goal</label>
                                                            <select className="form-select mb-3" id="companyGoal" value={this.state.selectedGoal} onChange={e => this.onSelectGoal(e)} >
                                                                <option value="1">Net-zero</option>
                                                                <option value="2">Carbon Neutral</option>
                                                            </select>
                                                        </div>
                                                    </div>*/}
                                                    <div className="col-lg-12">
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button type="button" className="btn btn-primary" onClick={() => this.onSaveSetting()}>{this.props.t('general.btnUpdate')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="tab-pane" id="changePassword" role="tabpanel">
                                            <form>
                                                <div className="row g-2">
                                                    <div className="col-lg-4">
                                                        <div>
                                                            <label htmlFor="oldpasswordInput" className="form-label">Old Password</label>
                                                            <input type="password" className="form-control" id="oldPasswordInput" placeholder="Enter current password" value={this.state.inputPasswordOld} onChange={e => this.onInputPasswordOld(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div>
                                                            <label htmlFor="newpasswordInput" className="form-label">New Password</label>
                                                            <input type="password" className="form-control" id="newPasswordInput" placeholder="Enter new password" value={this.state.inputPasswordNew} onChange={e => this.onInputPasswordNew(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div>
                                                            <label htmlFor="confirmpasswordInput" className="form-label">Confirm Password</label>
                                                            <input type="password" className="form-control" id="confirmPasswordInput" placeholder="Confirm password" value={this.state.inputPasswordConfirm} onChange={e => this.onInputPasswordConfirm(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="text-end">
                                                            <button type="button" className="btn btn-primary" onClick={() => this.onChangePassword()}>Change Password</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

const SearchParams = ({ render, ...props }) => {
    const [searchParams] = useSearchParams()

    return render({ ...props, searchParams })
}

const mapStateToProps = (state) => ({
    count: state.counter.value,
    loggedIn: state.loggedIn.value,
    account: state.account.value,
    company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Setting));