import { createSlice } from '@reduxjs/toolkit'

export const companySlice = createSlice({
    name: 'company',
    initialState: {
        value: []
    },
    reducers: {
        setCompany: (state, action) => {
            state.value = action.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setCompany } = companySlice.actions

export default companySlice.reducer