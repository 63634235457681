import { useState } from 'react'
import ApexCharts from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import {
  EMPTY_CHART_COLORS,
  twoDecimalFormat,
} from '../../library/helper'

export default function EmptyDonutChart() {
  const { i18n } = useTranslation()

  const [series] = useState([95, 70, 55, 85])

  const emptyOptions = {
    chart: {
      id: 'chartDonutEmpty',
      type: 'donut',
    },
    colors: [EMPTY_CHART_COLORS[0]],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10
      }
    },
    stroke: {
      colors: "#FFFFFF",
      show: true,
    },
    grid: {
      padding: {
        bottom: -130
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return twoDecimalFormat(val, i18n.language) + " tCO₂e"
        },
      },
      enabled: false,
    },
  }

  return (
    <ApexCharts
      options={emptyOptions}
      series={series}
      type="donut"
      height={200}
    />
  );
}
