import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import loginReducer from '../features/account/loginSlice'
import accountReducer from '../features/account/accountSlice'
import companyReducer from '../features/account/companySlice'
import sidebarSizeReducer from '../features/layout/sidebarSizeSlice'
import tourReducer from '../features/tour/tourSlice'

export default configureStore({
    reducer: {
        counter: counterReducer,
        loggedIn: loginReducer,
        account: accountReducer,
        company: companyReducer,
        sidebarSize: sidebarSizeReducer,
        tour: tourReducer,
    }
})