import {
  useState,
} from 'react'
import axios from 'axios'
import {
  useQuery,
  useMutation,
} from '@tanstack/react-query'
import { NumericFormat } from 'react-number-format'
import { useTranslation } from 'react-i18next'
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import {
  MRT_Localization_ID,
} from 'material-react-table/locales/id'
import {
  MRT_Localization_EN,
} from 'material-react-table/locales/en'

import CustomAutocomplete from '../components/Autocomplete'

import { decimalFormat } from '../library/helper'

// https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
// const useDebounce = (value, delay) => {
//   const [debouncedValue, setDebouncedValue] = useState(value)

//   useEffect(() => {
//     const handler = setTimeout(() => {
//       setDebouncedValue(value)
//     }, delay)

//     return () => {
//       clearTimeout(handler)
//     }
//   }, [value])

//   return debouncedValue
// }

const useUnitTypeLookup = () => {
  return useQuery({
    queryKey: [
      'climatiq-emission-unit-types-lookup',
    ],
    retry: false,
    queryFn: ({ signal }) => axios({
      url: [
        process.env.REACT_APP_BASE_SERVER,
        '/climatiq-emission-unit-types-lookup',
      ].join(''),
      method: 'GET',
      withCredentials: true,
      signal,
    }).then(response => {
      return (response?.data?.data?.unit_types ?? [])
    }),
  })
}

const useUnitTypesTable = () => {
  const { t, i18n } = useTranslation()

  const {
    data = [],
    isLoading,
    isRefetching,
  } = useUnitTypeLookup()

  return useMaterialReactTable({
    enableBottomToolbar: true,
    enableColumnOrdering: true,
    enableDensityToggle: false,
    enableExpandAll: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: true,
    enablePagination: true,
    enableTableFooter: false,
    enableTopToolbar: true,
    initialState: { density: 'compact' },
    localization: i18n.language === 'id' ? (
      MRT_Localization_ID
    ) : MRT_Localization_EN,
    muiTablePaperProps: {
      elevation: 0,
    },
    columns: [
      {
        header: 'Unit Type',
        size: 100,
        accessorKey: 'unit_type',
      },
      {
        header: 'units',
        accessorKey: 'units',
        size: 100,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue()
          const types = Object.keys(cell.getValue())
          return (
            <div className="d-flex flex-wrap gap-2 fs-16">
              {
                types.length > 0 && types.map(type => {
                  return <div
                    key={type}
                    className="badge fw-medium badge-soft-secondary"
                  >
                    {type} ({cellValue[type].join(', ')})
                  </div>
                })
              }
            </div>
          )
        },
      },
    ],
    data,
    state: {
      isLoading: isLoading || isRefetching,
    }
  })
}

const useEmissionFactorsLookup = ({
  limit,
  keyword,
}) => {
  return useQuery({
    queryKey: [
      'climatiq-emission-factors-lookup',
      limit,
      keyword,
    ],
    retry: false,
    queryFn: ({ signal }) => axios({
      url: [
        process.env.REACT_APP_BASE_SERVER,
        '/climatiq-emission-factors-lookup',
      ].join(''),
      method: 'GET',
      withCredentials: true,
      signal,
      params: {
        limit,
        keyword,
      },
    }).then(response => {
      return (response?.data?.data?.searchResult?.results ?? []).map(data => {
        return {
          ...data,
          key: data.id,
          label: `${data.name} (${data.region}-${data.year}-${data.source})`,
        }
      })
    }),
  })
}

const useEmissionEstimate = () => {
  return useMutation({
    mutationKey: ['climatiq-emission-estimate-lookup'],
    mutationFn: async (data) => axios({
      url: [
        process.env.REACT_APP_BASE_SERVER,
        '/climatiq-emission-estimate-lookup',
      ].join(''),
      method: 'POST',
      withCredentials: true,
      data,
    }),
  })
}

export default function POCEmissionSearch() {
  const { i18n } = useTranslation()
  const [searchKeyword, setSearchKeyword] = useState('')
  const [selectedFactor, setSelectedFactor] = useState({})
  const [kWhAmount, setKWhAmount] = useState(0)

  const {
    data = [],
    isLoading,
  } = useEmissionFactorsLookup({
    limit: 300,
    keyword: searchKeyword,
  })

  const {
    mutate: requestClientsEmissionCorrectionsConfirmation,
    data: estimationResult,
  } = useEmissionEstimate()


  const unitTypesTable = useUnitTypesTable()

  const handleOpenSearch = () => {
    if (!searchKeyword) setSearchKeyword('')
  }

  const handleSearchInputChange = (_event, newValue) => {
    if (!newValue) {
      return
    }
    setTimeout(() => {
      setSearchKeyword(newValue)
    }, 500)
  }

  const handleEstimate = () => {
    requestClientsEmissionCorrectionsConfirmation({
      amount: kWhAmount,
    }, {})
  }

  const onNumericFormatInputChange = (values, sourceInfo) => {
    if (sourceInfo?.event?.target?.name && values) {
      setKWhAmount(values.value)
    }
  }

  let co2Total = '-'
  let source = ''
  if (estimationResult?.data?.data?.co2e) {
    const langLocale = i18n.language === 'id' ? 'id-ID' : 'en-US'

    const value = decimalFormat(estimationResult?.data?.data?.co2e, langLocale)

    co2Total = `${value} ${estimationResult?.data?.data?.co2e_unit}`
    source = `Source: ${estimationResult?.data?.data?.emission_factor?.source} ${estimationResult?.data?.data?.emission_factor?.year} (${estimationResult?.data?.data?.emission_factor?.region})`
  }

  return (
    <div className='page-content'>
      <div className='container-fluid'>
        <div className='card'>
          <div className='card-body'>
            <div className="row">
              <div style={{
                textAlign: 'left',
                margin: '1rem',
              }}>
                <h4>1. Search API</h4>
                <CustomAutocomplete
                  id='search'
                  label='Search'
                  handleOpen={handleOpenSearch}
                  handleInputChange={handleSearchInputChange}
                  handleChange={(e, newValue) => {
                    setSelectedFactor(newValue)
                  }}
                  options={data}
                  loading={isLoading}
                  value={searchKeyword}
                  renderOption={(option, state) => {
                    return (
                      <div {...option}>
                        <h6>{state.name} ({`${state.region}-${state.year}-${state.source}`})</h6>
                      </div>
                    )
                  }}
                />
                {!!selectedFactor?.activity_id && (
                  <div style={{
                    margin: '1rem',
                    whiteSpace: 'pre',
                    height: '30vh',
                    width: '40vw',
                    overflow: 'scroll',
                  }}
                  >
                    {JSON.stringify(selectedFactor, null, 5)}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div style={{
                display: 'flex',
                gap: '1rem',
                flexDirection: 'column',
                margin: '1rem',
                textAlign: 'left',
              }}
              >
                <h4>2. Estimate API</h4>
                <div style={{ display: 'flex', gap: '1rem', width: '400px' }}>
                  <div
                    className="input-group"
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <NumericFormat
                      name='nearTermLar'
                      allowNegative
                      className="form-control"
                      value={kWhAmount}
                      onValueChange={onNumericFormatInputChange}
                      style={{ padding: '0.3rem 1.1rem' }}
                      decimalSeparator={i18n.language === 'id' ? ',' : '.'}
                      thousandSeparator={i18n.language === 'id' ? '.' : ','}
                    />
                    <span className="input-group-text" id="basic-addon2">kWh</span>
                  </div>
                  <button
                    onClick={handleEstimate}
                    className="btn btn-danger"
                  >
                    Estimate Electricity
                  </button>
                </div>

                <div>
                  <p className='mb-0'>CO<sub>2</sub>e = {co2Total} </p>
                  <p className='text-muted'>{source}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div style={{
                textAlign: 'left',
                margin: '1rem',
              }}
              >
                <h4>3. Unit Types API</h4>
                <MaterialReactTable table={unitTypesTable} />

              </div>
            </div>

          </div>
        </div>
      </div>
    </div >
  )
}
