import React, { Component, useEffect } from 'react'
// eslint-disable-next-line
import { Navigate } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'

import { connect } from "react-redux"
import { setAccount } from "../features/account/accountSlice"
import { setCompany } from "../features/account/companySlice"
import { setLoggedIn } from "../features/account/loginSlice"
import { decrement, increment } from "../features/counter/counterSlice"

import axios from "axios"
import qs from 'qs'
import "react-tooltip/dist/react-tooltip.css"
import Swal from 'sweetalert2'

import { withTranslation } from 'react-i18next'
import { hidePreloader, showPreloader } from '../library/helper'

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import Attachment from '../components/Attachment'
import { withRouter } from '../components/withRouter'

export class EmissionUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectEmission: false,
            selectedCompany: 0,
            selectedOffice: 0,
            selectedDate: new Date(Date.now()),
            selectedScope: 0,
            selectedCategory: 0,
            selectedItem: 0,
            amountItem: 0,

            listEmissionRecord: [
                {
                    assets: "",
                    factor: 0,
                    amount: 0,
                    amount_2: 0,
                    amount_3: 0,
                    amount_4: 0,
                    amount_5: 0,
                    amount_6: 0,
                    amount_7: 0,
                    amount_8: 0,
                    unit: 0,
                    multiplier: 1,
                }
            ],

            listCategory: [],
            listFactor: [],
            itemUnit: '',

            amountEmission: 0,
            countRecords: 1,

            selectedID: "",
            selectedFile: null,
        }
    }

    componentDidMount = async () => {
        showPreloader();

        let listCompany = [];
        let listCategory = [];
        let listEmissionFactor = [];
        let listEmissionFactorUnit = [];

        console.log("emission create");
        const data = {}

        const optionsGetCompany = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/company`
        };
        const responseGetCompany = await axios(optionsGetCompany);
        if (responseGetCompany.data.success) {
            listCompany = responseGetCompany.data.data
        }
        console.log(listCompany)

        const optionsGetEmissionCategory = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/category`
        };
        const responseGetEmissionCategory = await axios(optionsGetEmissionCategory);
        if (responseGetEmissionCategory.data.success) {
            listCategory = responseGetEmissionCategory.data.data
        }
        console.log(listCategory)

        const optionsGetEmissionFactor = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor`
        };
        const responseGetEmissionFactor = await axios(optionsGetEmissionFactor);
        if (responseGetEmissionFactor.data.success) {
            listEmissionFactor = responseGetEmissionFactor.data.data
        }
        console.log(listEmissionFactor)

        const optionsGetEmissionFactorUnit = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor_unit`
        };
        const responseGetEmissionFactorUnit = await axios(optionsGetEmissionFactorUnit);
        if (responseGetEmissionFactorUnit.data.success) {
            listEmissionFactorUnit = responseGetEmissionFactorUnit.data.data
        }
        console.log(listEmissionFactorUnit)

        const { id } = this.props.params

        const dataID = {
            'id': id,
        };

        const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataID),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission/read`
        };
        const response = await axios(options);
        if (response.data.success) {
            console.log(response.data.data)
            this.onLoadCompany(response.data.data[0].record_company)
            var datePick = new Date(new Date(response.data.data[0].record_date).getFullYear(), new Date(response.data.data[0].record_date).getMonth(), 7);
            let data = this.state.listEmissionRecord;
            data[0].assets = response.data.data[0].record_assets;
            data[0].factor = response.data.data[0].record_factor;
            data[0].unit = response.data.data[0].record_unit;
            data[0].amount = response.data.data[0].record_amount;
            data[0].amount_2 = response.data.data[0].record_amount_2;
            data[0].amount_3 = response.data.data[0].record_amount_3;
            data[0].attachmentId = response.data.data[0].attachment_id;
            this.setState({
                selectedID: id,
                selectedCompany: response.data.data[0].record_company,
                selectedOffice: response.data.data[0].record_location,
                selectedCategory: response.data.data[0].record_category,
                selectedDate: datePick,
                listEmissionRecord: data
            })
        } else {

        }

        this.setState({
            listCompany: listCompany,
            listCategory: listCategory,
            listEmissionFactor: listEmissionFactor,
            listEmissionFactorUnit: listEmissionFactorUnit,
        })

        console.log(this.state.selectedDate)
        hidePreloader();
    }

    onSelectCompany = async (e) => {
        let companyValue = e.target.value;
        console.log(companyValue)
        const data = {
            'coid': companyValue
        };
        const optionsGetOffice = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/office`
        };
        const responseGetOffice = await axios(optionsGetOffice);
        if (responseGetOffice.data.success) {
            this.setState({
                selectedCompany: companyValue,
                listOffice: responseGetOffice.data.data,
                selectedOffice: 0,
            })
        }
    }

    onLoadCompany = async (companyValue) => {
        console.log(companyValue)
        const data = {
            'coid': companyValue
        };
        const optionsGetOffice = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/office`
        };
        const responseGetOffice = await axios(optionsGetOffice);
        if (responseGetOffice.data.success) {
            this.setState({
                selectedCompany: companyValue,
                listOffice: responseGetOffice.data.data,
                //selectedOffice: 0,
            })
        }
    }

    onSelectCategory = async (e) => {
        /*let categoryValue = e;
        console.log(categoryValue)
        this.setState({
            selectedCategory: categoryValue,
        })*/
        let categoryValue = e.target.value;

        this.setState({
            selectedCategory: categoryValue,
            listEmissionRecord: [
                {
                    assets: "",
                    factor: 0,
                    amount: "",
                    amount_2: "",
                    amount_3: "",
                    unit: 0,
                    multiplier: 1,
                }
            ],
        })
    }

    onSelectOffice = async (e) => {
        let officeValue = e.target.value;
        this.setState({
            selectedOffice: officeValue,
        })
    }

    onSelectDate = async (e) => {
        console.log(new Date(e).getFullYear());
        console.log(new Date(e).getMonth());
        var datePick = new Date(new Date(e).getFullYear(), new Date(e).getMonth(), 7);
        console.log(datePick)
        this.setState({
            selectedDate: datePick,
        })
    }

    onInsertRow = async () => {
        let data = this.state.listEmissionRecord;
        data.push({
            assets: "",
            factor: 0,
            amount: "",
            amount_2: "",
            amount_3: "",
            unit: 0,
            multiplier: 1,
        })
        this.setState({
            countRecords: this.state.countRecords + 1,
        })
    }

    onDeleteRow = async (index) => {
        let data = this.state.listEmissionRecord;
        data.splice(index, 1)
        this.setState({
            countRecords: this.state.countRecords - 1,
        })
    }

    onInputAsset = async (e, index) => {
        let data = this.state.listEmissionRecord;
        data[index].assets = e.target.value;
        this.setState({
            listEmissionRecord: data
        })

        console.log(this.state.listEmissionRecord)
    }

    onSelectEmission = async (e, index) => {
        let data = this.state.listEmissionRecord;
        data[index].factor = e.target.value;
        this.setState({
            listEmissionRecord: data
        })

        console.log(this.state.listEmissionRecord)
    }

    onSelectUnit = async (e, index) => {
        let data = this.state.listEmissionRecord;
        data[index].unit = e.target.value;
        let listEmissionFactorUnit = this.state.listEmissionFactorUnit;
        let filteredUnit = listEmissionFactorUnit.filter(
            // eslint-disable-next-line
            item => item.unit_id == e.target.value
        )
        data[index].multiplier = filteredUnit[0].unit_multiplier;
        this.setState({
            listEmissionRecord: data
        })

        console.log(this.state.listEmissionRecord)
    }

    onInputNumericFormatChange = (values, sourceInfo, index) => {
        if (sourceInfo?.event?.target?.name && values) {
            let data = this.state.listEmissionRecord
            data[index].amount = values.value
            this.setState({
                listEmissionRecord: data,
            })
        }
    }

    onInletOutletCODInputNumericFormatChange = (values, sourceInfo, index) => {
        if (sourceInfo?.event?.target?.name && values) {
            let data = this.state.listEmissionRecord
            data[index][sourceInfo?.event?.target?.name] = values.value
            this.setState({
                listEmissionRecord: data,
            })
        }
    }

    onSaveRecordCall = async (data) => {
        const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'multipart/form-data' },
            data: data,
            url: `${process.env.REACT_APP_BASE_SERVER}/emission/update`
        };
        const response = await axios(options);
        if (response.data.success) {
            return true;
        } else {
            return false;
        }
    }

    onSaveRecord = async (e) => {
        let listCategory = this.state.listCategory;
        let filteredCategory = listCategory.filter(
            // eslint-disable-next-line
            item => item.category_id == this.state.selectedCategory
        )
        console.log(filteredCategory)
        this.state.listEmissionRecord.forEach(async element => {
            let listEmissionFactor = this.state.listEmissionFactor;
            let filteredEmissionFactor = listEmissionFactor.filter(
                // eslint-disable-next-line
                item => item.emission_factor_id == element.factor && item.cf_category == this.state.selectedCategory
            )

            let data = new FormData()
            console.log(filteredEmissionFactor)
            if (filteredEmissionFactor.length > 0) {
                //console.log(parseFloat(filteredEmissionFactor[0].emission_factor))
                data.append('id', this.state.selectedID)
                data.append('scope', filteredCategory[0].category_scope)
                data.append('category', this.state.selectedCategory)
                data.append('factor', element.factor)
                data.append('unit', element.unit)
                data.append('company', this.state.selectedCompany)
                data.append('location', this.state.selectedOffice)
                data.append('assets', element.assets)
                data.append('date', this.state.selectedDate.toISOString())
                data.append('amount', element.amount)
                data.append('total', parseFloat(element.amount) * parseFloat(element.multiplier) * parseFloat(filteredEmissionFactor[0].emission_factor))
                data.append('attachment', this.state.selectedFile)
                if (this.state.selectedCategory == 26) {
                    data.append('amount_2', element.amount_2)
                    data.append('amount_3', element.amount_3)
                }
            } else {
                data.append('id', this.state.selectedID)
                data.append('scope', filteredCategory[0].category_scope)
                data.append('category', this.state.selectedCategory)
                data.append('factor', element.factor)
                data.append('unit', element.unit)
                data.append('company', this.state.selectedCompany)
                data.append('location', this.state.selectedOffice)
                data.append('assets', element.assets)
                data.append('date', this.state.selectedDate.toISOString())
                data.append('amount', element.amount)
                data.append('total', parseFloat(element.amount) * parseFloat(element.multiplier))
                data.append('attachment', this.state.selectedFile)
            }
            console.log(data)
            const result = await this.onSaveRecordCall(data);

            if (!result) {
                Swal.fire({
                    title: '',
                    html: '<p>Please fill all information needs</p>',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            } else {
                Swal.fire({
                    title: '',
                    html: '<p>Successfully record emissions</p>',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({
                            redirectEmission: true
                        })
                    }
                })
            }
        });
    }

    onAttachFile = async () => {
        document.getElementById('formFile').click();
    }

    onChangeFile = async (e) => {
        const files = e.target.files
        this.setState({ selectedFile: files?.[0] })
    }

    render() {
        const { redirectEmission } = this.state;
        const { listCompany, selectedCompany } = this.state;
        const { listOffice, selectedOffice } = this.state;
        const { selectedDate } = this.state;
        const { listCategory, selectedCategory } = this.state;
        const { listEmissionFactor, listEmissionFactorUnit, listEmissionRecord } = this.state;

        if (redirectEmission) {
            return <Navigate to={'/emission'} />
        }

        let optionCompany = [];
        optionCompany.push(selectedCompany > 0 ? null : <option key={0} value={0}>Select Company</option>)
        // eslint-disable-next-line
        if (listCompany != undefined) {
            if (listCompany.length > 0) {
                listCompany.map(async (item, index) => {
                    optionCompany.push(<option key={index + 1} value={item.company_id}>{item.company_name}</option>)
                })
            }
        }

        let optionOffice = [];
        optionOffice.push(selectedOffice > 0 ? null : <option key={0} value={0}>Select Location</option>)
        // eslint-disable-next-line
        if (listOffice != undefined) {
            if (listOffice.length > 0) {
                listOffice.map(async (item, index) => {
                    optionOffice.push(<option key={index + 1} value={item.office_id}>{item.office_name}</option>)
                })
            }
        }

        let optionCategory = [];
        optionCategory.push(selectedCategory > 0 ? null : <option key={0} value={0}>Select Source</option>)
        // eslint-disable-next-line
        if (listCategory != undefined) {
            if (listCategory.length > 0) {
                listCategory.map(async (item, index) => {
                    if (this.props.i18n.language === "id") {
                        optionCategory.push(<option key={index + 1} value={item.category_id}>{item.category_name_id}</option>)
                    } else {
                        optionCategory.push(<option key={index + 1} value={item.category_id}>{item.category_name}</option>)
                    }
                    // eslint-disable-next-line
                    /*if(item.category_id == selectedCategory){
                        optionCategory.push(
                            <button key={index} onClick={() => this.onSelectCategory(item.category_id)} className='text-center rounded-md border border-transparent bg-indigo-600 py-2 h-full w-full align-middle text-sm font-medium text-white hover:bg-indigo-700 col-span-6 md:col-span-6'>
                            {item.category_name}</button>
                        )
                    }else{
                        optionCategory.push(
                            <button key={index} onClick={() => this.onSelectCategory(item.category_id)} className='text-center rounded-md border border-transparent bg-indigo-300 py-2 h-full w-full align-middle text-sm font-medium text-white hover:bg-indigo-700 col-span-6 md:col-span-6'>
                            {item.category_name}</button>
                        )
                    }*/
                })
            }
        }

        let optionEmissionFactor = [];
        // eslint-disable-next-line
        if (selectedCategory != 0) {
            // eslint-disable-next-line
            if (listEmissionFactor != undefined) {
                if (listEmissionFactor.length > 0) {
                    listEmissionFactor.map(async (item, index) => {
                        // eslint-disable-next-line
                        if (item.cf_category == selectedCategory) {
                            optionEmissionFactor.push(
                                <option key={index + 1} value={item.emission_factor_id}>{item.emission_name}</option>
                            )
                        }
                    })
                }
            }
        }

        //let emissionRows = [];
        //for (let i = 0; i < countRecords; i++) {
        let optionEmissionFactorUnit = [];
        // eslint-disable-next-line
        if (listEmissionRecord[0].factor != 0) {
            // eslint-disable-next-line
            if (listEmissionFactorUnit != undefined) {
                if (listEmissionFactorUnit.length > 0) {
                    listEmissionFactorUnit.map(async (item, index) => {
                        // eslint-disable-next-line
                        if (item.unit_factor == listEmissionRecord[0].factor) {
                            optionEmissionFactorUnit.push(
                                <option key={index + 1} value={item.unit_id}>{item.unit_label}</option>
                            )
                        }
                    })
                }
            }
        }

        /*if(optionEmissionFactor.length > 0) {
            emissionRows.push(
                <tr key={i}>
                    <td>
                        <input
                            onChange={e => this.onInputAsset(e, i)}
                            value={listEmissionRecord[i].assets}
                            className="form-control"
                            placeholder="assets"
                        ></input>
                    </td>
                    <td>
                        <select onChange={e => this.onSelectEmission(e, i)} value={listEmissionRecord[i].factor} className="form-select mb-0">
                            {listEmissionRecord[i].factor > 0 ? "" : <option value="0">Select {selectedCategory == 7 ? "Grid Name" : selectedCategory == 3 ? "Refrigerant Type" : "Fuel Type"}</option>}
                            {optionEmissionFactor}
                        </select>
                    </td>
                    <td>
                        <select onChange={e => this.onSelectUnit(e, i)} value={listEmissionRecord[i].unit} className="form-select mb-0">
                            {listEmissionRecord[i].unit > 0 ? "" : <option value="0">Select Unit</option>}
                            {optionEmissionFactorUnit}
                        </select>
                    </td>
                    <td>
                        <input
                            type="number"
                            onChange={e => this.onInputAmount(e, i)}
                            value={listEmissionRecord[i].amount}
                            className="form-control"
                            placeholder="amount"
                        ></input>
                    </td>
                    <td>
                        <button onClick={() => this.onDeleteRow(i)} type="button" className="btn btn-icon btn-ghost-danger"><i className="ri-delete-bin-5-line"></i></button>
                    </td>
                </tr>
            )
        }else{
            emissionRows.push(
                <tr key={i}>
                    <td>
                        <input
                            onChange={e => this.onInputAsset(e, i)}
                            value={listEmissionRecord[i].assets}
                            className="form-control"
                            placeholder="assets"
                        ></input>
                    </td>
                    <td>
                        <input
                            type="number"
                            onChange={e => this.onInputAmount(e, i)}
                            value={listEmissionRecord[i].amount}
                            className="form-control"
                            placeholder="amount"
                        ></input>
                    </td>
                    <td>
                        <button onClick={() => this.onDeleteRow(i)} type="button" className="btn btn-icon btn-ghost-danger"><i className="ri-delete-bin-5-line"></i></button>
                    </td>
                </tr>
            )
        }*/
        //}

        return (
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">{this.props.t("menu.label21")} - Scope 1 & 2</h4>

                                {/*<div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#page">Pages</a></li>
                                        <li className="breadcrumb-item active">Starter</li>
                                    </ol>
                                </div>*/}

                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">{this.props.t('general.btnUpdate')}</h4>
                                </div>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="company" className="form-label">{this.props.t('emission.form.label1')}</label>
                                                <select onChange={e => this.onSelectCompany(e)} value={selectedCompany} id="company" name="company" className="form-select mb-3">
                                                    {optionCompany}
                                                </select>
                                            </div>
                                        </div>
                                        {this.state.selectedCompany > 0 ? <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="office" className="form-label">{this.props.t('emission.table.label2')}</label>
                                                <select onChange={e => this.onSelectOffice(e)} value={selectedOffice} id="company" name="company" className="form-select mb-3">
                                                    {optionOffice}
                                                </select>
                                            </div>
                                        </div> : ""}
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="category" className="form-label">{this.props.t('emission.form.label2')}</label> {/*<i id="ghg-emission" className="ri-question-line"></i>
                                                <ReactTooltip anchorId="ghg-emission" place="bottom" content="GHG Emission Source are ..............." />*/}
                                                <select onChange={e => this.onSelectCategory(e)} value={selectedCategory} id="category" name="category" className="form-select mb-1">
                                                    {optionCategory}
                                                </select>
                                                {/* eslint-disable-next-line */}
                                                {selectedCategory == 1 ?
                                                    <p>&nbsp;{this.props.t('emission.form.label3')} : <a target="_blank" rel="noreferrer" href="https://ditjenppi.menlhk.go.id/reddplus/images/adminppi/dokumen/igrk/Buku-EFDB.pdf">EFDB National KLHK 2017</a></p>
                                                    /* eslint-disable-next-line */
                                                    : selectedCategory == 25 ?
                                                        <p>&nbsp;{this.props.t('emission.form.label3')} : <a target="_blank" rel="noreferrer" href="https://www.ipcc.ch/report/ar6/wg1/downloads/report/IPCC_AR6_WGI_Chapter_07_Supplementary_Material.pdf">IPCC AR6 2023</a></p>
                                                        : selectedCategory == 6 ?
                                                            <p>&nbsp;{this.props.t('emission.form.label3')} : <a target="_blank" rel="noreferrer" href="https://www.ipcc-nggip.iges.or.jp/EFDB/find_ef.php?ipcc_code=2.B&ipcc_level=1">IPCC EFDB Guidelines 2006</a></p>
                                                            : selectedCategory == 7 ?
                                                                <p>&nbsp;{this.props.t('emission.form.label3')} : <a target="_blank" rel="noreferrer" href="https://gatrik.esdm.go.id/assets/uploads/download_index/files/96d7c-nilai-fe-grk-sistem-ketenagalistrikan-tahun-2019.pdf">GRK National ESDM 2019</a></p>
                                                                : selectedCategory != 0 ?
                                                                    <p>&nbsp;{this.props.t('emission.form.label3')} : <a target="_blank" rel="noreferrer" href="https://www.epa.gov/climateleadership/ghg-emission-factors-hub">EPA 2023</a></p>
                                                                    : ""}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="date" className="form-label">
                                                    {this.props.t('emission.form.label4')}
                                                </label>
                                                <div>
                                                    <DatePicker
                                                        id="date"
                                                        selected={selectedDate}
                                                        onChange={(date) => this.onSelectDate(date)}
                                                        dateFormat="MMMM yyyy"
                                                        className="form-select mb-3"
                                                        showMonthYearPicker
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                {/* eslint-disable-next-line */}
                                                <label htmlFor="category" className="form-label">{this.props.t('emission.form.label7')}</label>
                                                <input
                                                    onChange={e => this.onInputAsset(e, 0)}
                                                    value={listEmissionRecord[0].assets}
                                                    className="form-control"
                                                    placeholder={this.props.t('emission.form.enter')}
                                                >
                                                </input>
                                            </div>
                                        </div>
                                        {optionEmissionFactor.length > 0 ? <div className="col-md-6">
                                            <div className="mb-3">
                                                {/* eslint-disable-next-line */}
                                                <label htmlFor="category" className="form-label">{
                                                    selectedCategory == 25 ? this.props.t('emission.form.mineDepth') :
                                                        selectedCategory == 22 ? this.props.t('emission.form.label21') :
                                                            selectedCategory == 21 ? this.props.t('emission.form.label21') :
                                                                selectedCategory == 20 ? this.props.t('emission.form.label21') :
                                                                    selectedCategory == 18 ? this.props.t('emission.form.label22') :
                                                                        selectedCategory == 7 ? this.props.t('emission.form.label8') :
                                                                            (selectedCategory == 4 || selectedCategory == 26) ? this.props.t('emission.form.label19') :
                                                                                selectedCategory == 3 ? this.props.t('emission.form.label9') : this.props.t('emission.form.label10')}</label>
                                                <select onChange={e => this.onSelectEmission(e, 0)} value={listEmissionRecord[0].factor} className="form-select mb-0">
                                                    {/* eslint-disable-next-line */}
                                                    {listEmissionRecord[0].factor > 0 ? "" : <option value="0">{this.props.t('emission.form.select')} {
                                                        selectedCategory == 25 ? this.props.t('emission.form.mineDepth') :
                                                            selectedCategory == 22 ? this.props.t('emission.form.label21') :
                                                                selectedCategory == 21 ? this.props.t('emission.form.label21') :
                                                                    selectedCategory == 20 ? this.props.t('emission.form.label21') :
                                                                        selectedCategory == 18 ? this.props.t('emission.form.label22') :
                                                                            selectedCategory == 7 ? this.props.t('emission.form.label8') :
                                                                                (selectedCategory == 4 || selectedCategory == 26) ? this.props.t('emission.form.label19') :
                                                                                    selectedCategory == 3 ? this.props.t('emission.form.label9') : this.props.t('emission.form.label10')}</option>}
                                                    {optionEmissionFactor}
                                                </select>
                                            </div>
                                        </div> : ""}
                                        {
                                            selectedCategory == 26 && (
                                                <>
                                                    <div className="col-md-6">
                                                        <div className="mb-3" id="tour__inletCOD">
                                                            <label htmlFor="amount_2" className="form-label">
                                                                {this.props.t('emission.form.inletCOD')}
                                                            </label>
                                                            <NumericFormat
                                                                name='amount_2'
                                                                className="form-control"
                                                                value={listEmissionRecord[0].amount_2}
                                                                onValueChange={(values, sourceInfo) => this.onInletOutletCODInputNumericFormatChange(values, sourceInfo, 0)}
                                                                decimalSeparator={this.props.i18n.language === 'id' ? ',' : '.'}
                                                                thousandSeparator={this.props.i18n.language === 'id' ? '.' : ','}
                                                                placeholder={this.props.t('emission.form.enter')}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="mb-3" id="tour__outletCOD">
                                                            <label htmlFor="amount_3" className="form-label">
                                                                {this.props.t('emission.form.outletCOD')}
                                                            </label>
                                                            <NumericFormat
                                                                name='amount_3'
                                                                className="form-control"
                                                                value={listEmissionRecord[0].amount_3}
                                                                onValueChange={(values, sourceInfo) => this.onInletOutletCODInputNumericFormatChange(values, sourceInfo, 0)}
                                                                decimalSeparator={this.props.i18n.language === 'id' ? ',' : '.'}
                                                                thousandSeparator={this.props.i18n.language === 'id' ? '.' : ','}
                                                                placeholder={this.props.t('emission.form.enter')}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        {optionEmissionFactor.length > 0 ? <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="category" className="form-label">
                                                    {this.props.t('emission.form.label11')}
                                                </label>
                                                <select onChange={e => this.onSelectUnit(e, 0)} value={listEmissionRecord[0].unit} className="form-select mb-0">
                                                    {listEmissionRecord[0].unit > 0 ? "" : <option value="0">{this.props.t('emission.form.placeholder4')}</option>}
                                                    {optionEmissionFactorUnit}
                                                </select>
                                            </div>
                                        </div> : ""}
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                {/* eslint-disable-next-line */}
                                                <label htmlFor="category" className="form-label">{selectedCategory == 4 ? "Volume" : selectedCategory == 6 || selectedCategory == 5 ? "GHG Emissions (tCO\u2082e)" : "Amount"}</label>
                                                <NumericFormat
                                                    name='amount'
                                                    className="form-control"
                                                    value={listEmissionRecord[0].amount}
                                                    onValueChange={(values, sourceInfo) => this.onInputNumericFormatChange(values, sourceInfo, 0)}
                                                    decimalSeparator={this.props.i18n.language === 'id' ? ',' : '.'}
                                                    thousandSeparator={this.props.i18n.language === 'id' ? '.' : ','}
                                                    placeholder={this.props.t('emission.form.enter')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="formFile" className="form-label">{this.props.t('emission.form.label15')}</label>
                                                <p className="text-muted">{this.props.t('emission.form.label16')}</p>
                                                <Attachment id={listEmissionRecord[0].attachmentId}>
                                                    {({ attachment }) => {
                                                        useEffect(() => {
                                                            if (attachment.blob) {
                                                                const file = new File(
                                                                    [attachment.blob],
                                                                    attachment.name,
                                                                    { type: attachment.type },
                                                                )
                                                                const dataTransfer = new DataTransfer()
                                                                dataTransfer.items.add(file)
                                                                document.getElementById('formFile').files = dataTransfer.files;
                                                                this.setState({ selectedFile: file })
                                                            }
                                                        }, [attachment])

                                                        return <>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'baseline',
                                                                    flexDirection: 'column',
                                                                    gap: '0.5rem',
                                                                }}
                                                            >
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyItems: 'center',
                                                                    gap: '0.5rem',
                                                                }}>
                                                                    <button
                                                                        onClick={this.onAttachFile}
                                                                        type="button"
                                                                        className="btn btn-primary waves-effect waves-light"
                                                                    >
                                                                        {this.props.t('emission.form.label17')}
                                                                    </button>
                                                                    <span className="text-muted">
                                                                        {this.state.selectedFile?.name}
                                                                    </span>
                                                                </div>
                                                                {attachment.url
                                                                    ? <a
                                                                        className="btn btn-info"
                                                                        style={{ cursor: 'pointer' }}
                                                                        href={attachment.url}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        download={attachment.name}
                                                                    >
                                                                        {this.props.t('dashboard.btnDownload')}
                                                                    </a >
                                                                    : <p>{this.props.t('emission.noAttachment')}</p>
                                                                }
                                                            </div>
                                                            <input
                                                                onChange={this.onChangeFile}
                                                                style={{ display: "none" }}
                                                                className="form-control"
                                                                type="file"
                                                                id="formFile"
                                                                accept="image/*,.pdf,.doc"
                                                            />
                                                        </>
                                                    }}
                                                </Attachment>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="text-end">
                                                <button onClick={this.onSaveRecord} type="submit" className="btn btn-primary">{this.props.t('general.btnSave')}</button>
                                            </div>
                                        </div>
                                    </div>

                                    {/*this.state.selectedCategory > 0 ? <div className="row">
                                        <div className="col-md-12">
                                            <div className="mt-3 mb-3">
                                                <label htmlFor="companyCategory" className="form-label">Emission Records</label>
                                                <div className="table-responsive">
                                                    <table className="table table-hover table-striped align-middle table-nowrap mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">{selectedCategory == 6 || selectedCategory == 4 || selectedCategory == 5 ? "Process Name" : "Asset Name"}</th>
                                                                {optionEmissionFactor.length > 0 ? <th scope="col">{selectedCategory == 7 ? "Grid Name" : selectedCategory == 3 ? "Refrigerant Type" : "Fuel Type"}</th> : ""}
                                                                {optionEmissionFactor.length > 0 ? <th scope="col">Unit</th> : ""}
                                                                <th scope="col">{selectedCategory == 6 || selectedCategory == 4 || selectedCategory == 5 ? "GHG Emissions (tCO\u2082e)" : "Amount"}</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {emissionRows}
                                                            <tr>
                                                                <td colSpan={5}>
                                                                    <button onClick={() => this.onInsertRow()} type="button" className="btn btn-ghost-success">+ Add new record</button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""*/}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    count: state.counter.value,
    loggedIn: state.loggedIn.value,
    account: state.account.value,
    company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EmissionUpdate)));
