import React, {
  useState,
  useEffect,
} from 'react'
import {
  Navigate,
  useParams,
} from 'react-router-dom'
import axios from "axios"
import DatePicker from "react-datepicker"
import "react-tooltip/dist/react-tooltip.css"
import Swal from 'sweetalert2'
import { NumericFormat } from 'react-number-format'
import { useTranslation } from 'react-i18next'
import {
  ROUTE_PATHS,
} from '../../../library/helper'

import Attachment from '../../../components/Attachment'
import NotFound from '../../../components/NotFound'
import LoadingCard from '../../../components/LoadingCard'

const AttachmentChildren = ({
  isLoading,
  attachmentData,
  setSelectedFile,
  onAttachFile,
  selectedFile,
  onChangeFile,
}) => {
  const { t } = useTranslation()
  const [attachment, setAttachment] = useState({})

  useEffect(() => {
    if (attachmentData.type) {
      setAttachment(attachmentData)
    } else {
      setAttachment({})
    }
  }, [attachmentData])

  useEffect(() => {
    if (attachment.blob) {
      const file = new File(
        [attachment.blob],
        attachment.name,
        { type: attachment.type },
      )
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)
      document.getElementById('formFile').files = dataTransfer.files
      setSelectedFile(file)
    }
  }, [attachment])

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '7rem auto',
        }}
      >
        <div
          style={{
            padding: '5px 0',
          }}
        >
          <button
            onClick={onAttachFile}
            type="button"
            className="btn btn-primary waves-effect waves-light"
            style={{
              width: '100%',
            }}
            disabled={isLoading}
          >
            {t('emission.form.label17')}
          </button>
        </div>
        <div
          style={{
            padding: '5px 8px 5px',
            alignSelf: 'center',
          }}
        >
          {isLoading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <span className="text-muted">
              {selectedFile?.name}
            </span>
          )
          }
        </div>
        <div
          style={{
            padding: '5px 0',
          }}
        >
          {attachment?.url ? (
            <a
              className={`btn btn-info ${isLoading ? 'btn-soft-dark pe-none' : ''}`}
              style={{
                cursor: 'pointer',
                width: '100%',
              }}
              href={attachment.url}
              target="_blank"
              rel="noreferrer"
              download={attachment.name}
            >
              {t('dashboard.btnDownload')}
            </a>
          ) : (
            <p>{t('emission.noAttachment')}</p>
          )}
        </div>
      </div>
      <input
        onChange={onChangeFile}
        style={{ display: "none" }}
        className="form-control"
        type="file"
        id="formFile"
        accept="image/*,.pdf,.doc"
      />

    </>
  )
}

const getDistanceUnit = (text) => {
  let splitLabel
  if (text) {
    splitLabel = text.split("-") // Split the string by "-"
  }
  return Array.isArray(splitLabel) ? `(${splitLabel[1]})` : ''
}

const TransportationModeForm = ({
  row,
  setRow,
  transportationModeNameList,
}) => {
  const { t, i18n } = useTranslation()

  const onSelect = async (e) => {
    try {
      if (e.target.name === 'emissionName') {
        const value = e.target.value
        const unitLookup = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          params: {
            emissionName: e.target.value,
          },
          url: `${process.env.REACT_APP_BASE_SERVER}/emission-factor-unit-lookup`
        })
        if (unitLookup.data.ok) {
          setRow(prev => {
            return {
              ...prev,
              emissionName: value,
              recordUnit: 0,
              unitList: unitLookup.data.data,
              distanceUnit: '',
            }
          })
        }
      } else if (e.target.name === 'recordUnit') {
        const { options, selectedIndex } = e.target
        setRow(prev => {
          return {
            ...prev,
            recordUnit: Number(e.target.value),
            distanceUnit: getDistanceUnit(options[selectedIndex].text),
          }
        })
      }
    } catch (error) {
      let message
      if (typeof error.response?.data?.message === 'string') {
        message = error.response.data.message
      } else if (error.message) {
        message = error.message
      }
      Swal.fire({
        title: '',
        html: `<p>${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
        icon: 'error',
      })
    }
  }

  const onReset = () => {
    if (
      row.emissionName ||
      row.recordUnit ||
      row.recordAmount
    ) {
      setRow(prev => {
        return {
          ...prev,
          emissionName: '',
          recordUnit: 0,
          unitList: [],
          recordAmount: 0,
          distanceUnit: '',
        }
      })
    }
  }

  const onInputNumericFromatChange = (values, sourceInfo) => {
    if (sourceInfo?.event?.target?.name && values) {
      if (sourceInfo.event.target.name === 'recordAmount') {
        setRow(prev => {
          return {
            ...prev,
            recordAmount: values.value,
          }
        })
      }
    }
  }

  const required = (
    row.orderNumber === 1 && <span className="text-danger">*</span>
  )

  const efReferences = Array
    .isArray(row.unitList) ?
    row.unitList.find(list => list.unit_id === Number(row.recordUnit)) :
    {}

  return (
    <div className="row" key={row.orderNumber}>
      <div className="col-md-4">
        <div className="mb-3">
          <label htmlFor="emissionName" className="form-label">
            {t('emission.form.transportationMode')} {row.orderNumber} {required}
          </label>
          <select
            id="emissionName"
            name="emissionName"

            onChange={onSelect}
            value={row.emissionName}
            className="form-select mb-0"
          >
            {!row.emissionName && (
              <option value={''} key={0}>
                {t('emission.form.select')}
              </option>
            )}
            {
              transportationModeNameList.length > 0 &&
              transportationModeNameList.map(item => {
                return (
                  <option
                    key={item}
                    value={item}
                  >
                    {item}
                  </option>
                )
              })
            }
          </select>
        </div>
      </div>
      <div className="col-md-4">
        <div className="mb-3">
          <label
            htmlFor="recordUnit"
            className="form-label"
          >
            {t('emission.form.unit')} {row.orderNumber} {required}
          </label>
          <select
            id="recordUnit"
            name="recordUnit"

            onChange={onSelect}
            value={row.recordUnit}
            className="form-select mb-0"
          >
            {(
              !row.emissionName ||
              !row.recordUnit
            ) && (
                <option value={0} key={0}>
                  {t('emission.form.select')}
                </option>
              )}
            {
              row.emissionName &&
              row.unitList.length > 0 &&
              row.unitList.map(item => {
                return (
                  <option
                    key={item.unit_id}
                    value={item.unit_id}
                  >
                    {item.unit_label}
                  </option>
                )
              })
            }
          </select>
          {
            efReferences &&
              Array.isArray(efReferences.references) &&
              efReferences.references.length &&
              row.recordUnit ? (
              <p>&nbsp;{t('emission.form.label3')} : {efReferences.references.map(item => {
                return <a
                  target="_blank"
                  rel="noreferrer"
                  href={item.link}
                  key={item.label}
                >
                  {item.label} &nbsp;
                </a>
              })}
              </p>
            ) : <p>&nbsp;</p>
          }
        </div>
      </div>
      <div className="col-md-3">
        <div className="mb-3">
          <label
            htmlFor="recordAmount"
            className="form-label"
          >
            {t('emission.form.distance')} {row.orderNumber} {row.distanceUnit} {required}
          </label>
          <NumericFormat
            id="recordAmount"
            name="recordAmount"
            className="form-control"
            value={row.recordAmount}
            onValueChange={onInputNumericFromatChange}
            decimalSeparator={i18n.language === 'id' ? ',' : '.'}
            thousandSeparator={i18n.language === 'id' ? '.' : ','}
            placeholder={t('emission.form.enter')}
          />
        </div>
      </div>
      <div className="col-md-1">
        <div className="mb-3">
          <label
            htmlFor="reset"
            className="form-label"
            style={{ visibility: 'hidden' }}
          >
            Reset
          </label>
          <div
            className='d-flex gap-2'
          >
            <button
              onClick={onReset}
              type="button"
              className="btn btn-icon btn-soft-warning"
              data-toggle="tooltip"
              data-placement="top"

              title={row.orderNumber === 1 ? t('emission.form.reset') : t('emission.form.resetOrLeaveItBlank')}
              disabled={((
                !row.recordAmount &&
                !row.emissionName &&
                !row.recordUnit
              )
              )}
            >
              <i className=" ri-delete-back-2-line"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function EmissionScope3Category7Update() {
  const { t, i18n } = useTranslation()
  const { id } = useParams()

  const [redirectEmission, setRedirectEmission] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [companyList, setCompanyList] = useState([])
  const [selectedCompanyId, setSelectedCompanyId] = useState(0)
  const [locationList, setLocationList] = useState([])
  const [selectedLocationId, setSelectedLocationId] = useState(0)
  const [employeeName, setEmployeeName] = useState('')
  const [noOfEmployee, setNoOfEmployee] = useState(0)
  const [daysOfCommuting, setDaysOfCommuting] = useState(0)
  const [transportationModeNameList, setTransportationModeNameList] = useState([])
  const [selectedYear, setSelectedYear] = useState('')
  const [attachmentId, setAttachmentId] = useState('')

  const [transportMode1, setTransportMode1] = useState({})
  const [transportMode2, setTransportMode2] = useState({})
  const [transportMode3, setTransportMode3] = useState({})
  const [transportMode4, setTransportMode4] = useState({})
  const [transportMode5, setTransportMode5] = useState({})

  const [vehicleType, setVehicleType] = useState('')
  const [fuelType, setFuelType] = useState('')

  const [isHighLevelForm, setIsHighLevelForm] = useState(true)

  const [isNotFound, setIsNotFound] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const getIntitialData = async () => {

      try {
        setIsLoading(true)
        const responseInitialData = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          params: {
            uuid: id,
          },
          url: `${process.env.REACT_APP_BASE_SERVER}/commuting-emission-record-lookup`
        })
        if (responseInitialData.data.ok) {
          const {
            record,
            company_list,
            location_list,
            transportation_mode_unit_list,
            transportation_mode_name_list,
            all_transportation_mode_list,
            is_high_level_form,
          } = responseInitialData.data.data

          setIsHighLevelForm(is_high_level_form)

          setEmployeeName(record.record_assets)
          setNoOfEmployee(record.record_amount_7)
          setDaysOfCommuting(record.record_amount_1)

          const getModeObject = (orderNumber) => {
            if (record[`record_factor_${orderNumber}`]) {
              let splitLabel
              if (record[`unit_label_${orderNumber}`]) {
                splitLabel = record[`unit_label_${orderNumber}`]
                  .split("-") // Split the string by "-"
              }
              const distanceUnit = Array.isArray(splitLabel) ? `(${splitLabel[1]})` : ''
              const factorIds = all_transportation_mode_list
                .filter(list => list.emission_name === record[`emission_name_${orderNumber}`])
                .map(list => list.emission_factor_id)

              return {
                emissionName: record[`emission_name_${orderNumber}`],
                recordUnit: record[`record_unit_${orderNumber}`],
                unitList: transportation_mode_unit_list.filter(list => {
                  return factorIds.includes(list.unit_factor)
                }),
                distanceUnit,
                recordAmount: record[`record_amount_${orderNumber + 1}`],
                orderNumber: orderNumber,
              }
            } else {
              return {
                emissionName: '',
                recordUnit: 0,
                unitList: [],
                recordAmount: 0,
                distanceUnit: '',
                orderNumber: orderNumber,
              }
            }
          }

          setTransportMode1(getModeObject(1))
          setTransportMode2(getModeObject(2))
          setTransportMode3(getModeObject(3))
          setTransportMode4(getModeObject(4))
          setTransportMode5(getModeObject(5))

          setVehicleType(record.record_misc?.vehicle_type || '')
          setFuelType(record.record_misc?.fuel_type || '')


          setTransportationModeNameList(transportation_mode_name_list || [])

          setAttachmentId(record.attachment_id)

          setSelectedYear(new Date(record.record_date) || null)

          setCompanyList(company_list)

          setSelectedCompanyId(record.company_id)

          setSelectedLocationId(record.office_id)

          setLocationList(location_list)

        }
      } catch (error) {
        if (error?.response?.data?.status === 404) {
          setIsNotFound(true)
        } else {
          let message
          if (typeof error.response?.data?.message === 'string') {
            message = error.response.data.message
          } else if (error.message) {
            message = error.message
          }
          Swal.fire({
            icon: 'error',
            title: '',
            html: `<p style="font-size:0.75rem">${message}</p>`,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
        }
      } finally {
        setIsLoading(false)
      }
    }
    getIntitialData()
  }, [])

  const onSaveRecord = async (e) => {
    try {
      let data = new FormData()
      data.append('uuid', id)
      data.append('companyId', selectedCompanyId)
      data.append('locationId', selectedLocationId)
      data.append('employeeName', employeeName)
      if (isHighLevelForm) {
        data.append('noOfEmployee', 1)
      } else {
        data.append('noOfEmployee', noOfEmployee)
        data.append('vehicleType', vehicleType)
        data.append('fuelType', fuelType)
      }
      data.append('daysOfCommuting', daysOfCommuting)
      data.append('transportMode1', JSON.stringify(transportMode1))
      data.append('transportMode2', JSON.stringify(transportMode2))
      data.append('transportMode3', JSON.stringify(transportMode3))
      data.append('transportMode4', JSON.stringify(transportMode4))
      data.append('transportMode5', JSON.stringify(transportMode5))
      data.append('year', new Date(selectedYear).getFullYear())
      if (selectedFile) data.append('attachment', selectedFile)

      const response = await axios({
        withCredentials: true,
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: data,
        url: `${process.env.REACT_APP_BASE_SERVER}/commuting-emission-record-submission`
      })

      if (response.data.ok) {
        Swal.fire({
          title: '',
          html: '<p>Successfully record emissions</p>',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            setRedirectEmission(true)
          }
        })
      }
    } catch (error) {
      let message
      if (typeof error.response?.data?.message === 'string') {
        message = error.response.data.message
      } else if (error.message) {
        message = error.message
      }
      Swal.fire({
        title: '',
        html: `<p>${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
        icon: 'error',
      })
    }
  }

  const onAttachFile = async () => {
    document.getElementById('formFile').click()
  }

  const onChangeFile = async (e) => {
    const files = e.target.files
    setSelectedFile(files?.[0])
  }

  if (redirectEmission) {
    return <Navigate to={ROUTE_PATHS.scope3category7} />
  }

  const onSelect = async (e) => {
    try {
      const selectedValue = Number(e.target.value)
      if (e.target.name === 'companyId') {
        setSelectedCompanyId(selectedValue)
        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          params: {
            companyId: selectedValue,
          },
          url: `${process.env.REACT_APP_BASE_SERVER}/client-office-lookup`
        })
        if (response.data.ok) {
          setLocationList(response.data.data)
          setSelectedLocationId(0)
        }
      } else if (e.target.name === 'locationId') {
        setSelectedLocationId(selectedValue)
      }
    } catch (error) {
      let message
      if (typeof error.response?.data?.message === 'string') {
        message = error.response.data.message
      } else if (error.message) {
        message = error.message
      }
      Swal.fire({
        title: '',
        html: `<p>${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
        icon: 'error',
      })
    }
  }

  const onInputChange = (e) => {
    if (e.target.name === 'employeeName') {
      setEmployeeName(e.target.value)
    } else if (e.target.name === 'vehicleType') {
      setVehicleType(e.target.value)
    } else if (e.target.name === 'fuelType') {
      setFuelType(e.target.value)
    }
  }

  const onInputNumericFromatChange = (values, sourceInfo) => {
    if (sourceInfo?.event?.target?.name && values) {
      if (sourceInfo.event.target.name === 'daysOfCommuting') {
        setDaysOfCommuting(values.value)
      } else if (sourceInfo.event.target.name === 'noOfEmployee') {
        setNoOfEmployee(values.value)
      }
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">
                {t("menu.scope3Category7Title")}
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">

            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  {t('general.btnUpdate')}
                </h4>
              </div>

              {isLoading ? (
                <div className="card-body">
                  <LoadingCard />
                </div>
              ) : (
                <>
                  {isNotFound ? (
                    <div className="card-body">
                      <NotFound />
                    </div>
                  ) : (
                    <div className="card-body">
                      <h5>{t('emission.form.info1')}</h5>
                      <p className='mb-3'>{t('emission.form.subInfo1')}</p>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label
                              htmlFor="companyId"
                              className="form-label"
                            >
                              {t('emission.form.label1')} <span className="text-danger">*</span>
                            </label>
                            <select
                              id="companyId"
                              name="companyId"
                              onChange={onSelect}
                              value={selectedCompanyId}
                              className="form-select mb-3"
                            >
                              {selectedCompanyId === 0 && (
                                <option
                                  key={0}
                                  value={0}
                                >
                                  {t('emission.form.select')}
                                </option>
                              )}
                              {companyList.map(item => {
                                return (
                                  <option
                                    key={item.company_id}
                                    value={item.company_id}
                                  >
                                    {item.company_name}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                        {selectedCompanyId > 0 && (
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="locationId"
                                className="form-label"
                              >
                                {t('emission.table.label2')} <span className="text-danger">*</span>
                              </label>
                              <select
                                id="locationId"
                                name="locationId"
                                onChange={onSelect}
                                value={selectedLocationId}
                                className="form-select mb-3"
                              >
                                {selectedLocationId === 0 && (
                                  <option
                                    key={0}
                                    value={0}
                                  >
                                    {t('emission.form.select')}
                                  </option>
                                )}
                                {locationList.map(item => {
                                  return (
                                    <option
                                      key={item.office_id}
                                      value={item.office_id}
                                    >
                                      {item.office_name}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>

                      {
                        isHighLevelForm ? (
                          <div className="row">
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label htmlFor="employeeName" className="form-label">
                                  {t('emission.form.employeeName')} <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="employeeName"
                                  name="employeeName"
                                  onChange={onInputChange}
                                  value={employeeName}
                                  className="form-control"
                                  placeholder={t('emission.form.employeeName')}
                                >
                                </input>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label htmlFor="year" className="form-label">
                                  {t('emission.form.year')} <span className="text-danger">*</span>
                                </label>
                                <div>
                                  <DatePicker
                                    id="year"
                                    name="year"
                                    showYearPicker
                                    dateFormat="yyyy"
                                    yearItemNumber={9}
                                    selected={selectedYear}
                                    className="form-select"
                                    onChange={(date) => setSelectedYear(date)}
                                    minDate={new Date(`${new Date().getFullYear() - 2}-01-13`)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label
                                  htmlFor="daysOfCommuting"
                                  className="form-label"
                                >
                                  {t('emission.form.daysOfCommuting')} <span className="text-danger">*</span>
                                </label>
                                <NumericFormat
                                  id="daysOfCommuting"
                                  name="daysOfCommuting"
                                  className="form-control"
                                  value={daysOfCommuting}
                                  onValueChange={onInputNumericFromatChange}
                                  decimalSeparator={i18n.language === 'id' ? ',' : '.'}
                                  thousandSeparator={i18n.language === 'id' ? '.' : ','}
                                  placeholder={t('emission.form.perYear')}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label htmlFor="employeeName" className="form-label">
                                    {t('emission.form.employeeName')} <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="employeeName"
                                    name="employeeName"
                                    onChange={onInputChange}
                                    value={employeeName}
                                    className="form-control"
                                    placeholder={t('emission.form.employeeName')}
                                  >
                                  </input>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="noOfEmployee"
                                    className="form-label"
                                  >
                                    {t('emission.form.noOfEmployee')} <span className="text-danger">*</span>
                                  </label>
                                  <NumericFormat
                                    id="noOfEmployee"
                                    name="noOfEmployee"
                                    className="form-control"
                                    value={noOfEmployee}
                                    onValueChange={onInputNumericFromatChange}
                                    decimalSeparator={i18n.language === 'id' ? ',' : '.'}
                                    thousandSeparator={i18n.language === 'id' ? '.' : ','}
                                    placeholder={t('emission.form.perYear')}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label htmlFor="year" className="form-label">
                                    {t('emission.form.year')} <span className="text-danger">*</span>
                                  </label>
                                  <div>
                                    <DatePicker
                                      id="year"
                                      name="year"
                                      showYearPicker
                                      dateFormat="yyyy"
                                      yearItemNumber={9}
                                      selected={selectedYear}
                                      className="form-select"
                                      onChange={(date) => setSelectedYear(date)}
                                      minDate={new Date(`${new Date().getFullYear() - 2}-01-13`)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="daysOfCommuting"
                                    className="form-label"
                                  >
                                    {t('emission.form.daysOfCommuting')} <span className="text-danger">*</span>
                                  </label>
                                  <NumericFormat
                                    id="daysOfCommuting"
                                    name="daysOfCommuting"
                                    className="form-control"
                                    value={daysOfCommuting}
                                    onValueChange={onInputNumericFromatChange}
                                    decimalSeparator={i18n.language === 'id' ? ',' : '.'}
                                    thousandSeparator={i18n.language === 'id' ? '.' : ','}
                                    placeholder={t('emission.form.perYear')}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}


                      <h5 className="pt-2 mt-1">{t('emission.form.info2')}</h5>
                      <p className="mb-3">{t('emission.form.specifyTransportMode')}</p>

                      <TransportationModeForm
                        row={transportMode1}
                        setRow={setTransportMode1}
                        transportationModeNameList={transportationModeNameList}
                      />

                      {
                        isHighLevelForm ? (
                          <>
                            <TransportationModeForm
                              row={transportMode2}
                              setRow={setTransportMode2}
                              transportationModeNameList={transportationModeNameList}
                            />
                            <TransportationModeForm
                              row={transportMode3}
                              setRow={setTransportMode3}
                              transportationModeNameList={transportationModeNameList}
                            />
                            <TransportationModeForm
                              row={transportMode4}
                              setRow={setTransportMode4}
                              transportationModeNameList={transportationModeNameList}
                            />
                            <TransportationModeForm
                              row={transportMode5}
                              setRow={setTransportMode5}
                              transportationModeNameList={transportationModeNameList}
                            />
                          </>
                        ) : (
                          <div className="row">
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label htmlFor="vehicleType" className="form-label">
                                  {t('emission.form.vehicleType')} <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="vehicleType"
                                  name="vehicleType"
                                  onChange={onInputChange}
                                  value={vehicleType}
                                  className="form-control"
                                  placeholder={t('emission.form.vehicleType')}
                                >
                                </input>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <label htmlFor="fuelType" className="form-label">
                                  {t('emission.form.fuelType')} <span className="text-danger">*</span>
                                </label>
                                <input
                                  id="fuelType"
                                  name="fuelType"
                                  onChange={onInputChange}
                                  value={fuelType}
                                  className="form-control"
                                  placeholder={t('emission.form.fuelType')}
                                >
                                </input>
                              </div>
                            </div>
                          </div>
                        )
                      }

                      <h5 className="pt-2 mt-1">{t('emission.form.info3')}</h5>

                      <div className="col-md-12">
                        <div className="mb-3">
                          <label htmlFor="formFile" className="form-label">
                            {t('emission.form.label15')}
                          </label>
                          <p className="text-muted">
                            {t('emission.form.label16')}
                          </p>
                          <div className="col-md-6">
                            <Attachment id={attachmentId}>
                              {({ attachment, isLoading }) => {
                                return <AttachmentChildren
                                  setSelectedFile={setSelectedFile}
                                  onAttachFile={onAttachFile}
                                  selectedFile={selectedFile}
                                  onChangeFile={onChangeFile}
                                  attachmentData={attachment}
                                  isLoading={isLoading}
                                />
                              }}
                            </Attachment>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="text-end">
                          <button
                            onClick={onSaveRecord}
                            type="submit"
                            className="btn btn-primary"
                          >
                            {t('general.btnSave')}
                          </button>
                        </div>
                      </div>
                    </div >
                  )}
                </>
              )}

            </div>
          </div>
        </div>
      </div>

    </div >
  )
}
