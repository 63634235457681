import { useMutation } from '@tanstack/react-query'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  useTranslation,
} from 'react-i18next'

import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import {
  MRT_Localization_ID,
} from 'material-react-table/locales/id'
import {
  MRT_Localization_EN,
} from 'material-react-table/locales/en'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import axios from 'axios';
import qs from 'qs';
import Swal from 'sweetalert2'

import DateCell from '../components/DateCell'

import {
  twoDecimalFormat,
} from '../library/helper'

const useTableLookup = () => {
  return useMutation({
    mutationKey: ['emission/revenue_get'],
    retry: false,
    mutationFn: async (data) => axios({
      url: [
        process.env.REACT_APP_BASE_SERVER,
        '/emission/revenue_get',
      ].join(''),
      method: 'POST',
      withCredentials: true,
      data,
    }).then(response => {
      return response?.data?.data ?? []
    }),
  })
}

const useTable = () => {
  const { t, i18n } = useTranslation()

  const {
    data = [],
    isPending: isLoading,
    mutate: requestRevenues,
  } = useTableLookup()

  useEffect(() => {
    requestRevenues()
  }, [])

  const onDeleteCall = async (data) => {
    const options = {
      withCredentials: true,
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: `${process.env.REACT_APP_BASE_SERVER}/emission/revenue/delete`
    };
    const response = await axios(options);
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  }

  const onDelete = async (id) => {
    let invalidData = true;
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          'id': id,
        };
        const result = onDeleteCall(data)
        if (result) {
          invalidData = false;
        }

        if (invalidData) {
          Swal.fire({
            title: '',
            html: '<p>Cannot delete data, please try again later</p>',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
        } else {
          Swal.fire({
            title: 'Deleted',
            text: 'Your data has been deleted.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          })
        }
      }
    })
  }

  return useMaterialReactTable({
    enableBottomToolbar: true,
    enableColumnOrdering: true,
    enableDensityToggle: false,
    enableExpandAll: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    enablePagination: true,
    enableTableFooter: false,
    enableTopToolbar: true,
    initialState: { density: 'compact' },
    localization: i18n.language === 'id' ? (
      MRT_Localization_ID
    ) : MRT_Localization_EN,
    muiTablePaperProps: {
      elevation: 0,
    },
    columns: [
      {
        size: 100,
        accessorKey: 'revenue_date_time',
        header: t('emission.table.label1'),
        // Transform data before processing so sorting works
        accessorFn: (row) => new Date(row.revenue_date_time),
        Cell: ({ row }) => <DateCell dateTime={row.original.revenue_date_time} />,
        sortingFn: (rowA, rowB) => {
          const a = rowA.original.revenue_date_time
          const b = rowB.original.revenue_date_time

          if (a > b) {
            return 1
          } else if (b > a) {
            return -1
          } else {
            return 0
          }
        },
      },
      {
        size: 100,
        accessorKey: 'revenue_company',
        header: t('emission.table.label2'),
      },
      {
        size: 100,
        accessorKey: 'product_name',
        header: t('setting.revenueAndProduction.label'),
      },
      {
        size: 100,
        accessorKey: 'revenue_amount',
        header: t('emission.table.label5'),
        Cell: ({ cell, row }) => <div className='d-flex flex-column flex-wrap gap-2'>
          <span>
            {twoDecimalFormat(cell.getValue(), i18n.language)} {row.original.product_unit}
          </span>
        </div>,
      },
      {
        size: 100,
        accessorKey: 'revenue_uuid',
        header: t('emission.table.label6'), // Action column
        enableSorting: false,
        enableColumnFilter: false,
        enableGrouping: false,
        Cell: ({ cell }) => <div className='d-flex flex-wrap gap-2'>
          <Link
            to={'/revenue/update/' + cell.getValue()}
            className='btn btn-info btn-icon waves-effect waves-light'
          >
            <i className='ri-pencil-fill'></i>
          </Link>
          <button
            onClick={() => onDelete(cell.getValue())}
            type='button'
            className='btn btn-warning btn-icon waves-effect waves-light'
          >
            <i className='ri-delete-bin-2-fill'></i>
          </button>
        </div>,
      },
    ],
    data,
    state: {
      isLoading: isLoading,
    }
  })
}

export default function Revenue() {
  const { t } = useTranslation()
  const table = useTable()
  return (
    <div className='page-content'>
      <div className='container-fluid'>

        <div className='row'>
          <div className='col-12'>
            <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
              <h4 className='mb-sm-0'>
                {t('menu.label22')}
              </h4>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-header'>
                <h5 className='card-title mb-0'>
                  {t('general.read')}
                </h5>
                <br /><br />
                <Link
                  to={'/revenue/create'}
                  className='btn btn-success'
                >
                  {t('general.btnCreate')}
                </Link>
              </div >
              <div className='card-body'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MaterialReactTable table={table} />
                </LocalizationProvider>
              </div>
            </div >
          </div >
        </div >
      </div >
    </div >
  )
}