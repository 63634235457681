import {
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import xlsx from 'json-as-xlsx'
import { useSelector } from 'react-redux'

import {
  ROUTE_PATHS,
  SCENARIO_LOCAL_STORAGE_KEY,
  ANALYTICS_LABEL,
  AREA_CHART_COLORS,
  twoDecimalFormat,
} from '../library/helper'
import ReactApexChart from 'react-apexcharts'

import Download from './Download'
import MultilineChartLegend from './ApexCharts/MultilineChartLegend'
import EmptyAreaChart from './ApexCharts/EmptyAreaChart'

const LABEL = ANALYTICS_LABEL

const properties = {
  emissionVsTarget: {
    chartId: 'emissionVsTarget',
    apiURL: 'record-vs-target-summaries-lookup',
    titleTranslation: 'scenario.absoluteEmissionsVSAbsoluteEmissionTarget',
    downloadedChartName: 'absolute emissions vs absolute emission target',
  },
  carbonIntensityVsTarget: {
    chartId: 'carbonIntensityVsTarget',
    apiURL: 'carbon-intensity-vs-target-summaries-lookup',
    titleTranslation: 'analytics.carbonIntensity.emissionIntensityVSIntensityBasedEmissionTarget',
    downloadedChartName: 'emission intensity vs intensity-based emission target',
  },
  SBTiScope12: {
    chartId: 'SBTiScope12',
    apiURL: 'analytics-sbti-scope12-lookup',
    titleTranslation: 'analytics.sbti.scope12',
    downloadedChartName: 'sbti-scope12',
  },
  SBTiScope3: {
    chartId: 'SBTiScope3',
    apiURL: 'analytics-sbti-scope3-lookup',
    titleTranslation: 'analytics.sbti.scope3',
    downloadedChartName: 'sbti-scope3',
  },
}

const calculateSeries = (data, seriesKeys) => {
  if (data?.length > 0 && Array.isArray(seriesKeys)) {
    return seriesKeys
      .map(({ item, color }, i) => {
        return ({
          name: item,
          data: data
            .map(itemData => {
              return typeof itemData?.[item] === 'number'
                ? parseFloat(itemData[item]?.toFixed(2))
                : null
            }),
          zIndex: i + 1,
          color,
        })
      })
  } else {
    return []
  }
}

const NoData = ({
  onDashboard,
}) => {
  return (
    <div className='d-flex flex-column gap-2 pb-3 px-3'
    >
      <div style={{
        position: 'absolute',
        borderStyle: 'solid',
        borderWidth: '1px',
        padding: '0.5rem',
        borderRadius: 14,
        fontSize: '12px',
        fontWeight: 'bold',
        backgroundColor: 'white',
        alignSelf: 'center',
        top: onDashboard ? '20%' : '50%',

      }}>
        No Data
      </div>
    </div>
  )
}

function MultilineChartCard(props) {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const account = useSelector((state) => state.account.value)
  const apexChartRef = useRef()

  const [data, setData] = useState([])
  const [lines, setLines] = useState()
  const [revenueUnit, setRevenueUnit] = useState('')
  const [showGrowthRateForm, setShowGrowthRateForm] = useState(false)
  const [growthRate, setGrowthRate] = useState()
  const [averageGrowthRate, setAverageGrowthRate] = useState(null)
  const [prevSbtiSetting, setPrevSbtiSetting] = useState({})
  const [newSbtiSetting, setNewSbtiSetting] = useState({})

  const [filteredData, setFilteredData] = useState([])
  const [activeKeys, setActiveKeys] = useState([])

  const [hiddenSeries, setHiddenSeries] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const options = {
    chart: {
      id: 'chart',
      height: 280,
      toolbar: {
        show: false,
      },
      type: 'area',
      zoom: {
        enabled: true,
        type: 'xy',
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: '#90CAF9',
            opacity: 0.4
          },
          stroke: {
            color: '#0D47A1',
            opacity: 0.4,
            width: 1
          }
        }
      },
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 2,
    },
    xaxis: {
      type: 'year',
      categories: data
        .map(item => item?.[LABEL.timescale])
        .filter((value, index, array) => array.indexOf(value) === index).sort()
    },
    yaxis: {
      title: {
        text: location.pathname === ROUTE_PATHS.analyticsCarbonIntensity
          ? revenueUnit
            ? `tCO₂e/${revenueUnit}`
            : 'tCO₂e'
          : 'tCO₂e'
      },
      labels: {
        formatter: function (value) {
          return twoDecimalFormat(value, i18n.language)
        },
      },
      tickAmount: 4,
      min: 0,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val
            ? twoDecimalFormat(val, i18n.language) + (location.pathname === ROUTE_PATHS.analyticsCarbonIntensity
              ? revenueUnit
                ? ` tCO₂e/${revenueUnit}`
                : ' tCO₂e'
              : ' tCO₂e')
            : null
        },
        title: {
          formatter: function (seriesName) {
            const findLine = Array.isArray(lines) && lines.find(line => line.label === seriesName)
            const titleText = findLine
              ? i18n.language === 'id'
                ? findLine.labelId || seriesName
                : seriesName
              : seriesName
            return `<span style='text-transform: capitalize;'>${titleText}:</span>`
          },
        },
      },
    },
    markers: {},
  }

  const getSeriesKeys = () => {
    if (data?.length > 0 && lines?.length > 0) {
      const keys = lines.map(line => line.label)

      const getMinMaxYear = (key) => {
        const values = data.map(d => d[key])
        const yearStartIndex = values.findIndex(el => el !== undefined)
        let yearEndIndex
        const findYearEndIndex = values.findLastIndex(el => el !== undefined)
        if (findYearEndIndex === -1) {
          yearEndIndex = values[values.length - 1]
        } else {
          yearEndIndex = findYearEndIndex
        }

        return {
          min: values[yearStartIndex],
          max: values[yearEndIndex],
        }
      }

      const minMaxReduction = getMinMaxYear(LABEL.emission)
      const minMaxActual = getMinMaxYear(LABEL.actualEmission)

      const theFirstTwoItems = []

      if (keys.find(key => key === LABEL.carbonIntensity)) {
        theFirstTwoItems.push(LABEL.carbonIntensity)
      } else {
        if (minMaxReduction.max > minMaxActual.max) {
          theFirstTwoItems.push(LABEL.emission, LABEL.actualEmission)
        } else {
          theFirstTwoItems.push(LABEL.actualEmission, LABEL.emission)
        }
      }

      const remainingKeys = keys.filter(item => {
        return (
          item !== LABEL.actualEmission &&
          item !== LABEL.emission &&
          item !== LABEL.carbonIntensity
        )
      })

      remainingKeys.sort((a, b) => {
        const minMaxA = getMinMaxYear(a)
        const minMaxB = getMinMaxYear(b)

        if (minMaxA.max < minMaxB.max) {
          return 1
        } else {
          return -1
        }
      })

      const theFirstTwoItemsWithColors = theFirstTwoItems.map(item => {
        let color
        if (
          item === LABEL.actualEmission ||
          item === LABEL.carbonIntensity
        ) {
          color = AREA_CHART_COLORS[0]
        } else {
          color = AREA_CHART_COLORS[1]
        }
        return {
          item,
          color,
        }
      })

      const remainingKeysWithColors = remainingKeys.map((item, itemIndex) => {
        return {
          item,
          color: AREA_CHART_COLORS[itemIndex + theFirstTwoItemsWithColors.length],
        }
      })

      const sortedKeys = [...remainingKeysWithColors, ...theFirstTwoItemsWithColors]

      return sortedKeys

    } else {
      return []
    }
  }
  const seriesKeys = getSeriesKeys()

  const series = useMemo(() => calculateSeries(data, seriesKeys),
    [data, seriesKeys]
  )

  // Set circle markers
  if (series.length > 0) {
    const descretes = []
    for (let i = 0; i < series.length; i++) {
      for (let j = 0; j < series[i].data.length; j++) {
        const seriesGroup = lines.find(line => line.label === series[i].name)
        if (seriesGroup) {
          if (seriesGroup.group === 1) {
            descretes.push({
              seriesIndex: i,
              dataPointIndex: j,
              fillColor: series[i].color,
              strokeColor: '#fff',
              size: 4,
              shape: "circle"
            })
          } else if (
            seriesGroup.group === 3 &&
            Array.isArray(seriesGroup.targetYears) &&
            data?.length > 0
          ) {
            for (let k = 0; k < seriesGroup.targetYears.length; k++) {
              const targetYear = seriesGroup.targetYears[k]
              const dataPointIndexGroup3 = data.findIndex(d => d.timescale === targetYear)
              if (dataPointIndexGroup3 !== -1 && j === dataPointIndexGroup3) {
                descretes.push({
                  seriesIndex: i,
                  dataPointIndex: j,
                  fillColor: series[i].color,
                  strokeColor: '#fff',
                  size: 4,
                  shape: "circle"
                })
              }
            }
          } else if (seriesGroup.label === LABEL.carbonIntensity) {
            descretes.push({
              seriesIndex: i,
              dataPointIndex: j,
              fillColor: series[i].color,
              strokeColor: '#fff',
              size: 4,
              shape: "circle"
            })
          }
        }
      }
    }
    options.markers.discrete = descretes
    options.stroke.curve = series.map(s => {
      if (
        s.name === LABEL.emission ||
        s.name === LABEL.actualEmission ||
        s.name === LABEL.carbonIntensity
      ) {
        return 'smooth'
      } else {
        return 'straight'
      }
    })
  }

  useEffect(() => {
    const controller = new AbortController()

    const getData = async () => {
      try {
        setIsLoading(true)
        const params = {}
        if (
          location.pathname === ROUTE_PATHS.scenario ||
          props.onDashboard
        ) {
          params.startYear = props.startYear
          params.endYear = props.endYear
          params.growthRate = growthRate
          params.checkedScenarios = {
            isFound: false,
            checkedScenarioIds: []
          }
          if (props.onDashboard) {
            params.onDashboard = true
          } else {
            const checkedScenarioIds = JSON.parse(localStorage.getItem(SCENARIO_LOCAL_STORAGE_KEY))
            params.checkedScenarios = {
              isFound: !!checkedScenarioIds,
              checkedScenarioIds: checkedScenarioIds
            }
          }
        } else if (location.pathname === ROUTE_PATHS.analyticsSBTi) {
          params.growthRate = newSbtiSetting.growthRate
        } else if (location.pathname === ROUTE_PATHS.analyticsCarbonIntensity) {
          params.startYear = props.startYear
          params.endYear = props.endYear
          params.clientProductId = props.selectedProductId
        }

        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          params,
          url: `${process.env.REACT_APP_BASE_SERVER}/${properties[props.chartId].apiURL}`,
          signal: controller.signal,
        })
        if (response.data.ok) {
          setData(response.data.data.dataChart)
          setLines(response.data.data.lineChart)
          setRevenueUnit(response.data.data.revenueUnit)
          setShowGrowthRateForm(response.data.data.showGrowthRateForm)
          setAverageGrowthRate(response.data.data.averageGrowthRate)

          setPrevSbtiSetting({
            nearTermLar: response.data.data.nearTermLar,
            longTermGoal: response.data.data.longTermGoal,
            nearTermTargetYear: response.data.data.nearTermTargetYear,
            longTermTargetYear: response.data.data.longTermTargetYear,
            growthRate: response.data.data.averageGrowthRate, // in %
          })

          setActiveKeys(prevKeys => {
            if (prevKeys.some(key => key.label === LABEL.emissionPathway)) {
              return prevKeys
                .filter(initKey => {
                  return (
                    initKey.label !== LABEL.nearTermSBTs
                    && initKey.label !== LABEL.longTermSBTs
                  )
                })
            } else if (prevKeys.some(key => key.label === LABEL.nearTermSBTs)) {
              return prevKeys
                .filter(initKey => {
                  return (
                    initKey.label !== LABEL.emissionPathway
                    && initKey.label !== LABEL.longTermSBTs
                  )
                })
            } else if (prevKeys.some(key => key.label === LABEL.longTermSBTs)) {
              return prevKeys
                .filter(initKey => {
                  return (
                    initKey.label !== LABEL.emissionPathway
                    && initKey.label !== LABEL.nearTermSBTs
                  )
                })
            } else if (prevKeys.length > 0) {
              return prevKeys
            } else {
              return response.data.data.lineChart
                .filter(initKey => {
                  if (location.pathname === ROUTE_PATHS.analyticsSBTi) {
                    return (
                      initKey.label !== LABEL.nearTermSBTs
                      && initKey.label !== LABEL.longTermSBTs
                    )
                  } else {
                    return true
                  }
                })
            }
          })
        }
      } catch (error) {
        if (error.code && error.code !== 'ERR_CANCELED') {
          Swal.fire({
            icon: 'error',
            title: '',
            html: error.response.data.message,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
        }
      } finally {
        setIsLoading(false)
      }
    }
    if (
      props.startYear &&
      props.endYear &&
      props.endYear > props.startYear
    ) {
      getData()
    } else {
      setIsLoading(false)
    }

    return () => controller.abort()
  }, [props.startYear, props.endYear, props.chartId, props.onDashboard, growthRate, newSbtiSetting, props.selectedProductId])

  const onDownload = () => {
    props.onDownloadImage(props.chartId, properties[props.chartId].downloadedChartName)
  }

  const onDownloadExcel = () => {
    const columns = [
      { label: "YEAR", value: "timescale" },
    ]
    if (activeKeys.some(key => key.label === LABEL.actualEmission)) {
      columns.push({ label: LABEL.actualEmission.toLocaleUpperCase(), value: LABEL.actualEmission })
    }
    if (activeKeys.some(key => key.label === LABEL.projection)) {
      columns.push({ label: LABEL.projection.toLocaleUpperCase(), value: LABEL.projection })
    }
    if (activeKeys.some(key => key.label === LABEL.emissionPathway)) {
      columns.push({ label: LABEL.emissionPathway.toLocaleUpperCase(), value: LABEL.emissionPathway })
    }
    if (activeKeys.some(key => key.label === LABEL.nearTermSBTs)) {
      columns.push({ label: LABEL.nearTermSBTs.toLocaleUpperCase(), value: LABEL.nearTermSBTs })
    }
    if (activeKeys.some(key => key.label === LABEL.longTermSBTs)) {
      columns.push({ label: LABEL.longTermSBTs.toLocaleUpperCase(), value: LABEL.longTermSBTs })
    }
    columns.push({ label: 'GAP', value: 'gap' })

    const content = filteredData.map(row => {
      const newObject = {}
      columns.forEach(col => {
        newObject[col.value] = row[col.value]
      })
      if (row[columns[2].value] && row[columns[3].value]) {
        newObject.gap = Number(row[columns[2].value]) - Number(row[columns[3].value])
      }
      return newObject
    })

    const data = [
      {
        sheet: "Data",
        columns,
        content,
      },
    ]

    const settings = {
      fileName: `${props.chartId}_${account.client_name}`,
      extraLength: 3,
      writeMode: "writeFile",
      writeOptions: {},
    }

    xlsx(data, settings)
  }

  useEffect(() => {
    if (
      apexChartRef.current &&
      series.length > 0
    ) {
      const chart = apexChartRef.current.chart

      if (hiddenSeries.length > 0) {
        series.forEach(item => {
          if (hiddenSeries.includes(item.name)) {
            chart.hideSeries(item.name)
          } else {
            chart.showSeries(item.name)
          }
        })
      } else {
        series.forEach(item => {
          chart.showSeries(item.name)
        })
      }
    }

  }, [hiddenSeries, series])

  const FilterSeries = (seriesName = "") => {
    if (apexChartRef.current) {

      let currentData = [...hiddenSeries]
      const checkIndex = currentData.findIndex(item => item === seriesName)

      if (checkIndex < 0) currentData.push(seriesName)
      else currentData.splice(checkIndex, 1)

      setHiddenSeries(currentData)
    }
  }

  if (isLoading) {
    return (
      <div
        className='bg-white d-flex flex-column flex-grow-1 flex-shrink-1 gap-1 h-100 justify-content-center align-items-center'
        style={{
          borderRadius: '8px',
          width: '100%',
        }}
      >
        <div className="d-flex flex-wrap gap-3 align-content-center"
          style={{
            height: props.onDashboard ? '300px' : '75vh'
          }}
        >
          <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-warning" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="spinner-grow text-danger" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className='bg-white d-flex flex-column flex-grow-1 flex-shrink-1 gap-1 h-100 justify-content-center'
        style={{
          borderRadius: '8px',
          width: '100%',
        }}
        id={props.chartId}
      >
        <div className='align-items-start d-flex gap-3 justify-content-between pt-3 px-3 w-100'>
          <div>
            <p
              className={`fw-semibold m-0 ${series.length > 0 ? '' : 'text-muted'}`}
              style={{ fontSize: 12 }}
            >
              {t(properties[props.chartId].titleTranslation)}
            </p>
            {
              location.pathname !== ROUTE_PATHS.analyticsSBTi && (
                <p
                  className={`${series.length > 0 ? '' : 'text-muted'}`}
                  style={{ fontSize: 10 }}
                >
                  {account['client_scope'] > 1 ? t('analytics.chart.scope12And3') : t('analytics.chart.scope1And2')}
                </p>
              )
            }
          </div>
          {
            location.pathname === ROUTE_PATHS.analyticsSBTi ? (
              <div className="flex-shrink-0">
                <ul className="list-inline text-end mb-0">
                  <li className="list-inline-item m-0">
                    <div className="dropdown">
                      <button
                        className="btn btn-ghost-secondary btn-icon btn-sm"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="ri-more-2-fill "></i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-end">
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={onDownload}
                        >
                          <i className="ri-file-line align-bottom text-muted me-2"></i>
                          {t('sbti.saveAsPng')}
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={onDownloadExcel}
                        >
                          <i className="ri-file-excel-line align-bottom text-muted me-2"></i>
                          {t('sbti.saveAsExcel')}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              <Download
                onClick={onDownload}
                isDisabled={series.length === 0}
              />
            )
          }
        </div>

        <div className='px-3 position-relative'>
          {
            series.length === 0 || (
              location.pathname === ROUTE_PATHS.analyticsCarbonIntensity &&
              !props.selectedProductId
            )
              ? <EmptyAreaChart
                onDashboard={props.onDashboard}
                revenueUnit={revenueUnit}
              />
              : <ReactApexChart
                options={options}
                series={series}
                type='area'
                height={props.onDashboard ? 200 : 465}
                ref={apexChartRef}
              />
          }
          {
            (
              series.length === 0 || (
                location.pathname === ROUTE_PATHS.analyticsCarbonIntensity &&
                !props.selectedProductId
              )
            ) && <NoData onDashboard={props.onDashboard} />
          }
        </div>
        {
          series?.length > 0 && (
            <MultilineChartLegend
              series={series}
              filterSeries={FilterSeries}
              hiddenSeries={hiddenSeries}
              onDashboard={props.onDashboard}
              lines={lines}
              averageGrowthRate={averageGrowthRate}
              setGrowthRate={setGrowthRate}
            />
          )
        }
      </div>
    )
  }
}

export default MultilineChartCard
