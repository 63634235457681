import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SingleRowWrapper from '../Layouts/Row'
import SelectCompany from '../SelectCompany'
import MonthlyEmission from '../MonthlyEmission'

import {
  twoDecimalFormat,
} from '../../../library/helper'

function LowerSection({
  companyOptions,
  onDownloadImage,
  selectedYear,
}) {
  const { t, i18n } = useTranslation()
  const [selectedCompanyId, setSelectedCompanyId] = useState()

  const optionsTotalEmissionByScope = {
    chart: {
      id: 'chartTotalEmissionByScope',
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: i18n.language === 'id'
        ? ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agt', 'Sep', 'Okt', 'Nov', 'Des']
        : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
      title: {
        text: 'tCO₂e',
      },
      labels: {
        formatter: function (value) {
          return twoDecimalFormat(value, i18n.language)
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return twoDecimalFormat(val, i18n.language) + " tCO₂e"
        },
      },
    },
  }

  const optionsTotalEmissionByCategory = {
    chart: {
      id: 'chartTotalEmissionByCategory',
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: i18n.language === 'id'
        ? ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agt', 'Sep', 'Okt', 'Nov', 'Des']
        : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    yaxis: {
      title: {
        text: 'tCO₂e',
      },
      labels: {
        formatter: function (value) {
          return twoDecimalFormat(value, i18n.language)
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return twoDecimalFormat(val, i18n.language) + " tCO₂e"
        },
      },
    },
  }

  useEffect(() => {
    if (
      Array.isArray(companyOptions) &&
      companyOptions.length > 0 &&
      companyOptions[0].companyId
    ) {
      setSelectedCompanyId(companyOptions[0].companyId)
    }
  }, [companyOptions, selectedYear])

  const onChangeCompany = (event) => {
    setSelectedCompanyId(Number(event.target.value))
  }

  const onDownloadMonthlyScope = () => {
    if (selectedCompanyId) {
      const selectedCompany = Array.isArray(companyOptions) && companyOptions
        .find(company => company.companyId === selectedCompanyId)
      if (selectedCompany) {
        onDownloadImage(`monthlyScope${selectedCompanyId}`,
          `${t('dashboard.card.title10')} ${selectedCompany.companyName}`)
      }
    }
  }

  const onDownloadMonthlyCategory = () => {
    if (selectedCompanyId) {
      const selectedCompany = Array.isArray(companyOptions) && companyOptions
        .find(company => company.companyId === selectedCompanyId)
      if (selectedCompany) {
        onDownloadImage(`monthlyCategory${selectedCompanyId}`,
          `${t('dashboard.card.title11')} ${selectedCompany.companyName}`)
      }
    }
  }

  return (
    <>
      {
        Array.isArray(companyOptions) && companyOptions.length > 1 && (
          <SingleRowWrapper>
            <SelectCompany
              onChange={onChangeCompany}
              option={companyOptions.map(companyOpt => (
                <option key={companyOpt.companyId} value={companyOpt.companyId}>
                  {companyOpt.companyName}
                </option>
              ))}
              title={t('dashboard.card.title9')}
              value={selectedCompanyId}
            />
          </SingleRowWrapper>
        )
      }
      {
        !!selectedCompanyId && (
          <>
            <MonthlyEmission
              customLabelName={"Scope"}
              chartHeight={350}
              chartType="bar"
              apiRoute='/record-by-scope-monthly-summaries-lookup'
              chartId={`monthlyScope${selectedCompanyId}`}
              filterLabel={t('dashboard.chart.stackedbar.filterByScope')}
              options={optionsTotalEmissionByScope}
              onDownload={onDownloadMonthlyScope}
              selectedCompanyId={selectedCompanyId}
              selectedYear={selectedYear}
              title={t('dashboard.card.title10')}
            />
            <MonthlyEmission
              chartHeight={350}
              chartType="bar"
              apiRoute='/record-by-category-monthly-summaries-lookup'
              chartId={`monthlyCategory${selectedCompanyId}`}
              filterLabel={t('dashboard.chart.stackedbar.filterByCategory')}
              onDownload={onDownloadMonthlyCategory}
              options={optionsTotalEmissionByCategory}
              selectedCompanyId={selectedCompanyId}
              selectedYear={selectedYear}
              title={t('dashboard.card.title11')}
            />
          </>
        )
      }
    </>
  )
}

export default LowerSection