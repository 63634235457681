import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { NumericFormat } from 'react-number-format'

import { connect } from "react-redux"
import { increment, decrement } from "../features/counter/counterSlice"
import { setLoggedIn } from "../features/account/loginSlice"
import { setAccount } from "../features/account/accountSlice"
import { setCompany } from "../features/account/companySlice"

import axios from "axios";
import qs from 'qs';
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2';
import "react-tooltip/dist/react-tooltip.css";

import {
    hidePreloader,
    showPreloader,
    twoDecimalFormat,
} from '../library/helper';
import { withTranslation } from 'react-i18next';

import "react-datepicker/dist/react-datepicker.css";

export class SupplychainCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectLogin: false,
            redirectEmission: false,
            selectedCompany: 0,
            selectedOffice: 0,
            selectedDate: new Date(Date.now()).setFullYear(new Date(Date.now()).getFullYear() - 1),
            selectedScope: 0,
            selectedCategory: 0,
            selectedItem: 0,
            amountItem: 0,

            listEmissionRecord: [
                {
                    assets: "",
                    factor: 0,
                    amount: 0,
                    amount_2: 0,
                    amount_3: 0,
                    amount_4: 0,
                    amount_5: 0,
                    amount_6: 0,
                    amount_7: 0,
                    amount_8: 0,
                    unit: 0,
                    multiplier: 1,
                }
            ],

            listCategory: [],
            listFactor: [],
            itemUnit: '',

            amountEmission: 0,
            countRecords: 1,

            selectedSupplier: 0,
            selectedSupplierCarbonIntensity: 0,
            selectedSupplierUnit: 0,
        }
    }

    componentDidMount = async () => {
        showPreloader();

        let listCompany = [];
        let listCategory = [];
        let listSupplychain = [];
        let listEmissionFactor = [];
        let listEmissionFactorUnit = [];

        const data = {}

        const optionsGetCompany = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/company`
        };
        const responseGetCompany = await axios(optionsGetCompany);
        if (responseGetCompany.data.success) {
            listCompany = responseGetCompany.data.data
        }

        const optionsGetSupplychain = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/supplychain`
        };
        const responseGetSupplychain = await axios(optionsGetSupplychain);
        if (responseGetSupplychain.data.success) {
            listSupplychain = responseGetSupplychain.data.data
        }

        const optionsGetEmissionCategory = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/supplychain/category`
        };
        const responseGetEmissionCategory = await axios(optionsGetEmissionCategory);
        if (responseGetEmissionCategory.data.success) {
            listCategory = responseGetEmissionCategory.data.data
        }

        const optionsGetEmissionFactor = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor`
        };
        const responseGetEmissionFactor = await axios(optionsGetEmissionFactor);
        if (responseGetEmissionFactor.data.success) {
            listEmissionFactor = responseGetEmissionFactor.data.data
        }

        const optionsGetEmissionFactorUnit = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/emission_factor_unit`
        };
        const responseGetEmissionFactorUnit = await axios(optionsGetEmissionFactorUnit);
        if (responseGetEmissionFactorUnit.data.success) {
            listEmissionFactorUnit = responseGetEmissionFactorUnit.data.data
        }

        this.setState({
            listCompany: listCompany,
            listCategory: listCategory,
            listSupplychain: listSupplychain,
            listEmissionFactor: listEmissionFactor,
            listEmissionFactorUnit: listEmissionFactorUnit,
            selectedOffice: 0,
        })

        hidePreloader();
    }

    onSelectCompany = async (e) => {
        let companyValue = e.target.value;
        const data = {
            'coid': companyValue
        };
        const optionsGetOffice = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/office`
        };
        const responseGetOffice = await axios(optionsGetOffice);
        if (responseGetOffice.data.success) {
            this.setState({
                selectedCompany: companyValue,
                listOffice: responseGetOffice.data.data,
                selectedOffice: 0,
            })
        }
    }

    onSelectCategory = async (e) => {
        /*let categoryValue = e;
        this.setState({
            selectedCategory: categoryValue,
        })*/
        let categoryValue = e.target.value;

        this.setState({
            selectedCategory: categoryValue,
            selectedSupplier: 0,
            selectedSupplierCarbonIntensity: 0,
            amountItem: 0,
            listEmissionRecord: [
                {
                    assets: "",
                    factor: 0,
                    amount: "",
                    unit: 0,
                    multiplier: 1,
                }
            ],
        })
    }

    onSelectOffice = async (e) => {
        let officeValue = e.target.value;
        this.setState({
            selectedOffice: officeValue,
        })
    }

    onSelectDate = async (e) => {
        var datePick = new Date(new Date(e).getFullYear(), new Date(e).getMonth(), 7);
        this.setState({
            selectedDate: datePick,
            selectedSupplierCarbonIntensity: 0,
        }, () => {
            this.onRefreshCarbonIntensity();
        })
    }

    onInsertRow = async () => {
        let data = this.state.listEmissionRecord;
        data.push({
            assets: "",
            factor: 0,
            amount: "",
            unit: 0,
            multiplier: 1,
        })
        this.setState({
            countRecords: this.state.countRecords + 1,
        })
    }

    onDeleteRow = async (index) => {
        let data = this.state.listEmissionRecord;
        data.splice(index, 1)
        this.setState({
            countRecords: this.state.countRecords - 1,
        })
    }

    onInputAsset = async (e, index) => {
        let data = this.state.listEmissionRecord;
        data[index].assets = e.target.value;
        this.setState({
            listEmissionRecord: data
        })
    }

    onSelectEmission = async (e, index) => {
        let data = this.state.listEmissionRecord;
        data[index].factor = e.target.value;
        this.setState({
            listEmissionRecord: data
        })
    }

    onSelectUnit = async (e, index) => {
        let data = this.state.listEmissionRecord;
        data[index].unit = e.target.value;
        let listEmissionFactorUnit = this.state.listEmissionFactorUnit;
        let filteredUnit = listEmissionFactorUnit.filter(
            // eslint-disable-next-line
            item => item.unit_id == e.target.value
        )
        data[index].multiplier = filteredUnit[0].unit_multiplier;
        this.setState({
            listEmissionRecord: data
        })
    }

    onInputAmount = async (e) => {
        let amount = e.target.value;
        this.setState({
            amountItem: amount
        })
    }

    onInputNumericFormatChange = (values, sourceInfo) => {
        if (sourceInfo?.event?.target?.name && values) {
            this.setState({
                amountItem: values.value
            })
        }
    }

    onSelectSupplier = async (e) => {
        let supplier = e.target.value;
        this.setState({
            selectedSupplier: supplier,
            selectedSupplierCarbonIntensity: 0,
        }, () => {
            this.onRefreshCarbonIntensity();
        })
    }

    onRefreshCarbonIntensity = async () => {
        let totalEmission = 0;
        let totalRevenue = 0;
        let revenueUnit = "";

        let data = {
            'supplychain': this.state.selectedSupplier,
            'year': new Date(this.state.selectedDate).getFullYear()
        };

        const optionsTotalEmission = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/supplychain/total_emission`
        };
        const responseTotalEmission = await axios(optionsTotalEmission);
        if (responseTotalEmission.data.success) {
            totalEmission = responseTotalEmission.data.data[0]?.emission_total;
        }

        const optionsTotalRevenue = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/supplychain/total_revenue`
        };
        const responseTotalRevenue = await axios(optionsTotalRevenue);
        if (responseTotalRevenue.data.success) {
            totalRevenue = responseTotalRevenue.data.data[0]?.revenue_total;
        }

        const optionsGetSetting = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/supplychain/setting`
        };
        const responseGetSetting = await axios(optionsGetSetting);
        if (responseGetSetting.data.success) {
            // eslint-disable-next-line
            if (responseGetSetting.data.data[0]?.client_revenue_unit == 1) {
                revenueUnit = "IDR mn";
            } else {
                revenueUnit = "USD k";
            }
        }

        this.setState({
            selectedSupplierCarbonIntensity: (parseFloat(totalEmission)),
            selectedSupplierUnit: revenueUnit,
        })
    }

    onSaveRecordCall = async (data) => {
        const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/supplychain/create`
        };
        const response = await axios(options);
        if (response.data.success) {
            return true;
        } else {
            return false;
        }
    }

    onSaveRecord = async (e) => {
        let invalidData = true;
        let data = {
            'scope': 3,
            'category': this.state.selectedCategory,
            'factor': this.state.selectedSupplier,
            'intensity': this.state.selectedSupplierCarbonIntensity,
            'company': this.state.selectedCompany,
            'location': this.state.selectedOffice,
            'date': this.state.selectedDate,
            'amount': this.state.amountItem,
        };
        const result = this.onSaveRecordCall(data);
        //const result = false;
        if (result) {
            invalidData = false;
        }
        if (invalidData) {
            Swal.fire({
                title: '',
                html: '<p>Please fill all information needs</p>',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            })
        } else {
            Swal.fire({
                title: '',
                html: '<p>Successfully record emissions</p>',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        redirectEmission: true
                    })
                }
            })
        }
    }

    render() {
        const { redirectLogin, redirectEmission } = this.state;
        const { listCompany, selectedCompany } = this.state;
        const { listOffice, selectedOffice } = this.state;
        const { selectedDate } = this.state;
        const { listCategory, selectedCategory } = this.state;
        const { listEmissionFactor, listEmissionFactorUnit, listEmissionRecord } = this.state;
        const { countRecords } = this.state;

        if (redirectLogin) {
            return <Navigate to={'/login'} />
        }

        if (redirectEmission) {
            return <Navigate to={'/scope3/category15'} />
        }

        let optionCompany = [];
        optionCompany.push(selectedCompany > 0 ? null : <option key={0} value={0}>{this.props.t('emission.form.placeholder1')}</option>)
        // eslint-disable-next-line
        if (listCompany != undefined) {
            if (listCompany.length > 0) {
                listCompany.map(async (item, index) => {
                    optionCompany.push(<option key={index + 1} value={item.company_id}>{item.company_name}</option>)
                })
            }
        }

        let optionOffice = [];
        optionOffice.push(selectedOffice > 0 ? null : <option key={0} value={0}>{this.props.t('emission.form.placeholder2')}</option>)
        // eslint-disable-next-line
        if (listOffice != undefined) {
            if (listOffice.length > 0) {
                listOffice.map(async (item, index) => {
                    optionOffice.push(<option key={index + 1} value={item.office_id}>{item.office_name}</option>)
                })
            }
        }

        let optionCategory = [];
        optionCategory.push(selectedCategory > 0 ? null : <option key={0} value={0}>{this.props.t('emission.form.placeholder3')}</option>)
        // eslint-disable-next-line
        if (listCategory != undefined) {
            if (listCategory.length > 0) {
                listCategory.map(async (item, index) => {
                    if (this.props.i18n.language === "id") {
                        optionCategory.push(<option key={index + 1} value={item.category_id}>{item.category_name_id}</option>)
                    } else {
                        optionCategory.push(<option key={index + 1} value={item.category_id}>{item.category_name}</option>)
                    }
                    // eslint-disable-next-line
                    /*if(item.category_id == selectedCategory){
                        optionCategory.push(
                            <button key={index} onClick={() => this.onSelectCategory(item.category_id)} className='text-center rounded-md border border-transparent bg-indigo-600 py-2 h-full w-full align-middle text-sm font-medium text-white hover:bg-indigo-700 col-span-6 md:col-span-6'>
                            {item.category_name}</button>
                        )
                    }else{
                        optionCategory.push(
                            <button key={index} onClick={() => this.onSelectCategory(item.category_id)} className='text-center rounded-md border border-transparent bg-indigo-300 py-2 h-full w-full align-middle text-sm font-medium text-white hover:bg-indigo-700 col-span-6 md:col-span-6'>
                            {item.category_name}</button>
                        )
                    }*/
                })
            }
        }

        let optionSupplychain = [];
        //optionSupplychain.push(this.state.selectedSupplier > 0 ? null : <option key={0} value={0}>Select Supplychain</option>)
        optionSupplychain.push(<option key={0} value={0}>{this.props.t('emission.form.placeholder1')}</option>)
        // eslint-disable-next-line
        if (this.state.listSupplychain != undefined) {
            if (this.state.listSupplychain.length > 0) {
                this.state.listSupplychain.map(async (item, index) => {
                    // eslint-disable-next-line
                    if (this.state.selectedCategory == item.relation_category) {
                        optionSupplychain.push(<option key={index + 1} value={item.client_id}>{item.client_name}</option>)
                    }
                })
            }
        }

        let optionEmissionFactor = [];
        // eslint-disable-next-line
        if (selectedCategory != 0) {
            // eslint-disable-next-line
            if (listEmissionFactor != undefined) {
                if (listEmissionFactor.length > 0) {
                    listEmissionFactor.map(async (item, index) => {
                        // eslint-disable-next-line
                        if (item.cf_category == selectedCategory) {
                            optionEmissionFactor.push(
                                <option key={index + 1} value={item.emission_factor_id}>{item.emission_name}</option>
                            )
                        }
                    })
                }
            }
        }

        let emissionRows = [];
        for (let i = 0; i < countRecords; i++) {
            let optionEmissionFactorUnit = [];
            // eslint-disable-next-line
            if (listEmissionRecord[i].factor != 0) {
                // eslint-disable-next-line
                if (listEmissionFactorUnit != undefined) {
                    if (listEmissionFactorUnit.length > 0) {
                        listEmissionFactorUnit.map(async (item, index) => {
                            // eslint-disable-next-line
                            if (item.unit_factor == listEmissionRecord[i].factor) {
                                optionEmissionFactorUnit.push(
                                    <option key={index + 1} value={item.unit_id}>{item.unit_label}</option>
                                )
                            }
                        })
                    }
                }
            }

            if (optionEmissionFactor.length > 0) {
                emissionRows.push(
                    <tr key={i}>
                        <td>
                            <input
                                onChange={e => this.onInputAsset(e, i)}
                                value={listEmissionRecord[i].assets}
                                className="form-control"
                                placeholder="assets"
                            ></input>
                        </td>
                        <td>
                            <select onChange={e => this.onSelectEmission(e, i)} value={listEmissionRecord[i].factor} className="form-select mb-0">
                                {/* eslint-disable-next-line */}
                                {listEmissionRecord[i].factor > 0 ? "" : <option value="0">Select {selectedCategory == 7 ? "Grid Name" : selectedCategory == 3 ? "Refrigerant Type" : "Fuel Type"}</option>}
                                {optionEmissionFactor}
                            </select>
                        </td>
                        <td>
                            <select onChange={e => this.onSelectUnit(e, i)} value={listEmissionRecord[i].unit} className="form-select mb-0">
                                {listEmissionRecord[i].unit > 0 ? "" : <option value="0">Select Unit</option>}
                                {optionEmissionFactorUnit}
                            </select>
                        </td>
                        <td>
                            <input
                                type="number"
                                onChange={e => this.onInputAmount(e, i)}
                                value={listEmissionRecord[i].amount}
                                className="form-control"
                                placeholder="amount"
                            ></input>
                        </td>
                        <td>
                            <button onClick={() => this.onDeleteRow(i)} type="button" className="btn btn-icon btn-ghost-danger"><i className="ri-delete-bin-5-line"></i></button>
                        </td>
                    </tr>
                )
            } else {
                emissionRows.push(
                    <tr key={i}>
                        <td>
                            <input
                                onChange={e => this.onInputAsset(e, i)}
                                value={listEmissionRecord[i].assets}
                                className="form-control"
                                placeholder="assets"
                            ></input>
                        </td>
                        <td>
                            <input
                                type="number"
                                onChange={e => this.onInputAmount(e, i)}
                                value={listEmissionRecord[i].amount}
                                className="form-control"
                                placeholder="amount"
                            ></input>
                        </td>
                        <td>
                            <button onClick={() => this.onDeleteRow(i)} type="button" className="btn btn-icon btn-ghost-danger"><i className="ri-delete-bin-5-line"></i></button>
                        </td>
                    </tr>
                )
            }
        }

        return (
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">
                                    {this.props.t('menu.scope3Category15Title')}
                                </h4>

                                {/*<div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#page">Pages</a></li>
                                        <li className="breadcrumb-item active">Starter</li>
                                    </ol>
                                </div>*/}

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">{this.props.t('general.btnCreate')}</h4>
                                </div>

                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="company" className="form-label">
                                                    {this.props.t('emission.form.label1')}
                                                </label>
                                                <select
                                                    onChange={e => this.onSelectCompany(e)}
                                                    value={selectedCompany}
                                                    id="company"
                                                    name="company"
                                                    className="form-select mb-3"
                                                >
                                                    {optionCompany}
                                                </select>
                                            </div>
                                        </div>
                                        {this.state.selectedCompany > 0 ? <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="office" className="form-label">
                                                    {this.props.t('emission.table.label2')}
                                                </label>
                                                <select
                                                    onChange={e => this.onSelectOffice(e)}
                                                    value={selectedOffice}
                                                    id="location"
                                                    name="location"
                                                    className="form-select mb-3"
                                                >
                                                    {optionOffice}
                                                </select>
                                            </div>
                                        </div> : ""}
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="category" className="form-label">
                                                    {this.props.t('emission.form.label2')}
                                                </label>
                                                {/*<i id="ghg-emission" className="ri-question-line"></i>
                                                <ReactTooltip anchorId="ghg-emission" place="bottom" content="GHG Emission Source are ..............." />*/}
                                                <select
                                                    onChange={e => this.onSelectCategory(e)}
                                                    value={selectedCategory}
                                                    id="category"
                                                    name="category"
                                                    className="form-select mb-1"
                                                >
                                                    {optionCategory}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="date" className="form-label">
                                                    {this.props.t('emission.form.year')}
                                                </label>
                                                <div>
                                                    <DatePicker
                                                        id="date"
                                                        selected={selectedDate}
                                                        onChange={(date) => this.onSelectDate(date)}
                                                        dateFormat="yyyy"
                                                        className="form-select mb-3"
                                                        showYearPicker
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {selectedCategory > 0 ?
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="category" className="form-label">
                                                        {this.props.t('emission.form.borrowerCompany')}
                                                    </label>
                                                    <select
                                                        onChange={e => this.onSelectSupplier(e)}
                                                        value={this.state.selectedSupplier}
                                                        className="form-select mb-0"
                                                    >
                                                        {optionSupplychain}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="category" className="form-label">
                                                        {this.props.t('emission.form.borrowerTotalEmissions')}
                                                    </label>
                                                    <p className="form-control border-0 mb-0">
                                                        <strong className="fs-5">
                                                            {
                                                                this.state.selectedSupplierCarbonIntensity > 0
                                                                    ? twoDecimalFormat(this.state.selectedSupplierCarbonIntensity, this.props.i18n.language)
                                                                    : 0
                                                            }
                                                        </strong>
                                                        {
                                                            this.state.selectedSupplierCarbonIntensity > 0
                                                                ? <span> tCO<sub>2</sub>e</span>
                                                                : ""
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="category" className="form-label">
                                                        {this.props.t('emission.form.bankExposure')}
                                                    </label>
                                                    <button
                                                        type="button"
                                                        className="btn btn-link waves-effect"
                                                        title={this.props.t('emission.form.bankExposureExplanation')}
                                                    >
                                                        <i className="ri-information-line"></i>
                                                    </button>
                                                    {
                                                        // eslint-disable-next-line
                                                        this.state.selectedSupplierUnit != "" ?
                                                            <div className="input-group">
                                                                <NumericFormat
                                                                    name='amount'
                                                                    className="form-control"
                                                                    value={this.state.amountItem}
                                                                    onValueChange={this.onInputNumericFormatChange}
                                                                    decimalSeparator={this.props.i18n.language === 'id' ? ',' : '.'}
                                                                    thousandSeparator={this.props.i18n.language === 'id' ? '.' : ','}
                                                                    placeholder={this.props.t('emission.form.label13')}
                                                                />
                                                                <span
                                                                    className="input-group-text"
                                                                    id="basic-addon2"
                                                                    title={this.props.t('emission.form.percentage')}
                                                                >
                                                                    %
                                                                </span>
                                                            </div>
                                                            :
                                                            <NumericFormat
                                                                name='amount'
                                                                className="form-control"
                                                                value={this.state.amountItem}
                                                                onValueChange={this.onInputNumericFormatChange}
                                                                decimalSeparator={this.props.i18n.language === 'id' ? ',' : '.'}
                                                                thousandSeparator={this.props.i18n.language === 'id' ? '.' : ','}
                                                                placeholder={this.props.t('emission.form.label13')}
                                                            />
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <div className="text-end">
                                                    <button
                                                        onClick={this.onSaveRecord}
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        {this.props.t('general.btnSave')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        : ""}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    count: state.counter.value,
    loggedIn: state.loggedIn.value,
    account: state.account.value,
    company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SupplychainCreate));