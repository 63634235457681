import {
  useState,
  useCallback,
} from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'

export default function CustomAutocomplete({
  id,
  label,
  handleInputChange,
  handleChange,
  options,
  loading,
  handleOpen = () => null,
  renderOption,
}) {
  const [open, setOpen] = useState(false)

  const onOpen = useCallback(() => {
    setOpen(true)
    handleOpen()
  }, [handleOpen])

  const onClose = () => {
    setOpen(false)
  }

  const getOptionSelected = (option, value) => {
    return option.key === value.key
  }

  const getOptionLabel = (option) => {
    return option.label
  }

  return (
    <Autocomplete
      id={id || 'autocomplete-demo'}
      sx={{ width: 400 }}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isOptionEqualToValue={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || "Autocomplete"}
          className="form-control"

          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
