import {
  useQuery,
  useMutation,
} from '@tanstack/react-query'
import {
  Link,
} from 'react-router-dom'
import {
  useTranslation,
} from 'react-i18next'

import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import {
  MRT_Localization_ID,
} from 'material-react-table/locales/id'
import {
  MRT_Localization_EN,
} from 'material-react-table/locales/en'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import axios from 'axios'
import Swal from 'sweetalert2'

import {
  ROUTE_PATHS,
} from '../library/helper'

const useClientProductDeletion = () => {
  return useMutation({
    mutationKey: [
      'client-product-deletion',
    ],
    retry: false,
    mutationFn: async (data) => {
      return axios({
        url: [
          process.env.REACT_APP_BASE_SERVER,
          '/client-product-deletion',
        ].join(''),
        method: 'POST',
        withCredentials: true,
        data,
      })
    },
  })
}

const useTableLookup = () => {
  return useQuery({
    queryKey: [
      'client-product-summary-lookup',
    ],
    retry: false,
    queryFn: ({ signal }) => axios({
      url: [
        process.env.REACT_APP_BASE_SERVER,
        '/client-product-summary-lookup',
      ].join(''),
      method: 'GET',
      withCredentials: true,
      signal,
    }).then(response => {
      return response?.data?.data ?? {}
    }),
  })
}

const useTable = (location) => {
  const { t, i18n } = useTranslation()

  const {
    data = {},
    isLoading,
    isRefetching,
    refetch,
  } = useTableLookup(location)

  const {
    mutate,
    isPending,
  } = useClientProductDeletion()

  const onDelete = async (id) => {
    Swal.fire({
      title: t('dialog.delete.areYouSure'),
      text: t('dialog.delete.youWontBeAbleToRevertThis'),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: t('dialog.delete.cancel'),
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('dialog.delete.yesDeleteIt'),
    }).then(async (result) => {
      if (result.isConfirmed) {
        mutate({
          id,
        }, {
          onSuccess: () => {
            Swal.fire({
              title: `<h5 style="font-size:1.125rem">${t('dialog.delete.deleted')}</h5>`,
              html: `<p style="font-size:0.75rem">${t('dialog.delete.yourDataHasBeenDeleted')}</p>`,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                refetch()
              }
            })
          },
          onError: (error) => {
            let message = t('dialog.delete.cannotDeleteDataPleaseTryAgainLater')
            if (error.response?.data?.messageType === 'productLinkedToRevenue') {
              message = t('dialog.delete.product.productLinkedToRevenue')
            } else if (typeof error.response?.data?.message === 'string') {
              message = error.response.data.message
            } else if (error.message) {
              message = error.message
            }
            Swal.fire({
              title: `<h5 style="font-size:1.125rem">${t('dialog.delete.cannotDeleteData')}</h5>`,
              html: `<p style="font-size:0.75rem">${message}</p>`,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            })
          }
        })
      }
    })
  }

  return useMaterialReactTable({
    enableBottomToolbar: true,
    enableColumnOrdering: true,
    enableDensityToggle: false,
    enableExpandAll: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: true,
    enablePagination: true,
    enableTableFooter: false,
    enableTopToolbar: true,
    initialState: { density: 'compact' },
    localization: i18n.language === 'id' ? (
      MRT_Localization_ID
    ) : MRT_Localization_EN,
    muiTablePaperProps: {
      elevation: 0,
    },
    columns: [
      {
        accessorKey: 'name',
        header: t('setting.revenueAndProduction.label'),
        size: 100,
        Cell: ({ row, cell }) => {
          if (row.original.id === data.product_default_id) {
            return (
              <>
                {cell.getValue()} <span className="badge text-bg-primary">
                  {t('setting.revenueAndProduction.form.default')}
                </span>
              </>
            )
          } else {
            return cell.getValue()
          }
        },
      },
      {
        accessorKey: 'unit',
        header: t('setting.revenueAndProduction.unit'),
        size: 100,
        Cell: ({ cell }) => cell.getValue(),
      },
      {
        accessorKey: 'id',
        header: t('emission.table.label6'),
        size: 100,
        enableSorting: false,
        enableColumnFilter: false,
        enableGrouping: false,
        Cell: ({ cell }) => <div className="d-flex flex-wrap gap-2">
          <Link
            to={`${ROUTE_PATHS.settingRevenue}/update/${cell.getValue()}`}
            className="btn btn-info btn-icon waves-effect waves-light"
          >
            <i className="ri-pencil-fill"></i>
          </Link>
          <button
            onClick={() => onDelete(cell.getValue())}
            type="button"
            className="btn btn-warning btn-icon waves-effect waves-light"
          >
            <i className="ri-delete-bin-2-fill"></i>
          </button>
        </div>,
      },
    ],
    data: Array.isArray(data.products) ? data.products : [],
    state: {
      isLoading: isLoading || isRefetching || isPending,
    }
  })
}
export default function SettingRevenueAndProductionList() {
  const { t } = useTranslation()
  const table = useTable()
  return (
    <div className='page-content'>
      <div className='container-fluid'>

        <div className='row'>
          <div className='col-12'>
            <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
              <h4 className='mb-sm-0'>
                {t('menu.label22')}
              </h4>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-header'>
                <h5 className='card-title mb-0'>
                  {t('general.read')}
                </h5>
                <br /><br />
                <Link
                  to={ROUTE_PATHS.settingRevenueCreate}
                  className='btn btn-success'
                >
                  {t('general.btnCreate')}
                </Link>
              </div >
              <div className='card-body'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MaterialReactTable table={table} />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}