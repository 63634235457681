import axios from "axios"
import { useEffect, useState } from "react"

function Attachment(props) {
  const { id } = props
  const [attachment, setAttachment] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (id) {
      let url

      (async () => {
        try {
          setIsLoading(true)
          const response = await axios({
            withCredentials: true,
            responseType: 'blob',
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_SERVER}/attachment-lookup`,
            params: { id }
          })
          if (response.status === 200) {
            url = window.URL.createObjectURL(response.data)
            setAttachment({
              type: response.headers['content-type'],
              name: response.headers['x-amz-meta-truclimate-original-name'] || id,
              blob: response.data,
              url: url
            })
          }
        } catch (error) {
          console.log(error)
        } finally {
          setIsLoading(false)
        }
      })()

      return () => url && window.URL.revokeObjectURL(url)
    } else {
      setIsLoading(false)
    }
  }, [id])

  return props.children({ ...props, attachment, isLoading })
}

export default Attachment