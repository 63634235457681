import styles from './Grid.module.css'

function Grid({
  children,
  itemCount,
}) {
  const className = itemCount === 3 ? (
    `${styles.grid} ${styles.gridThree}`
  ) : `${styles.grid} ${styles.gridFour}`

  return (
    <div className={className}>
      {children}
    </div>
  )
}

export default Grid
