import {
  useState,
  useRef,
  useEffect,
} from "react"
import {
  select,
  scaleBand,
  axisBottom as d3AxisBottom,
  axisLeft,
  scaleLinear,
  stack,
  max,
  min,
  union,
  index,
  scaleOrdinal,
} from "d3"

import styles from './StackedBarChart.module.css'
import { useTranslation } from "react-i18next"

import { CHART_COLORS } from '../../library/helper'

const EMISSION_VS_REDUCTION_COLORS = [
  '#3eb6ae',
  '#4c638c',
]

const nearestPowerOfTen = (number) => {
  let power = 10;
  while (power <= number) {
    power *= 10;
  }
  return power / 10;
}

const roundDomain = (number, divider) => {
  let result = Math.ceil(number / divider) * divider;
  return result
}


export const D3StackedBarGraph = ({
  data,
  axisBottom,
  color,
  isScopeChart = false,
  tooltipRef,
  isReflectorChart,
}) => {
  const svgRef = useRef()
  const { i18n } = useTranslation()

  useEffect(() => {
    const drawSvg = () => {
      // Specify the chart’s dimensions.
      const width = 808
      const height = 150
      const marginTop = 10
      const marginRight = 5
      const marginBottom = 20
      let marginLeft = 50

      const svg = select(svgRef.current)
        .attr('width', '100%')
        .attr('height', height)
        .attr('viewBox', [0, 0, width, height])
        .attr('style', 'max-width: 100%; height: auto;');

      svg.selectAll('*').remove() // Reset children

      const unionByCategory = union(data.map(d => d.categoryId))
      const series = stack()
        .keys(unionByCategory) // distinct series keys, in input order
        .value(([, D], key) => D.get(key).total) // get value for each series key and stack
        (index(data, d => d.axisBottom, d => d.categoryId));

      const minAxisLeft = min(data.map(d => d.total))
      const maxAxisLeft = max(series, d => max(d, item => item[1]))

      let domainStart = 0
      if (minAxisLeft < 0) {
        if (minAxisLeft * - 1 < maxAxisLeft) {
          domainStart = maxAxisLeft * - 1
        } else {
          const divider = nearestPowerOfTen(Math.abs(minAxisLeft))
          domainStart = roundDomain(Math.abs(minAxisLeft), divider) * - 1
        }
      }

      const y = scaleLinear()
        .domain([domainStart, maxAxisLeft])
        .rangeRound([height - marginBottom, marginTop])

      const formatValue = xValue => (isNaN(xValue) ? 'N/A' : xValue.toLocaleString(i18n.language === 'id' ? 'id' : 'en'));

      const yTickValueArray = []
      const yTickFormat = (tickValue) => {
        yTickValueArray.push(Math.abs(tickValue))
        return formatValue(tickValue)
      }

      const gWrapperClass = 'trucount-g-wrapper'
      svg
        .append('g')
        .attr("class", `y-axis ${gWrapperClass}`)
        .call(axisLeft(y)
          .ticks(3)
          .tickFormat(yTickFormat)
          .tickSizeInner([-width - 20])
        )
        .call(g => g.select('.domain').remove());

      let maxYTickValueNumber = max(yTickValueArray)
      if (maxYTickValueNumber !== undefined) {
        if (maxYTickValueNumber === 1) {
          maxYTickValueNumber = yTickValueArray[yTickValueArray.length - 2]
        }
        const characterCount = maxYTickValueNumber
          .toLocaleString('en')
          .split('')
          .length

        const oneCharWidth = 5.6 // The width of 1 character of a tick value
        const axisLeftLabelWidth = 25
        marginLeft = (characterCount * oneCharWidth) + axisLeftLabelWidth
      }

      svg
        .select(`.${gWrapperClass}`)
        .attr('transform', `translate(${marginLeft},0)`)

      // / Prepare the scales for positional and color encodings.
      const x = scaleBand()
        .domain(axisBottom)
        .range([marginLeft, width - marginRight])
        .padding(0.3);

      // Create tooltip
      const tooltip = select(tooltipRef.current)
        .style('position', 'absolute')
        .style('padding', '5px')
        .style('border-radius', '5px')
        .style('visibility', 'hidden')

      const mouseover = function (event, d) {
        const name = d.data[0]
        let subGroupName
        if (isScopeChart) {
          subGroupName = `Scope ${d.key}`
        } else {
          subGroupName = d.data[1].get(d.key)[i18n.language === 'id' ? 'categoryNameId' : 'categoryName']
        }
        if (!subGroupName) {
          subGroupName = d.data[1].get(d.key).categoryName
        }
        const value = formatValue(d.data[1].get(d.key).total)
        tooltip.transition().duration(200).style('visibility', 'visible')
        tooltip
          .html(`<strong>${name}</strong><br>${subGroupName}: <strong>${value} tCO\u2082e</strong>`)
          .style('background', '#f4f4f4')
          .style('border', `1px solid ${color(d.key)}`)

      }
      const mouseleave = function () {
        // Hide tooltip on mouseout
        const tooltipDiv = tooltipRef.current
        if (tooltipDiv) {
          select(tooltipDiv).transition().duration(500).style("visibility", 'hidden')
        }
      }

      const tickValues = x
        .domain() // This is what is written on the x axis. e.g., from 2019 to 2030 or from Jan to Dec
        .filter((_, i, array) => {
          if (array.length <= 12) {
            return true
          } else {
            // Hide some ticks to prevent them from piling up on each other
            const step = Math.floor(array.length / 10) // Adjust this number as needed
            return i % step === 0
          }
        })

      svg
        .append('g')
        .attr('transform', `translate(0,${height - marginBottom})`)
        .attr("class", "x-axis")
        .call(d3AxisBottom(x).tickValues(tickValues).tickSizeOuter(0))
        .call(g => g.select('.domain').remove());

      // Add axis left label
      svg.append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0)
        .attr("x", ((height - marginTop) / - 2) + 6)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .text('tCO\u2082e')

      svg.append("g")
        .selectAll()
        .data(series)
        .join("g")
        .attr("fill", d => {
          return color(d.key)
        })
        .selectAll("rect")
        .data(D => D.map(d => (d.key = D.key, d)))
        .join("rect")
        .attr("x", d => x(d.data[0]))
        .attr("y", d => {
          // Make sure 2 is always the category id of the reduction bar and
          // reflector chart only use in the actual emission vs reduction chart / scenario page
          if (isReflectorChart && d.key === 2) {
            return y(0)
          } else {
            return y(d[1])
          }
        })
        .attr("height", d => {
          return Math.abs(y(d[0]) - y(d[1]))
        })
        .attr("width", x.bandwidth())
        .on("mouseover", mouseover)
        .on("mouseleave", mouseleave)

      svg.selectAll(".y-axis").selectAll("path").remove()
      svg.selectAll(".x-axis").selectAll("path").remove()
      svg.selectAll(".x-axis").selectAll("g").selectAll("line").remove()

      svg.select(".y-axis")
        .selectAll(".tick")
        .filter(function () {
          return select(this).text() !== yTickFormat(0)
        })
        .select('line')
        .attr('stroke', '#ebebeb')

      if (minAxisLeft >= 0) {
        // Make sure 0 tCO2e line to always stay at the bottom
        // and not going to the middle position when all data is 0
        svg
          .selectAll(".y-axis>g:first-child")
          .attr("transform", `translate(0, ${height - marginBottom})`)
      }
    }

    if (
      Array.isArray(data) &&
      data.length > 0 &&
      Array.isArray(axisBottom) &&
      axisBottom.length > 0
    ) {
      drawSvg()
    }

  }, [data, axisBottom, color, i18n.language, isScopeChart, isReflectorChart, tooltipRef])

  return (
    (
      Array.isArray(data) &&
      data.length > 0 &&
      Array.isArray(axisBottom) &&
      axisBottom.length > 0
    ) ? <svg ref={svgRef}></svg> : <></>
  )
}

const LegendItem = ({
  color,
  isActive,
  label,
  id,
  initialKeys,
  setActiveKeys,
}) => {
  const onClickCategoryId = () => {
    if (isActive) {
      setActiveKeys(previous => {
        return previous.filter(prev => prev.categoryId !== id)
      })
    } else {
      setActiveKeys(previous => {
        return [
          ...previous,
          initialKeys.find(initKey => initKey.categoryId === id)
        ]
      })
    }

  }
  return (
    <div
      style={{
        display: 'flex',
        fontSize: '11px',
      }}
    >
      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
        }}
        className={styles.legendItem}
        onClick={onClickCategoryId}
      >

        {isActive ? (
          <i
            className='ri-checkbox-blank-circle-fill'
            style={{
              color,
              marginRight: '0.5rem',
            }}
          >
          </i>
        ) : (
          <i
            className='ri-checkbox-blank-circle-line'
            style={{
              color,
              marginRight: '0.5rem',
            }}
          >
          </i>
        )}
        <span
          style={{
            marginRight: '0.5rem',
          }}
          className={isActive ? '' : 'text-muted'}
        >
          {label}
        </span>
      </div>
    </div>
  )
}

const LegendTitle = ({
  filterLabel,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        fontSize: '11px',
      }}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <i
          className='ri-filter-3-fill'
          style={{
            marginRight: '0.5rem',
          }}
        >
        </i>
        <span
          style={{
            marginRight: '0.5rem',
            fontsize: '11px'
          }}
        >
          {filterLabel}
        </span>
      </div>
    </div>
  )
}

const D3StackedBarChart = ({
  axisBottom,
  allKeys: initialKeys, // initial legend items
  data: initialData,
  filterLabel,
  isReflectorChart = false,
  isScopeChart = false,
}) => {
  const { i18n } = useTranslation()
  const tooltipRef = useRef()
  const [legendKeys, setLegendKeys] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [activeKeys, setActiveKeys] = useState([])
  const [color, setColor] = useState()

  useEffect(() => {
    if (Array.isArray(activeKeys) && Array.isArray(initialData)) {
      setFilteredData(initialData.filter(initData => {
        return activeKeys.some(activeKey => activeKey.categoryId === initData.categoryId)
      }))
    }
  }, [activeKeys, initialData])

  useEffect(() => {
    if (Array.isArray(initialData) && Array.isArray(initialKeys)) {
      const unionByCategory = union(initialData.map(d => d.categoryId))
      const series = stack()
        .keys(unionByCategory) // distinct series keys, in input order
        .value(([, D], key) => D.get(key).total) // get value for each series key and stack
        (index(initialData, d => d.axisBottom, d => d.categoryId))

      function compareNumbers(a, b) {
        return a - b
      }
      const sortedKeys = series.map(s => s.key).sort(compareNumbers)
      const colorRange = isReflectorChart ? EMISSION_VS_REDUCTION_COLORS : CHART_COLORS
      const colorD3 = scaleOrdinal(colorRange)
        .domain(sortedKeys)
        .unknown("#ccc");

      setLegendKeys(initialKeys.map(k => {
        return {
          ...k,
          color: colorD3(k.categoryId)
        }
      }))
      setColor(() => colorD3)
      setActiveKeys(initialKeys)
    }
  }, [initialData, initialKeys])

  return (
    <div
      style={{
        display: 'flex',
        fontSize: '11px',
        textAlign: 'left',
        gap: '0.75rem',
      }}
    >
      <div
        style={{
          flex: 0.8,
        }}
      >
        <div>
          <div ref={tooltipRef} />
          <D3StackedBarGraph
            data={filteredData}
            tooltipRef={tooltipRef}
            axisBottom={axisBottom}
            color={color}
            isReflectorChart={isReflectorChart}
            isScopeChart={isScopeChart}
          />
        </div>
      </div>
      <div
        style={{
          flex: 0.2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <LegendTitle
          filterLabel={filterLabel}
        />
        {legendKeys.map((key) => (
          <LegendItem
            key={key.categoryId}
            id={key.categoryId}
            label={i18n.language === 'id' ? (key.categoryNameId || key.categoryName) : key.categoryName}
            color={key.color}
            isActive={activeKeys.some(activeKey => activeKey.categoryId === key.categoryId)}
            setActiveKeys={setActiveKeys}
            initialKeys={initialKeys}
          />
        ))}
      </div>
    </div>
  )
}

export default D3StackedBarChart
