import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import MultilineChartCard from '../../MultilineChartCard'
import MonthlyEmission from '../MonthlyEmission'
import YearlyEmission from '../YearlyEmission'

import {
    CHART_COLORS,
    twoDecimalFormat,
} from '../../../library/helper'
import { useState } from 'react'

function MiddleSection({
    companyOptions,
    onDownloadImage,
    selectedYear,
}) {
    const { t, i18n } = useTranslation()
    const account = useSelector((state) => state.account.value)

    const [selectedValueCompany, setSelectedValueCompany] = useState("");
    const [selectedValueDivision, setSelectedValueDivision] = useState("");

    const optionsByCategory = {
        chart: {
            id: 'chartByCategory',
            type: 'polarArea',
            height: 400,
            offsetX: 0,
            offsetY: 0,
            width: 200
        },
        colors: CHART_COLORS,
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false
        },
        stroke: {
            colors: ['#fff']
        },
        fill: {
            opacity: 0.8
        },
        yaxis: {
            show: false,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return twoDecimalFormat(val, i18n.language) + " tCO₂e"
                },
            },
        }
    };

    const optionsByCompany = {
        chart: {
            id: 'chartByCompany',
            events: {
                dataPointSelection: function (_e, _chart, opts) {
                    setSelectedValueCompany(opts?.w?.globals?.seriesTotals[opts?.dataPointIndex]);
                },
            },
            type: 'donut',
        },
        colors: CHART_COLORS,
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
                offsetY: 10
            }
        },
        stroke: {
            colors: "#FFFFFF",
            show: true,
        },
        grid: {
            padding: {
                bottom: -130
            }
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return twoDecimalFormat(val, i18n.language) + " tCO₂e"
                },
            },

        }
    };

    const optionsBusinessDivision = {
        chart: {
            id: 'chartBusinessDivision',
            events: {
                dataPointSelection: function (_e, _chart, opts) {
                    setSelectedValueDivision(opts?.w?.globals?.seriesTotals[opts?.dataPointIndex]);
                },
            },
            type: 'donut',
        },
        colors: CHART_COLORS,
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
                offsetY: 10,
            }
        },
        stroke: {
            colors: "#FFFFFF",
            show: true,
        },
        grid: {
            padding: {
                bottom: -130
            }
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return twoDecimalFormat(val, i18n.language) + " tCO₂e"
                },
            },
        }
    };

    const optionsTotalEmission = {
        chart: {
            id: 'chartTotalEmission',
            type: 'bar',
            height: 350,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: i18n.language === 'id'
                ? ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agt', 'Sep', 'Okt', 'Nov', 'Des']
                : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
            title: {
                text: 'tCO₂e'
            },
            labels: {
                formatter: function (value) {
                    return twoDecimalFormat(value, i18n.language)
                },
            },
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return twoDecimalFormat(val, i18n.language) + " tCO₂e"
                }
            }
        }
    };


    const onDownloadMonthlyCompany = () => {
        if (
            Array.isArray(companyOptions) &&
            companyOptions[0].companyId
        ) {
            onDownloadImage(
                `monthlyCompany${companyOptions[0].companyId}`,
                `${companyOptions[0].companyId} monthly-emission-by-company`)
        }
    }

    let emissionVsTargetWrapperClass

    if (account['client_subscription'] > 1) {
        if (Array.isArray(companyOptions) && companyOptions.length > 1) {
            emissionVsTargetWrapperClass = 'col-9'
        } else {
            emissionVsTargetWrapperClass = 'col-8'
        }
    }

    return (
        <>
            <div className='align-items-stretch d-flex gap-3 w-100'>
                <div className='d-flex flex-grow-1'>
                    <YearlyEmission
                        chartHeight={200}
                        chartType="polarArea"
                        chartId='yearlyCategory'
                        onDownloadImage={onDownloadImage}
                        pdfLable={t('dashboard.card.title5')}
                        options={optionsByCategory}
                        selectedYear={selectedYear}
                        title={t('dashboard.card.title5')}
                        apiRoute='/record-by-category-yearly-summaries-lookup'
                    />
                </div>
                {
                    account['client_subscription'] > 1 && (
                        <div className={emissionVsTargetWrapperClass}>
                            <MultilineChartCard
                                chartId='emissionVsTarget'
                                onDownloadImage={onDownloadImage}
                                startYear={selectedYear ? selectedYear - 1 : null}
                                endYear={selectedYear ? selectedYear + 1 : null}
                                onDashboard={true}
                            />
                        </div>
                    )
                }
            </div>

            {
                Array.isArray(companyOptions) && companyOptions.length > 1 && (
                    <div
                        className='align-items-stretch d-grid flex-wrap gap-3 position-relative'
                        style={{ gridTemplateColumns: '1fr 1fr' }}
                    >
                        <YearlyEmission
                            chartHeight={200}
                            chartType="donut"
                            chartId='yearlyCompany'
                            onDownloadImage={onDownloadImage}
                            pdfLable={t('dashboard.card.title6')}
                            options={optionsByCompany}
                            selectedValue={selectedValueCompany}
                            selectedYear={selectedYear}
                            title={t('dashboard.card.title6')}
                            apiRoute='/record-by-company-yearly-summaries-lookup'
                        />
                        <YearlyEmission
                            chartHeight={200}
                            chartType="donut"
                            chartId='yearlyDivision'
                            onDownloadImage={onDownloadImage}
                            pdfLable={t('dashboard.card.title7')}
                            options={optionsBusinessDivision}
                            selectedValue={selectedValueDivision}
                            selectedYear={selectedYear}
                            title={t('dashboard.card.title7')}
                            apiRoute='/record-by-division-yearly-summaries-lookup'
                        />
                    </div>
                )
            }

            <MonthlyEmission
                chartHeight={350}
                chartType="bar"
                apiRoute='/record-by-company-monthly-summaries-lookup'
                chartId={`monthlyCompany${companyOptions?.[0]?.companyId}`}
                filterLabel={t('dashboard.chart.stackedbar.filterByCompany')}
                options={optionsTotalEmission}
                onDownload={onDownloadMonthlyCompany}
                selectedYear={selectedYear}
                title={t('dashboard.card.title8')}
            />
        </>
    )
}

export default MiddleSection