import styles from './BoxItem.module.css'

function BoxItem({
  borderColor,
  label,
  value,
}) {
  return (
    <div
      className={styles.card}
      style={{
        borderBottom: `4px solid ${borderColor}`,
        flex: '1 1 0px',
      }}
    >
      <div
        className={styles.cardBody}
        style={{ minHeight: 'auto' }}
      >
        <p
          className='text-muted mb-0'
          style={{
            fontSize: 10,
            minHeight: '1.8rem', // Maintain the same height across different cards
          }}
        >
          {label}
        </p>
        <div className='align-items-center d-flex gap-3 mt-2'>
          {value}
        </div>
      </div>
    </div>
  )
}

export default BoxItem