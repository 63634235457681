import { useState } from 'react'
import ApexCharts from 'react-apexcharts'
import {
  EMPTY_CHART_COLORS,
} from '../../library/helper'

export default function EmptyStackedBarChart() {

  const [series] = useState([
    {
      "name": "Actual Emission",
      "nameId": "Emisi Aktual",
      "data": [
        50,
        40,
        36,
        32,
        29,
        25,
        20,
        17,
        14,
        10,
        8,
        4,
      ]
    },
    {
      "name": "Reduction",
      "nameId": "Penurunan",
      "data": [
        -8,
        -10,
        -11,
        -13,
        -12.5,
        -14,
        -19,
        -24,
        -29,
        -38,
        -44,
        -59,
      ]
    },
  ])

  const emptyOptions = {
    chart: {
      id: 'chartStackedBarEmpty',
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enable: false,
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    colors: EMPTY_CHART_COLORS,
    xaxis: {
      type: 'year',
      categories: [
        2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030
      ],
      labels: {
        show: false,
      },
      tickPlacement: 'on',
    },
    yaxis: {
      title: {
        text: 'tCO₂e',
      },
      tickAmount: 4,
    },
    tooltip: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
  }

  return (
    <ApexCharts
      options={emptyOptions}
      series={series}
      type="bar"
      height={465}
    />
  );
}
