import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component'

import {
  resetHeaderLayout,
} from '../library/helper'

import InputNumeric from '../components/InputNumeric'

const OnboardFreeTier = ({
  user,
  setUser,
}) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const [companyName, setCompanyName] = useState(user?.clientName || '')
  const [selectedIndustry, setSelectedIndustry] = useState(0)
  const [industryList, setIndustryList] = useState()
  const [annualSalesRevenue, setAnnualSalesRevenue] = useState(0)
  const [numberOfEmployee, setNumberOfEmployee] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [switchedToEmissionFactorList, setSwitchedToEmissionFactorList] = useState(false)
  const [responseClientRegistration, setResponseClientRegistration] = useState({})
  const [showSaveConfirmDialog, setShowSaveConfirmDialog] = useState(true)
  const [filterText, setFilterText] = useState('')
  const [filteredEmissionFactorList, setFilteredEmissionFactorList] = useState([])
  const [phoneNumber, setPhoneNumber] = useState('')

  useEffect(() => {
    (async function () {
      const optionsClientIndustry = {
        withCredentials: true,
        method: 'GET',
        url: `${process.env.REACT_APP_BASE_SERVER}/client/industry`
      }
      const responseClientIndustry = await axios(optionsClientIndustry)
      if (responseClientIndustry.data.success) {
        setIndustryList(responseClientIndustry.data.data)
      }
    })()
  }, [])

  const onChangeCompanyName = (event) => {
    setCompanyName(event.target.value)
  }

  const onSelectIndustry = async (event) => {
    setSelectedIndustry(event.target.value)
  }

  const onSelectAnnualSalesRevenue = (event) => {
    setAnnualSalesRevenue(event.target.value)
  }

  const onChangeNumberOfEmployee = (values, sourceInfo) => {
    if (sourceInfo?.event?.target?.name && values) {
      setNumberOfEmployee(values.value)
    }
  }

  const goToDashboard = () => {
    resetHeaderLayout()
    setUser(prev => ({
      ...prev,
      clientId: responseClientRegistration.clientId,
      clientOnboard: responseClientRegistration.clientOnboard,
    }))
    setSwitchedToEmissionFactorList(false)
    navigate("/dashboard")
  }

  const onSubmit = async (event) => {
    try {
      event.preventDefault()
      if (isLoading) {
        return
      } else if (switchedToEmissionFactorList) {
        // Go to dashboard
        goToDashboard()
      } else if (!companyName) {
        throw new Error(t('onboard.error.orgNameIsMissing'))
      } else if (!selectedIndustry) {
        throw new Error(t('onboard.error.industryNameIsMissing'))
      } else if (!annualSalesRevenue) {
        throw new Error(t('onboard.error.revenueIsMissing'))
      } else if (!numberOfEmployee) {
        throw new Error(t('onboard.error.employeeNumberIsMissing'))
      } else if (showSaveConfirmDialog) {
        const { isConfirmed: isInputConfirmed } = await Swal.fire({
          title: `<h5 style="font-size:1.125rem">${t('onboard.swal.confirm')}</h5>`,
          html: `<p style="font-size:0.75rem">${t('onboard.swal.confirmAccuracy')}</p>`,
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: t('onboard.swal.noReviewAgain'),
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: t('onboard.swal.yesConfirmIt'),
        })
        if (isInputConfirmed) {
          setIsLoading(true)
          const data = {
            user,
            companyName,
            industry: selectedIndustry,
            annualSalesRevenue,
            numberOfEmployee,
          }
          const options = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            data,
            url: `${process.env.REACT_APP_BASE_SERVER}/user-client-free-tier-registration`
          }
          const responseClientIndustry = await axios(options)
          if (responseClientIndustry.data.ok) {
            const { isConfirmed: isSeeDetailsConfirmed } = await Swal.fire({
              showCancelButton: false,
              confirmButtonText: user && user.clientSubs === 3 ? t('onboard.swal.seeDetails') : t('onboard.swal.goToDashboard'),
              title: `<h5 style="font-size:1.125rem">${t('onboard.swal.congratulation')}</h5>`,
              html: `<p style="font-size:0.75rem">${t('onboard.swal.congratSubtitle')}</p>`,
              icon: 'success',
              confirmButtonColor: '#4c638c',
              allowOutsideClick: false,
            })
            if (isSeeDetailsConfirmed) {
              setShowSaveConfirmDialog(false)
              setResponseClientRegistration(responseClientIndustry.data.data)
              if (user && user.clientSubs === 3) {
                // Stay on the page and show the EF list
                setSwitchedToEmissionFactorList(true)
                setFilteredEmissionFactorList(responseClientIndustry.data.data.emissionSources)
              } else {
                resetHeaderLayout()
                setUser(prev => ({
                  ...prev,
                  clientId: responseClientIndustry.data.data.clientId,
                  clientOnboard: responseClientIndustry.data.data.clientOnboard,
                }))
                setSwitchedToEmissionFactorList(false)
                navigate("/dashboard")
              }
            }
          }
        }
      }
    } catch (e) {
      let message = t('onboard.error.pleaseFill')
      if (typeof e.response?.data?.message === 'string') {
        message = e.response.data.message
      } else if (e.message) {
        message = e.message
      }
      Swal.fire({
        icon: 'error',
        title: '',
        html: `<p style="font-size:0.75rem">${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleRequestEmissionFactors = async () => {
    try {
      if (!phoneNumber) {
        throw new Error(t('onboard.form.error.phoneNumberIsMissing'))
      }
      const response = await axios({
        withCredentials: true,
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        data: { phoneNumber },
        url: `${process.env.REACT_APP_BASE_SERVER}/emission-factor-request-submission`
      })
      if (response.status != 200) {
        const response_ = await response.json();
        const message = response_?.message ?? ''
        Swal.fire({
          icon: 'error',
          title: `<h5 style="font-size:1.125rem">${t('onboard.swal.accessRequestFailed')}</h5>`,
          html: `<p style="font-size:0.75rem">${message}</p>`,
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
      } else {
        Swal.fire({
          showCancelButton: false,
          confirmButtonText: 'Close',
          title: `<h5 style="font-size:1.125rem">${t('onboard.swal.thankYou')}</h5>`,
          html: `<p style="font-size:0.75rem">${t('onboard.swal.thankYouSubtitle')}</p>`,
          icon: 'success',
          confirmButtonColor: '#4c638c',
        })
      }
    } catch (error) {
      let message = error?.response?.data?.message ?? ''
      if (!phoneNumber) {
        message = error.message
      } else if (error?.response?.data?.skipMail) {
        message = t('onboard.swal.eFAlreadyRequested')
      }
      let swalReqEFErrorTitle
      if (error?.response?.data?.skipMail) {
        swalReqEFErrorTitle = `<h5 style="font-size:1.125rem">${t('onboard.swal.thankYou')}</h5>`
      } else {
        swalReqEFErrorTitle = `<h5 style="font-size:1.125rem">${t('onboard.swal.accessRequestFailed')}</h5>`
      }
      Swal.fire({
        icon: error?.response?.data?.skipMail ? 'info' : 'error',
        title: swalReqEFErrorTitle,
        html: `<p style="font-size:0.75rem">${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      })
    }
  }

  const onFilter = (e) => {
    setFilterText(e.target.value)
    const hasList = (Array.isArray(responseClientRegistration.emissionSources) &&
      responseClientRegistration.emissionSources.length > 0)
    if (hasList && e.target.value) {
      const filteredItems = responseClientRegistration.emissionSources.filter(
        item => {
          return (item.categoryName && item.categoryName.toLowerCase().includes(e.target.value.toLowerCase())) ||
            (item.categoryNameId && item.categoryNameId.toLowerCase().includes(e.target.value.toLowerCase())) ||
            (item.emissionName && item.emissionName.toLowerCase().includes(e.target.value.toLowerCase()))
        }
      )
      setFilteredEmissionFactorList(filteredItems)
    } else if (hasList || !e.target.value) {
      setFilteredEmissionFactorList(responseClientRegistration.emissionSources)
    } else if (!hasList) {
      setFilteredEmissionFactorList([])
    }
  }

  const optionIndustry = [
    selectedIndustry === 0 ? (
      <option key={0} value={0}>
        {t('onboard.form.selectIndustry')}
      </option>
    ) : null,
    ...(industryList || []).map((item, index) => (
      <option key={index + 1} value={item.industry_id}>
        {item.industry_name}
      </option>
    )),
  ]

  return (
    <div className="page-content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              {switchedToEmissionFactorList
                ? <h4 className="mb-sm-0">{t('onboard.title.summary')}</h4>
                : <h4 className="mb-sm-0">{t('onboard.title.onboard')}</h4>
              }
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row gy-5">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-bill-info"
                    role="tabpanel"
                    aria-labelledby="v-pills-bill-info-tab"
                  >
                    <form onSubmit={onSubmit}>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                        <div style={{ marginRight: '0.5rem' }}>
                          {switchedToEmissionFactorList
                            ? <h5>{t('onboard.summaryEFDetail')}</h5>
                            : <h5>{t('onboard.organizationInfo')}</h5>
                          }
                          <p className="text-muted col-lg-8">
                            {switchedToEmissionFactorList
                              ? t('onboard.summaryEFDetailDescription')
                              : t('onboard.organizationInfoDescription')
                            }
                          </p>


                        </div>
                        <div>
                          <button
                            type="submit"
                            aria-disabled={isLoading}
                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                            data-nexttab="v-pills-bill-address-tab"
                          >
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            {switchedToEmissionFactorList
                              ? t('onboard.form.goToDashboard')
                              : user && user.clientSubs === 3 ? t('onboard.form.save') : t('onboard.form.goToDashboard')}
                          </button>
                        </div>
                      </div>

                      {switchedToEmissionFactorList
                        ? (
                          <>
                            <div className="col-lg-4" style={{ float: "right" }}>
                              <div className="input-group">
                                <span className="input-group-text" id="search"><i className="ri-search-line"></i></span>
                                <input
                                  value={filterText}
                                  onChange={onFilter}
                                  type="text"
                                  className="form-control"
                                  placeholder={t('table.search')}
                                  aria-describedby="search"
                                />
                              </div>
                            </div>
                            <DataTable
                              columns={[
                                {
                                  name: t('onboard.summary.table.emissionSource'),
                                  selector: row => row.emissionName,
                                },
                                {
                                  name: t('onboard.summary.table.category'),
                                  selector: row => i18n.language === 'id' ? row.categoryNameId : row.categoryName,
                                },
                              ]}
                              data={filteredEmissionFactorList}
                              keyField='cf_id'
                              noDataComponent={<p className='px-5 py-5 mt-3'>{t('onboard.summary.table.empty')}</p>}
                              pagination
                              dense
                              paginationComponentOptions={{
                                rowsPerPageText: t('table.paginationResult'),
                                rangeSeparatorText: t('table.paginationResultPage'),
                              }}
                              paginationRowsPerPageOptions={[20, 30, 50]}
                              paginationPerPage={20}
                            />
                            <p className="text-muted mt-4">
                              {t('onboard.form.requestEmissionFactorsFirstLine')}
                              <br></br>
                              {t('onboard.form.requestEmissionFactorsSecondLine')}
                            </p>
                            <div className="row align-items-start">
                              <div className="col-md-4 col-sm-4"
                                style={{ minWidth: '20rem' }}
                              >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <div className="mb-3">
                                    <input
                                      className="form-control border border-primary border-1 rounded"
                                      placeholder={t('locked.content.phone.placeholder')}
                                      type="number"
                                      id='phone'
                                      name='phone'
                                      onChange={(event) => setPhoneNumber(event.target.value)}
                                    />
                                  </div>
                                  <button type="button"
                                    className="btn btn-primary fw-semibold fs-8 px-5"
                                    onClick={handleRequestEmissionFactors}
                                  >
                                    {t('onboard.form.requestEmissionFactors')}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                        : (
                          <div className="row g-3">
                            <div className="col-md-6">
                              <div>
                                <label htmlFor="organizationName" className="form-label">
                                  {t('onboard.form.organizationName')}
                                </label>
                                <input
                                  onChange={onChangeCompanyName}
                                  value={companyName}
                                  type="text"
                                  className="form-control"
                                  id="organizationName"
                                  placeholder={t('emission.form.enter')}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor="industry" className="form-label">
                                  {t('onboard.form.industry')}
                                </label>
                                <select
                                  onChange={onSelectIndustry}
                                  value={selectedIndustry}
                                  className="form-select mb-3"
                                  id="industry"
                                >
                                  {optionIndustry}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor="annualSalesRevenue" className="form-label">
                                  {t('onboard.form.annualSalesRevenue')}
                                </label>
                                <select
                                  onChange={onSelectAnnualSalesRevenue}
                                  value={annualSalesRevenue}
                                  className="form-select mb-3"
                                  id="annualSalesRevenue"
                                >
                                  {annualSalesRevenue > 0 ? "" : <option value="0">{t('onboard.form.selectAmountRevenue')}</option>}
                                  <option value="1">{t('onboard.form.lessThan$5M')}</option>
                                  <option value="2">{t('onboard.form.$5M-$100M')}</option>
                                  <option value="3">{t('onboard.form.$100M-$500M')}</option>
                                  <option value="4">{t('onboard.form.moreThan$500M')}</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div>
                                <label htmlFor="numberOfEmployee" className="form-label">
                                  {t('onboard.form.numberOfEmployees')}
                                </label>
                                <InputNumeric
                                  id="numberOfEmployee"
                                  name="numberOfEmployee"
                                  value={numberOfEmployee}
                                  isAllowed={(values) => {
                                    if (!values.value) return true
                                    const { floatValue } = values
                                    return floatValue > 0
                                  }}
                                  onValueChange={onChangeNumberOfEmployee}
                                  placeholder={t('emission.form.enter')}
                                />
                              </div>
                            </div>

                          </div>
                        )}
                    </form>
                  </div>
                </div>
              </div>
            </div >
          </div >
        </div >

      </div >
    </div >
  )
}

export default OnboardFreeTier
