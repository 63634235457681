import React, {
  useState,
  useEffect,
} from 'react'
import {
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import axios from "axios"
import DatePicker from "react-datepicker"
import "react-tooltip/dist/react-tooltip.css"
import Swal from 'sweetalert2'
import { NumericFormat } from 'react-number-format'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from "@tanstack/react-query"
import {
  ROUTE_PATHS,
} from '../../../library/helper'

import Attachment from '../../../components/Attachment'
import NotFound from '../../../components/NotFound'
import LoadingCard from '../../../components/LoadingCard'

const purchasedGoodsAndServicesPageUrl = ROUTE_PATHS.scope3category1Update?.split(':')?.[0]
const capitalGoodsPageUrl = ROUTE_PATHS.scope3category2Update?.split(':')?.[0]
const currenciesSupported = [
  'EUR',
  'IDR',
  'USD',
]

const useSubmission = () => {
  return useMutation({
    mutationKey: [
      'purchased-and-capital-goods-emission-record-submission',
    ],
    retry: false,
    mutationFn: async (data) => {
      return axios({
        url: [
          process.env.REACT_APP_BASE_SERVER,
          '/purchased-and-capital-goods-emission-record-submission',
        ].join(''),
        method: 'POST',
        withCredentials: true,
        data,
      })
    },
  })
}

const useLookup = ({
  uuid,
  scope3CategoryId,
}) => {
  return useQuery({
    queryKey: [
      'purchased-and-capital-goods-emission-record-lookup',
      uuid,
      scope3CategoryId,
    ],
    retry: false,
    enabled: !!uuid,
    queryFn: ({ signal }) => axios({
      url: [
        process.env.REACT_APP_BASE_SERVER,
        '/purchased-and-capital-goods-emission-record-lookup',
      ].join(''),
      method: 'GET',
      withCredentials: true,
      signal,
      params: {
        uuid,
        scope3CategoryId,
      },
    }).then(response => {
      return response?.data?.data ?? {}
    }),
  })
}

const AttachmentChildren = ({
  isLoading,
  attachmentData,
  setSelectedFile,
  onAttachFile,
  selectedFile,
  onChangeFile,
}) => {
  const { t } = useTranslation()
  const [attachment, setAttachment] = useState({})

  useEffect(() => {
    if (attachmentData.type) {
      setAttachment(attachmentData)
    } else {
      setAttachment({})
    }
  }, [attachmentData])

  useEffect(() => {
    if (attachment.blob) {
      const file = new File(
        [attachment.blob],
        attachment.name,
        { type: attachment.type },
      )
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(file)
      document.getElementById('formFile').files = dataTransfer.files
      setSelectedFile(file)
    }
  }, [attachment])

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '7rem auto',
        }}
      >
        <div
          style={{
            padding: '5px 0',
          }}
        >
          <button
            onClick={onAttachFile}
            type="button"
            className="btn btn-primary waves-effect waves-light"
            style={{
              width: '100%',
            }}
            disabled={isLoading}
          >
            {t('emission.form.label17')}
          </button>
        </div>
        <div
          style={{
            padding: '5px 8px 5px',
            alignSelf: 'center',
          }}
        >
          {isLoading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <span className="text-muted">
              {selectedFile?.name}
            </span>
          )
          }
        </div>
        <div
          style={{
            padding: '5px 0',
          }}
        >
          {attachment?.url ? (
            <a
              className={`btn btn-info ${isLoading ? 'btn-soft-dark pe-none' : ''}`}
              style={{
                cursor: 'pointer',
                width: '100%',
              }}
              href={attachment.url}
              target="_blank"
              rel="noreferrer"
              download={attachment.name}
            >
              {t('dashboard.btnDownload')}
            </a>
          ) : (
            <p>{t('emission.noAttachment')}</p>
          )}
        </div>
      </div>
      <input
        onChange={onChangeFile}
        style={{ display: "none" }}
        className="form-control"
        type="file"
        id="formFile"
        accept="image/*,.pdf,.doc"
      />

    </>
  )
}

export default function EmissionScope3Category1And2SpendBasedUpdate() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()

  let scope3CategoryId
  if (location.pathname.startsWith(purchasedGoodsAndServicesPageUrl)) {
    scope3CategoryId = 1
  } else if (location.pathname.startsWith(capitalGoodsPageUrl)) {
    scope3CategoryId = 2
  }

  const query = useLookup({
    uuid: id,
    scope3CategoryId,
  })

  const [isSpendBased, setIsSpendBased] = useState(1)

  const [companies, setCompanies] = useState([])
  const [selectedCompanyId, setSelectedCompanyId] = useState(0)

  const [locations, setLocations] = useState([])
  const [selectedLocationId, setSelectedLocationId] = useState(0)

  const [categories, setCategories] = useState([])
  const [selectedCategoryId, setSelectedCategoryId] = useState(0)

  const [factors, setFactors] = useState([])
  const [selectedFactorId, setSelectedFactorId] = useState(0)
  const [refLabel, setRefLabel] = useState('')
  const [refLink, setRefLink] = useState('')

  const [selectedPurchasedDate, setSelectedPurchasedDate] = useState('')

  const [dataSource, setDataSource] = useState('')
  const [itemName, setItemName] = useState('')

  const [quantity, setQuantity] = useState(0)

  const [unit, setUnit] = useState('')

  const [amount, setAmount] = useState(0)

  const [initialFactorUnits, setInitialFactorUnits] = useState([])
  const [currencies, setCurrencies] = useState([])
  const [selectedFactorUnitId, setSelectedFactorUnitId] = useState('')

  const [dataOwner, setDataOwner] = useState('')
  const [verifiedBy, setVerifiedBy] = useState('')
  const [remarks, setRemarks] = useState('')

  const [attachmentId, setAttachmentId] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)

  useEffect(() => {
    if (query.data) {
      const {
        record,
      } = query.data

      setIsSpendBased(record.is_spend_based)

      setCompanies(query.data.companies)
      setSelectedCompanyId(record.record_company)

      setLocations(query.data.locations)
      setSelectedLocationId(record.record_location)

      setCategories([query.data.emission_category])
      setSelectedCategoryId(query.data.emission_category?.category_id)

      setFactors(query.data.factors)
      setSelectedFactorId(record.record_factor)
      setRefLabel(record.emission_ref_label)
      setRefLink(record.emission_ref_link)

      const purchasesDatePicker = new Date(
        new Date(record.record_date).getFullYear(),
        new Date(record.record_date).getMonth(),
        new Date(record.record_date).getDate(),
      )
      setSelectedPurchasedDate(purchasesDatePicker || null)

      setDataSource(record.record_misc.data_source)
      setItemName(record.record_assets)
      setQuantity(record.record_misc.quantity)
      setUnit(record.record_misc.unit)

      setAmount(record.record_amount)

      setInitialFactorUnits(query.data.factorUnits)
      setCurrencies(query.data.factorUnits.filter(item => item.unit_factor === record.record_factor))
      setSelectedFactorUnitId(record.record_unit)

      setDataOwner(record.record_misc.data_owner || '')
      setVerifiedBy(record.record_misc.verified_by || '')
      setRemarks(record.record_misc.remarks || '')

      setAttachmentId(record.attachment_id)
    }
  }, [query.data])

  const {
    mutate,
    isPending,
  } = useSubmission()

  let pageTitle
  let navigateTo

  if (location.pathname.startsWith(purchasedGoodsAndServicesPageUrl)) {
    pageTitle = t("menu.scope3Category1Title")
    navigateTo = ROUTE_PATHS.scope3category1
  } else if (location.pathname.startsWith(capitalGoodsPageUrl)) {
    pageTitle = t("menu.scope3Category2Title")
    navigateTo = ROUTE_PATHS.scope3category2
  }

  const onSaveRecord = async (e) => {
    let data = new FormData()
    data.append('uuid', id)
    data.append('companyId', selectedCompanyId)
    data.append('locationId', selectedLocationId)
    data.append('categoryId', selectedCategoryId)
    data.append('factorId', selectedFactorId)
    data.append('purchasedDate', selectedPurchasedDate)
    data.append('dataSource', dataSource)
    data.append('itemName', itemName)
    data.append('quantity', quantity)
    data.append('unit', unit)
    data.append('amount', amount)
    data.append('factorUnitId', selectedFactorUnitId)
    data.append('dataOwner', dataOwner)
    data.append('verifiedBy', verifiedBy)
    if (selectedFile) data.append('attachment', selectedFile)
    data.append('scope3CategoryId', scope3CategoryId)
    data.append('isSpendBased', isSpendBased)

    mutate(data, {
      onSuccess: () => {
        Swal.fire({
          title: '',
          html: '<p>Successfully record emissions</p>',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(navigateTo)
          }
        })
      },
      onError: (error) => {
        let message
        if (typeof error.response?.data?.message === 'string') {
          message = error.response.data.message
        } else if (error.message) {
          message = error.message
        }
        Swal.fire({
          title: '',
          html: `<p>${message}</p>`,
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          icon: 'error',
        })
      }
    })
  }

  const onAttachFile = async () => {
    document.getElementById('formFile').click()
  }

  const onChangeFile = async (e) => {
    const files = e.target.files
    setSelectedFile(files?.[0])
  }

  const onSelect = async (e) => {
    try {
      const selectedValue = Number(e.target.value)
      if (e.target.name === 'companyId') {
        setSelectedCompanyId(selectedValue)
        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          params: {
            companyId: selectedValue,
          },
          url: `${process.env.REACT_APP_BASE_SERVER}/client-office-lookup`
        })
        if (response.data.ok) {
          setLocations(response.data.data)
          setSelectedLocationId(0)
        }
      } else if (e.target.name === 'locationId') {
        setSelectedLocationId(selectedValue)
      } else if (e.target.name === 'categoryId') {
        setSelectedCategoryId(selectedValue)
      } else if (e.target.name === 'factorId') {
        setSelectedFactorId(selectedValue)
        //  In case user is selecting direct method
        const findUnits = initialFactorUnits
          .filter(item => item.unit_factor === selectedValue)
        if (findUnits.length > 0) {
          const isSpendBaseMethod = findUnits
            .find(item => currenciesSupported.includes(item.unit_label))
          if (isSpendBaseMethod) {
            setIsSpendBased(1)
            setSelectedFactorUnitId(0)
            setCurrencies(findUnits)
          } else {
            setIsSpendBased(0)
          }
        }
      } else if (e.target.name === 'selectedFactorUnitId') {
        setSelectedFactorUnitId(e.target.value)
      }
    } catch (error) {
      let message
      if (typeof error.response?.data?.message === 'string') {
        message = error.response.data.message
      } else if (error.message) {
        message = error.message
      }
      Swal.fire({
        title: '',
        html: `<p>${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
        icon: 'error',
      })
    }
  }

  const onInputChange = (e) => {
    if (e.target.name === 'dataSource') {
      setDataSource(e.target.value)
    } else if (e.target.name === 'itemName') {
      setItemName(e.target.value)
    } else if (e.target.name === 'unit') {
      setUnit(e.target.value)
    } else if (e.target.name === 'dataOwner') {
      setDataOwner(e.target.value)
    } else if (e.target.name === 'verifiedBy') {
      setVerifiedBy(e.target.value)
    } else if (e.target.name === 'remarks') {
      setRemarks(e.target.value)
    }
  }

  const onInputNumericFromatChange = (values, sourceInfo) => {
    if (sourceInfo?.event?.target?.name && values) {
      if (sourceInfo.event.target.name === 'amount') {
        setAmount(values.value)
      } else if (sourceInfo.event.target.name === 'quantity') {
        setQuantity(values.value)
      }
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">
                {pageTitle}
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">

            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  {t('general.btnUpdate')}
                </h4>
              </div>

              {isPending || query.isFetching ? (
                <div className="card-body">
                  <LoadingCard />
                </div>
              ) : (
                <>
                  {
                    query.failureReason?.response?.status === 404 ? (
                      <div className="card-body">
                        <NotFound />
                      </div>
                    ) : (
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label
                                htmlFor="companyId"
                                className="form-label"
                              >
                                {t('emission.form.label1')} <span className="text-danger">*</span>
                              </label>
                              <select
                                id="companyId"
                                name="companyId"
                                onChange={onSelect}
                                value={selectedCompanyId}
                                className="form-select mb-3"
                              >
                                {selectedCompanyId === 0 && (
                                  <option
                                    key={0}
                                    value={0}
                                  >
                                    {t('emission.form.select')}
                                  </option>
                                )}
                                {companies.map(item => {
                                  return (
                                    <option
                                      key={item.company_id}
                                      value={item.company_id}
                                    >
                                      {item.company_name}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </div>
                          {selectedCompanyId > 0 && (
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="locationId"
                                  className="form-label"
                                >
                                  {t('emission.table.label2')} <span className="text-danger">*</span>
                                </label>
                                <select
                                  id="locationId"
                                  name="locationId"
                                  onChange={onSelect}
                                  value={selectedLocationId}
                                  className="form-select mb-3"
                                >
                                  {selectedLocationId === 0 && (
                                    <option
                                      key={0}
                                      value={0}
                                    >
                                      {t('emission.form.select')}
                                    </option>
                                  )}
                                  {locations.map(item => {
                                    return (
                                      <option
                                        key={item.office_id}
                                        value={item.office_id}
                                      >
                                        {item.office_name}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            {
                              isSpendBased ? (
                                <div className="mb-3">
                                  <label
                                    htmlFor="factorId"
                                    className="form-label"
                                  >
                                    {t('emission.form.truclimateCategory')} <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    id="factorId"
                                    name="factorId"
                                    onChange={onSelect}
                                    value={selectedFactorId}
                                    className="form-select mb-1"
                                  >
                                    {selectedFactorId === 0 && (
                                      <option
                                        key={0}
                                        value={0}
                                      >
                                        {t('emission.form.select')}
                                      </option>
                                    )}
                                    {factors.map(item => {
                                      return (
                                        <option
                                          key={item.emission_factor_id}
                                          value={item.emission_factor_id}
                                        >
                                          {item.emission_name}
                                        </option>
                                      )
                                    })}
                                  </select>
                                  {refLabel && refLink && (
                                    <p>
                                      &nbsp;{t('emission.form.label3')} : <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={refLink}
                                      >
                                        {refLabel}
                                      </a>
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div className="mb-3">
                                  <label
                                    htmlFor="categoryId"
                                    className="form-label"
                                  >
                                    {t('emission.form.label2')} <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    id="categoryId"
                                    name="categoryId"
                                    onChange={onSelect}
                                    value={selectedCategoryId}
                                    className="form-select mb-1"
                                  >
                                    {selectedCategoryId === 0 && (
                                      <option
                                        key={0}
                                        value={0}
                                      >
                                        {t('emission.form.select')}
                                      </option>
                                    )}
                                    {categories.map(item => {
                                      return (
                                        <option
                                          key={item.category_id}
                                          value={item.category_id}
                                        >
                                          {
                                            i18n.language === 'id'
                                              ? item.category_name_id
                                              : item.category_name
                                          }
                                        </option>
                                      )
                                    })}
                                  </select>
                                  <p>
                                    &nbsp;{t('emission.form.label3')} : <a target="_blank" rel="noreferrer" href="https://www.epa.gov/climateleadership/ghg-emission-factors-hub">EPA 2023</a>
                                  </p>
                                </div>
                              )
                            }

                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="PurchasedDate" className="form-label">
                                {t('emission.form.purchasedDate')}
                              </label>
                              <div>
                                <DatePicker
                                  id="purchasedDate"
                                  name="purchasedDate"
                                  selected={selectedPurchasedDate}
                                  onChange={(date) => setSelectedPurchasedDate(date)}
                                  dateFormat="dd MMMM yyyy"
                                  className="form-select mb-3"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {
                          isSpendBased ? (
                            <>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label htmlFor="dataSource" className="form-label">
                                      {t('emission.form.dataSource')} <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="dataSource"
                                      name="dataSource"
                                      onChange={onInputChange}
                                      value={dataSource}
                                      className="form-control"
                                      placeholder={t('emission.form.enter')}
                                    >
                                    </input>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label htmlFor="itemName" className="form-label">
                                      {t('emission.form.itemName')} <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="itemName"
                                      name="itemName"
                                      onChange={onInputChange}
                                      value={itemName}
                                      className="form-control"
                                      placeholder={t('emission.form.enter')}
                                    >
                                    </input>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="quantity"
                                      className="form-label"
                                    >
                                      {t('emission.form.quantity')} <span className="text-danger">*</span>
                                    </label>
                                    <NumericFormat
                                      id="quantity"
                                      name="quantity"
                                      className="form-control"
                                      value={quantity}
                                      onValueChange={onInputNumericFromatChange}
                                      decimalSeparator={i18n.language === 'id' ? ',' : '.'}
                                      thousandSeparator={i18n.language === 'id' ? '.' : ','}
                                      placeholder={t('emission.form.enter')}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label htmlFor="unit" className="form-label">
                                      {t('emission.form.unit')} <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="unit"
                                      name="unit"
                                      onChange={onInputChange}
                                      value={unit}
                                      className="form-control"
                                      placeholder={t('emission.form.enter')}
                                    >
                                    </input>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="amount"
                                      className="form-label"
                                    >
                                      {t('emission.form.amountSpent')} <span className="text-danger">*</span>
                                    </label>
                                    <NumericFormat
                                      id="amount"
                                      name="amount"
                                      className="form-control"
                                      value={amount}
                                      onValueChange={onInputNumericFromatChange}
                                      decimalSeparator={i18n.language === 'id' ? ',' : '.'}
                                      thousandSeparator={i18n.language === 'id' ? '.' : ','}
                                      placeholder={t('emission.form.enter')}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label htmlFor="selectedFactorUnitId" className="form-label">
                                      {t('emission.form.currency')} <span className="text-danger">*</span>
                                    </label>
                                    <select
                                      id="selectedFactorUnitId"
                                      name="selectedFactorUnitId"
                                      onChange={onSelect}
                                      value={selectedFactorUnitId}
                                      className="form-select mb-0"
                                    >
                                      {!selectedFactorUnitId && (
                                        <option value='' key=''>
                                          {t('emission.form.select')}
                                        </option>
                                      )}
                                      {
                                        currencies.length > 0 &&
                                        currencies.map(item => {
                                          return (
                                            <option
                                              key={item.unit_id}
                                              value={item.unit_id}
                                            >
                                              {item.unit_label}
                                            </option>
                                          )
                                        })
                                      }
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label htmlFor="dataOwner" className="form-label">
                                      {t('emission.form.dataOwner')}
                                    </label>
                                    <input
                                      type="text"
                                      id="dataOwner"
                                      name="dataOwner"
                                      onChange={onInputChange}
                                      value={dataOwner}
                                      className="form-control"
                                      placeholder={t('emission.form.enter')}
                                    >
                                    </input>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label htmlFor="verifiedBy" className="form-label">
                                      {t('emission.form.verifiedBy')}
                                    </label>
                                    <input
                                      type="text"
                                      id="verifiedBy"
                                      name="verifiedBy"
                                      onChange={onInputChange}
                                      value={verifiedBy}
                                      className="form-control"
                                      placeholder={t('emission.form.enter')}
                                    >
                                    </input>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label htmlFor="remarks" className="form-label">
                                      {t('emission.form.remarks')}
                                    </label>
                                    <input
                                      type="text"
                                      id="remarks"
                                      name="remarks"
                                      onChange={onInputChange}
                                      value={remarks}
                                      className="form-control"
                                      placeholder={t('emission.form.enter')}
                                    >
                                    </input>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label htmlFor="itemName" className="form-label">
                                    {t('emission.form.label7')} <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    id="itemName"
                                    name="itemName"
                                    onChange={onInputChange}
                                    value={itemName}
                                    className="form-control"
                                    placeholder={t('emission.form.enter')}
                                  >
                                  </input>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <label htmlFor="amount" className="form-label">
                                    {t('emission.form.emission')} (<span>tCO<sub>2</sub>e</span>) <span className="text-danger">*</span>
                                  </label>
                                  <NumericFormat
                                    id="amount"
                                    name='amount'
                                    className="form-control"
                                    value={amount}
                                    onValueChange={onInputNumericFromatChange}
                                    decimalSeparator={i18n.language === 'id' ? ',' : '.'}
                                    thousandSeparator={i18n.language === 'id' ? '.' : ','}
                                    placeholder={t('emission.form.enter')}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        }



                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">
                              {t('emission.form.label15')}
                            </label>
                            <p className="text-muted">
                              {t('emission.form.label16')}
                            </p>
                            <div className="col-md-6">
                              <Attachment id={attachmentId}>
                                {({ attachment, isLoading }) => {
                                  return <AttachmentChildren
                                    setSelectedFile={setSelectedFile}
                                    onAttachFile={onAttachFile}
                                    selectedFile={selectedFile}
                                    onChangeFile={onChangeFile}
                                    attachmentData={attachment}
                                    isLoading={isLoading}
                                  />
                                }}
                              </Attachment>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="text-end">
                            <button
                              onClick={onSaveRecord}
                              type="submit"
                              className="btn btn-primary"
                            >
                              {t('general.btnSave')}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                </>
              )}

            </div>
          </div>
        </div>
      </div >

    </div >
  )
}
