function TableHead(props) {
  const {
    children,
    ignoreHTML2Canvas,
    ...restProps
  } = props
  if (ignoreHTML2Canvas) {
    restProps['data-html2canvas-ignore'] = "false"
  }
  return (
    <thead
      {...restProps}
    >
      {children}
    </thead>
  )
}

export default TableHead
