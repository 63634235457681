import React, {
  useState,
  useEffect,
} from 'react'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios"
import "react-tooltip/dist/react-tooltip.css"
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import {
  ROUTE_PATHS,
} from '../library/helper'

import NotFound from '../components/NotFound'
import LoadingCard from '../components/LoadingCard'

const useClientProductSubmission = () => {
  return useMutation({
    mutationKey: [
      'client-product-submission',
    ],
    retry: false,
    mutationFn: async (data) => {
      return axios({
        url: [
          process.env.REACT_APP_BASE_SERVER,
          '/client-product-submission',
        ].join(''),
        method: 'POST',
        withCredentials: true,
        data,
      })
    },
  })
}

const useClientProductLookup = ({
  id,
}) => {
  return useQuery({
    queryKey: [
      'client-product-lookup',
      id,
    ],
    retry: false,
    enabled: !!id,
    queryFn: ({ signal }) => axios({
      url: [
        process.env.REACT_APP_BASE_SERVER,
        '/client-product-lookup',
      ].join(''),
      method: 'GET',
      withCredentials: true,
      signal,
      params: {
        id,
      },
    }).then(response => {
      return response?.data?.data ?? {}
    }),
  })
}

export default function SettingRevenueAndProductionForm() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { id } = useParams()

  const query = useClientProductLookup({
    id,
  })

  const [name, setName] = useState('');
  const [unit, setUnit] = useState('');
  const [isDefault, setIsDefault] = useState(false)

  useEffect(() => {
    if (query.data) {
      setName(query.data.product?.name || '')
      setUnit(query.data.product?.unit || '')
      setIsDefault(query.data.product_default_id === query.data.product?.id)
    }
  }, [query.data])

  const {
    mutate,
    isPending,
  } = useClientProductSubmission()

  const onSaveRecord = async (e) => {
    mutate({
      name,
      unit,
      isDefault,
      id,
    }, {
      onSuccess: () => {
        Swal.fire({
          title: '',
          html: '<p>Successfully record emissions</p>',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(ROUTE_PATHS.settingRevenue)
          }
        })
      },
      onError: (error) => {
        let message
        if (typeof error.response?.data?.message === 'string') {
          message = error.response.data.message
        } else if (error.message) {
          message = error.message
        }
        Swal.fire({
          title: '',
          html: `<p>${message}</p>`,
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          icon: 'error',
        })
      }
    })
  }

  const onInputChange = (e) => {
    if (e.target.name === 'name') {
      setName(e.target.value)
    } else if (e.target.name === 'unit') {
      setUnit(e.target.value)
    }
  }

  const onSetAsDefault = () => {
    setIsDefault(prev => !prev)
  }

  return (
    <div className="page-content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">
                {t("menu.label22")}
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">

            <div className="card">
              <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  {id ? t('general.btnUpdate') : t('general.btnCreate')}
                </h4>
              </div>

              {
                isPending || query.isFetching ? (
                  <div className="card-body">
                    <LoadingCard />
                  </div>
                ) : <>
                  {
                    query.failureReason?.response?.data?.status === 404 ? (
                      <div className="card-body">
                        <NotFound />
                      </div>
                    ) : (
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="name" className="form-label">
                                {t('setting.revenueAndProduction.label')} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                id="name"
                                name="name"
                                onChange={onInputChange}
                                value={name}
                                className="form-control"
                                placeholder={t('setting.revenueAndProduction.form.enter')}
                              >
                              </input>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="unit" className="form-label">
                                {t('setting.revenueAndProduction.unit')} <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                id="unit"
                                name="unit"
                                onChange={onInputChange}
                                value={unit}
                                className="form-control"
                                placeholder={t('setting.revenueAndProduction.form.enter')}
                              >
                              </input>
                            </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className="col-md-6">
                            <div className="form-check form-switch form-switch-custom form-switch-primary mb-3 mt-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="makeDefault"
                                checked={isDefault}
                                onChange={onSetAsDefault}
                              >
                              </input>
                              <label
                                className="form-check-label"
                                htmlFor="makeDefault"
                                style={{
                                  color: isDefault ? 'inherit' : '#878a99',
                                }}
                              >
                                {t('setting.revenueAndProduction.form.makeDefault')}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="text-end">
                            <button
                              onClick={onSaveRecord}
                              type="submit"
                              className="btn btn-primary"
                            >
                              {t('general.btnSave')} {isPending ? '...' : ''}
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
