import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { MaterialReactTable, useMaterialReactTable } from "material-react-table"
import { MRT_Localization_EN } from "material-react-table/locales/en"
import { MRT_Localization_ID } from "material-react-table/locales/id"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { FaRegCheckCircle } from 'react-icons/fa'
import { ImNewTab } from 'react-icons/im'
import { TiWarningOutline } from 'react-icons/ti'
import Swal from "sweetalert2"
import { Box, Tab, Tabs } from "@mui/material"
import { useState } from "react"

function useClientsEmissionCorrectionsLookup() {
  return useQuery({
    queryKey: ['clients-emission-corrections-lookup'],
    queryFn: ({ signal }) => axios({
      url: [
        process.env.REACT_APP_BASE_SERVER,
        '/clients-emission-corrections-lookup',
      ].join(''),
      method: 'GET',
      withCredentials: true,
      signal,
    }).then(response => {
      return response?.data?.data ?? []
    }),
  })
}

function useClientsEmissionCorrectionHistoriesLookup() {
  return useQuery({
    queryKey: ['clients-emission-correction-histories-lookup'],
    queryFn: ({ signal }) => axios({
      url: [
        process.env.REACT_APP_BASE_SERVER,
        '/clients-emission-correction-histories-lookup',
      ].join(''),
      method: 'GET',
      withCredentials: true,
      signal,
    }).then(response => {
      return response?.data?.data ?? []
    }),
  })
}

function useClientsEmissionCorrectionsConfirmation() {
  const query = useQueryClient()

  return useMutation({
    mutationKey: ['clients-emission-corrections-confirmation'], 
    mutationFn: async (data) => axios({
      url: [
        process.env.REACT_APP_BASE_SERVER,
        '/clients-emission-corrections-confirmation',
      ].join(''),
      method: 'POST',
      withCredentials: true,
      data,
    }),
    onSuccess: () => {
      query.invalidateQueries({
        queryKey: [
          'clients-emission-corrections-lookup',
        ],
      })
      query.invalidateQueries({
        queryKey: [
          'clients-emission-correction-histories-lookup',
        ],
      })
    },
  })
}

function useClientsEmissionCorrectionsTable() {
  const { t, i18n } = useTranslation()
  const {
    data: emissionCorrections = [],
    isLoading: isLoadingEmissionCorrections,
    isRefetching: isRefetchingEmissionCorrections,
  } = useClientsEmissionCorrectionsLookup()

  const {
    mutate: requestClientsEmissionCorrectionsConfirmation,
    isPending: isMutatingEmissionCorrections,
  } =
    useClientsEmissionCorrectionsConfirmation()

  return useMaterialReactTable({
    enableExpandAll: false,
    enableGlobalFilter: false,
    enablePagination: false,
    enableTableFooter: false,
    enableTopToolbar: true,
    enableBottomToolbar: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    initialState: { density: 'compact' },
    localization: i18n.language === 'id' ? MRT_Localization_ID : MRT_Localization_EN,
    muiTablePaperProps: {
      elevation: 0,
    },
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: '#F5F6F8',
      }
    },
    muiTableHeadCellProps: {
      sx: {
        fontWeight: 600,
        paddingBlock: '1.5rem',
      }
    },
    muiTableBodyProps: () => ({
      sx: {
        '& > tr:nth-of-type(4n+1) > td': {
          backgroundColor: '#F5F5F5',
        },
      },
    }),
    muiTableBodyRowProps: ({ row, isDetailPanel }) => {
      return {
        sx: !isDetailPanel ? {
          borderBottomWidth: 0,
        } : {
        },
      }
    },
    muiTableBodyCellProps: ({ row }) => {
      return {
        sx: {
          paddingBlock: '1.5rem',
        }
      }
    },
    columns: [
      {
        // header: t("emission.sources.table.source"),
        header: 'Period',
        id: 'period',
        size: 50,
        accessorFn: (row) => {
          return moment(row.date).format('YYYY MMM')
        },
        sortingFn: (rowA, rowB) => {
          const a = rowA.original.date
          const b = rowB.original.date

          if (a > b) {
            return 1
          } else if (b > a) {
            return -1
          } else {
            return 0
          }
        },
      },
      {
        // header: t("emission.sources.table.source"),
        header: 'Asset',
        id: 'asset',
        accessorKey: 'asset',
        size: 50,
        Cell: ({ row, cell }) => {
          return <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {cell.getValue()}
          </span>
        },
      },
      {
        // header: t("emission.sources.table.category"),
        header: 'Category',
        id: 'category',
        // accessorKey:
        //   i18n.language === "id" ? "category_name_id" : "category_name",
        accessorKey: 'category_name',
      },
      {
        // header: t("emission.sources.table.conversion"),
        header: 'Old Emission',
        id: 'current_total',
        accessorFn: (row) => {
          return `${Number((row.current_total).toFixed(3)).toLocaleString()} tCO2e`
        },
      },
      {
        // header: t("emission.sources.table.conversion"),
        header: 'New Emission',
        id: 'new_total',
        accessorFn: (row) => {
          return `${Number(row.new_total.toFixed(3)).toLocaleString()} tCO2e`
        },
      },
    ],
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ table, row }) => {
      return [
        <button
          key={row?.original?.id}
          title="Confirm changes"
          className="btn"
          onClick={isRefetchingEmissionCorrections ||
            isMutatingEmissionCorrections
            ? () => { }
            : () => {
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
              }).then(async (result) => {
                if (result.isConfirmed) {
                  requestClientsEmissionCorrectionsConfirmation({
                    recordIds: [row?.original?.id]
                  }, {
                    onSettled: () => {
                      table.resetRowSelection()
                    },
                  })
                }
              })
            }}
        >
          <FaRegCheckCircle fill="green" fontSize={'1.5rem'} />
          {/* <RiThumbUpLine fill="green" fontSize={'1.5rem'} /> */}
        </button>
      ]
    },
    getRowId: (row) => row.id,
    enableRowSelection: true,
    muiTopToolbarProps: {
      sx: {
        '& > .MuiBox-root': {
          alignItems: 'center',
        },
      }
    },
    renderTopToolbarCustomActions: ({ table }) => {
      return <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          height: '120%',
          alignItems: 'center',
        }}
      >
        <button
          className="btn"
          style={{
            border: '2px solid #E1E1E1',
            paddingBlock: '0.25rem',
            paddingInline: '0.5rem',
            borderRadius: 19,
            fontWeight: 500,
            display: 'flex',
            gap: '0.25rem',
            alignItems: 'center',
            transition: 'opacity',
            opacity: !table.getSelectedRowModel().rows.length ||
              isRefetchingEmissionCorrections ||
              isMutatingEmissionCorrections ? 0.5 : 1.0,
          }}

          onClick={!table.getSelectedRowModel().rows.length ||
            isRefetchingEmissionCorrections ||
            isMutatingEmissionCorrections
            ? () => { }
            : () => {
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
              }).then(async (result) => {
                if (result.isConfirmed) {
                  const selectedRows = table.getSelectedRowModel().rows
                  requestClientsEmissionCorrectionsConfirmation({
                    recordIds: selectedRows.map(row => row.original.id)
                  }, {
                    onSettled: () => {
                      table.resetRowSelection()
                    },
                  })
                }
              })
            }}
        >
          <FaRegCheckCircle fill="green" fontSize={'1rem'} />
          <span>Confirm changes</span>
        </button>
      </div>
    },
    renderDetailPanel: ({ row }) => {
      return <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.25rem',
        }}
      >
        <EmisisonFactorUpdateDetail
          emissionCorrection={row.original}
        />
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem',
            justifyContent: 'end',
            paddingInline: '0.25rem',
          }}
        >
          <i>References:</i>
          {row?.original?.references?.map((reference, index) => {
            return <div key={index}>
              <a
                key={index}
                href={reference.link}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span
                  style={{
                    display: "flex",
                    gap: "0.25rem",
                    alignItems: "center",
                  }}
                >
                  {
                    i18n.language === "id"
                      ? reference.label
                      : reference.label
                  }
                  <ImNewTab />
                </span>
              </a> {index !== row?.original?.references?.length - 1 && ','}
            </div>
          })}
        </span>
      </div>
    },
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => {
        return table.setExpanded({ [row.id]: !row.getIsExpanded() })
      },
    }),
    data: emissionCorrections,
    state: {
      isLoading: isLoadingEmissionCorrections,
      showProgressBars: isRefetchingEmissionCorrections ||
        isMutatingEmissionCorrections,
      isSaving: isMutatingEmissionCorrections,
    }
  })
}

function useClientsEmissionCorrectionHistoriesTable() {
  const { t, i18n } = useTranslation()
  const {
    data: emissionCorrectionHistories = [],
    isLoading: isLoadingEmissionCorrectionHistories,
    isRefetching: isRefetchingEmissionCorrectionHistories,
  } = useClientsEmissionCorrectionHistoriesLookup()

  return useMaterialReactTable({
    enableExpandAll: false,
    enableGlobalFilter: false,
    enablePagination: false,
    enableTableFooter: false,
    enableTopToolbar: true,
    enableBottomToolbar: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    initialState: { density: 'compact' },
    localization: i18n.language === 'id' ? MRT_Localization_ID : MRT_Localization_EN,
    muiTablePaperProps: {
      elevation: 0,
    },
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: '#F5F6F8',
      }
    },
    muiTableHeadCellProps: {
      sx: {
        fontWeight: 600,
        paddingBlock: '1.5rem',
      }
    },
    muiTableBodyProps: () => ({
      sx: {
        '& > tr:nth-of-type(4n+1) > td': {
          backgroundColor: '#F5F5F5',
        },
      },
    }),
    muiTableBodyRowProps: ({ row, isDetailPanel }) => {
      return {
        sx: !isDetailPanel ? {
          borderBottomWidth: 0,
        } : {
        },
      }
    },
    muiTableBodyCellProps: ({ row }) => {
      return {
        sx: {
          paddingBlock: '1.5rem',
        }
      }
    },
    columns: [
      {
        // header: t("emission.sources.table.source"),
        header: 'Period',
        id: 'period',
        size: 50,
        accessorFn: (row) => {
          return moment(row.date).format('YYYY MMM')
        },
        sortingFn: (rowA, rowB) => {
          const a = rowA.original.date
          const b = rowB.original.date

          if (a > b) {
            return 1
          } else if (b > a) {
            return -1
          } else {
            return 0
          }
        },
      },
      {
        // header: t("emission.sources.table.source"),
        header: 'Asset',
        id: 'asset',
        accessorKey: 'asset',
        size: 50,
        Cell: ({ row, cell }) => {
          return <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {cell.getValue()}
          </span>
        },
      },
      {
        // header: t("emission.sources.table.category"),
        header: 'Category',
        id: 'category',
        // accessorKey:
        //   i18n.language === "id" ? "category_name_id" : "category_name",
        accessorKey: 'category_name',
      },
      {
        // header: t("emission.sources.table.conversion"),
        header: 'Old Emission',
        id: 'current_total',
        accessorFn: (row) => {
          return `${Number(row.current_total.toFixed(3)).toLocaleString()} tCO2e`
        },
      },
      {
        // header: t("emission.sources.table.conversion"),
        header: 'New Emission',
        id: 'new_total',
        accessorFn: (row) => {
          return `${Number(row.new_total.toFixed(3)).toLocaleString()} tCO2e`
        },
      },
      {
        // header: t("emission.sources.table.conversion"),
        header: 'User',
        id: 'user',
        accessorKey: 'user_email',
      },
      {
        // header: t("emission.sources.table.conversion"),
        header: 'Confirmed At',
        id: 'confirmed_at',
        accessorFn: (row) => {
          return moment(row.created_at).format('YYYY MMM DD')
        },
      },
    ],
    getRowId: (row) => row.id,
    muiTopToolbarProps: {
      sx: {
        '& > .MuiBox-root': {
          alignItems: 'center',
        },
      }
    },
    renderDetailPanel: ({ row }) => {
      return <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.25rem',
        }}
      >
        <EmisisonFactorUpdateDetail
          emissionCorrection={row.original}
        />
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem',
            justifyContent: 'end',
            paddingInline: '0.25rem',
          }}
        >
          <i>References:</i>
          {row?.original?.references?.map((reference, index) => {
            return <div key={index}>
              <a
                key={index}
                href={reference.link}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span
                  style={{
                    display: "flex",
                    gap: "0.25rem",
                    alignItems: "center",
                  }}
                >
                  {
                    i18n.language === "id"
                      ? reference.label
                      : reference.label
                  }
                  <ImNewTab />
                </span>
              </a> {index !== row?.original?.references?.length - 1 && ','}
            </div>
          })}
        </span>
      </div>
    },
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => {
        return table.setExpanded({ [row.id]: !row.getIsExpanded() })
      },
    }),
    data: emissionCorrectionHistories,
    state: {
      isLoading: isLoadingEmissionCorrectionHistories,
      showProgressBars: isRefetchingEmissionCorrectionHistories,
    }
  })
}

function TabPanel({ children, value, index, ...other }) {
  return <div
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    {...other}
  >
    {value === index && <Box
    // sx={{ p: 3 }}
    >
      {children}
    </Box>}
  </div>
}

function EmisisonFactorUpdateDetail({ emissionCorrection }) {
  const { t, i18n } = useTranslation()

  const data = [
    {
      property: 'Year',
      old: emissionCorrection.current_factor_year || 'No Data',
      new: emissionCorrection.new_factor_year || 'No Data',
    },
    {
      property: 'Global Warming Potential',
      old: emissionCorrection.current_factor_gwp?.toUpperCase() || 'No Data',
      new: emissionCorrection.new_factor_gwp?.toUpperCase() || 'No Data',
    },
    {
      property: 'Emission Factor',
      old: [
        emissionCorrection.current_multiplier,
        `tCO2e/${emissionCorrection.default_unit}`
      ].join(' ') || 'No Data',
      new: [
        emissionCorrection.new_multiplier,
        `tCO2e/${emissionCorrection.default_unit}`
      ].join(' ') || 'No Data',
    },
  ]
  const table = useMaterialReactTable({
    enableGlobalFilter: false,
    enableColumnActions: false,
    enablePagination: false,
    enableTableFooter: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    initialState: { density: 'compact' },
    localization: i18n.language === 'id'
      ? MRT_Localization_ID
      : MRT_Localization_EN,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '7px',
        border: '1px solid #B3B4C6',
      }
    },
    muiTableHeadProps: () => ({
    }),
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: '#F5F6F8',
      }
    },
    muiTableHeadCellProps: {
      sx: {
        fontWeight: 'bold',
        paddingBlock: '1rem',
      }
    },
    muiTableBodyProps: () => ({
      sx: {
        '& :last-child > td': {
          borderBottomWidth: 0,
        },
      },
    }),
    muiTableBodyRowProps: ({ row }) => {
      return {
        sx: {
          backgroundColor: 'white',
        }
      }
    },
    muiTableBodyCellProps: ({ row, cell }) => {
      return {
        sx: {
          paddingBlock: '1rem',
          ...(cell.column.id !== 'new' || row.original.new === row.original.old
            ? {
              backgroundColor: 'white',
            }
            : {
              backgroundColor: 'white',
            }),
        }
      }
    },
    data: data,
    columns: [
      {
        header: '',
        id: 'property',
        size: 50,
        accessorKey: 'property',
        Cell: ({ row }) => {
          return <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {row.getValue('property')}
          </span>
        },
      },
      {
        header: 'Old',
        id: 'old',
        size: 50,
        accessorKey: 'old',
      },
      {
        header: 'New',
        id: 'new',
        size: 50,
        accessorKey: 'new',
        Cell: ({ row, cell }) => {
          return <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '0.5rem',
              alignItems: 'center',
            }}
          >
            <span>
              {cell.getValue()}
            </span>
            {row.original.old !== row.original.new && <TiWarningOutline
              fontSize={'1.5rem'}
            />}
          </div>
        }
      },
    ],
  })

  return <MaterialReactTable table={table} />
}

const EmissionFactorUpdates = () => {
  const { t, i18n } = useTranslation()

  const emissionCorrectionsTable = useClientsEmissionCorrectionsTable()
  const emissionCorrectionHistoriesTable = useClientsEmissionCorrectionHistoriesTable()

  const [tabIndex, setTabIndex] = useState(0)

  return (
    <div
      className="page-content"
      style={{
        minHeight: '111vh',
      }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">{t("emission.corrections.title")}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div
                className="card-body"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.5rem',
                }}
              >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={tabIndex}
                    onChange={(_, newValue) => setTabIndex(newValue)}
                    aria-label="basic tabs example"
                  >
                    <Tab sx={{ textTransform: 'none' }} label="Updates" />
                    <Tab sx={{ textTransform: 'none' }} label="History" />
                  </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0}>
                  <MaterialReactTable table={emissionCorrectionsTable} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <MaterialReactTable table={emissionCorrectionHistoriesTable} />
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmissionFactorUpdates

// TODO: persist the history of confirmed changes
// TODO: ijo jadi abu iconnya?
// TODO: hilangin multirow action kalo misal belum ada selection?