import axios from 'axios'
import Swal from 'sweetalert2'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import inquery from '../assets/trucount/program.png'
import { useState } from 'react'

function Program() {
  const { t, i18n } = useTranslation()
  const account = useSelector((state) => state.account.value)
  const [loading, setLoading] = useState(false)

  const onSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    const data = {
      email: event.target.email.value,
      clientId: account.client_id,
      userId: account.user_id,
    }
    try {
      if (!data.email) {
        throw new Error('Email can not be empty')
      } else {
        const options = {
          withCredentials: true,
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          data,
          url: `${process.env.REACT_APP_BASE_SERVER}/program/create`,
        }
        const response = await axios(options)

        if (response.data.success) {
          Swal.fire({
            icon: 'success',
            title: '',
            html: `<p>${t('program.send.success')}</p>`,
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
        }
      }
    } catch (e) {
      let message = 'Please fill all information needs'
      if (typeof e.response?.data?.message === 'string') {
        message = e.response.data.message
      } else if (e.message) {
        message = e.message
      }
      Swal.fire({
        icon: 'error',
        title: '',
        html: `<p>${message}</p>`,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      })
    } finally {
      setLoading(false)
      event.target.reset()
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-lg-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0">{t('program.label')}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center justify-content-between">
                <div className="col-md-7">
                  {i18n.language === 'en'
                    ? (
                      <h2 className="">
                        {t('program.header.part1')}
                        <span className="text-success"> {t('program.header.part2')} </span>
                        {t('program.header.part3')}
                      </h2>
                    ) : (
                      <h2 className="">
                        {t('program.header.part1')} {t('program.header.part2')}
                        <span className="text-success"> {t('program.header.part3')}</span>
                      </h2>
                    )}
                  <p className="mr-7 text-primary">{t('program.description')}</p>
                  <form onSubmit={onSubmit}>
                    <div className="row">
                      <div className="col-md- col-sm-6 mb-3">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder={t('program.email')}
                          required={true}
                        >
                        </input>
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={loading}
                        >
                          {t('program.send')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="col-md-5 p-4">
                  <div className="w-6">
                    <img
                      src={inquery}
                      alt={t('program.label')}
                      className="img-fluid"
                    >
                    </img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="card">
            <div className="card-body">
              <div className="accordion" id="accordionProgram">
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link"
                      style={{
                        textDecoration: 'none',
                        color: 'initial',
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {t('program.collapse.label1')}
                    </button>
                  </h5>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionProgram"
                  >
                    <div className="card-body text-primary">
                      {t('program.collapse.description1')}
                    </div>
                  </div>
                </div>

                <div className="card-header" id="headingTwo">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link"
                      style={{
                        textDecoration: 'none',
                        color: 'initial',
                      }}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {t('program.collapse.label2')}
                    </button>
                  </h5>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionProgram"
                  >
                    <div className="card-body text-primary">
                      <p>{t('program.collapse.description2')}</p>
                      <p>{t('program.collapse.description3')}</p>
                      <p>{t('program.collapse.description4')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div >
    </div >
  )
}

export default Program
