import { connect } from "react-redux"
import { setAccount } from "./features/account/accountSlice"
import { setCompany } from "./features/account/companySlice"
import { setLoggedIn } from "./features/account/loginSlice"
import { decrement, increment } from "./features/counter/counterSlice"
import { useEffect, useState } from "react"
import { hidePreloader } from "./library/helper"
import cookies from "js-cookie"

import OnboardFreeTier from './pages/OnboardFreeTier'
import Onboard from './pages/Onboard'
import HeaderSection from './components/HeaderSection'

function Guard(props) {
  useEffect(() => {
    const url = process.env.REACT_APP_BASE_SERVER
    const signedInKey = `${new URL(url).hostname}_signedIn`
    props.setLoggedIn(cookies.get(signedInKey))
    const sync = async ({
      changed: changedCookies,
      deleted: deletedCookies,
    }) => {
      if (changedCookies.some(cookie =>
        cookie.name === signedInKey &&
        cookie.value === 'true'
      )) {
        props.setLoggedIn(true)
      }

      if (deletedCookies.some(cookie =>
        cookie.name === signedInKey
      )) {
        props.setLoggedIn(false)
      }
    }

    window.cookieStore?.addEventListener('change', sync)
    return () => window.cookieStore?.removeEventListener('change', sync)
  }, [props])

  const [user, setUser] = useState({})
  useEffect(() => {
    if (props.loggedIn !== 'waiting' && props.loggedIn) {
      const url = process.env.REACT_APP_BASE_SERVER
      const endpoint = `${url}/user-info-lookup`
      fetch(endpoint, {
        credentials: 'include',
        method: 'GET',
      }).then(response => {
        return response.json()
      }).then(response => {
        response.ok && setUser(response.data)
      }).catch(console.log)
    }
  }, [props])

  useEffect(() => {
    if (
      props.loggedIn !== 'waiting' &&
      props.loggedIn &&
      user.id
    ) {
      hidePreloader()
    }
  }, [props, user])

  if (props.loggedIn === 'waiting') {
    return <></>
  } else if (!props.loggedIn) {
    window.location.assign([
      process.env.REACT_APP_ACCOUNT_URL,
      '/login?',
      `redirect_=${window.location.href}`,
    ].join(''))

    return <></>
  } else if (!user.id) {
    return <></>
  } else if (
    !user.clientId || // Free-tier account
    (user.clientSubs === 3 && !user.clientOnboard) // Free-mium Account
  ) {
    return (
      <>
        <HeaderSection user={user} />
        <OnboardFreeTier user={user} setUser={setUser} />
      </>
    )
  } else if (!user.clientOnboard) {
    return (
      <>
        <HeaderSection user={user} />
        <Onboard user={user} setUser={setUser} />
      </>
    )
  } else {
    return props.children
  }
}

const mapStateToProps = (state) => ({
  count: state.counter.value,
  loggedIn: state.loggedIn.value,
  account: state.account.value,
  company: state.company.value,
});

const mapDispatchToProps = {
  increment,
  decrement,
  setLoggedIn,
  setAccount,
  setCompany,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Guard);