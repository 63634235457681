import moment from 'moment'
import { useTranslation } from 'react-i18next'

export default function DateCell({
  dateTime,
  withoutDay = true,
}) {
  const { i18n } = useTranslation()

  let idDateFormat = dateTime
  let enDateFormat = dateTime

  if (dateTime) {
    const idFormat = withoutDay ? 'MMM YYYY' : 'DD MMM YYYY'
    moment.locale('id') // Changing locale to id globally
    idDateFormat = moment(dateTime).format(idFormat)

    const enFormat = withoutDay ? 'YYYY MMM' : 'DD MMM YYYY'
    moment.locale('en') // Changing locale to en globally
    enDateFormat = moment(dateTime).format(enFormat)
  }

  return <>
    {i18n.language === 'id' ? idDateFormat : enDateFormat}
  </>
}
