import {
  useEffect,
  useState
} from 'react'
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'

import axios from 'axios'
import qs from 'qs'
import { withTranslation } from 'react-i18next'

import Swal from 'sweetalert2'

import {
  registerLocale,
} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { id } from 'date-fns/locale'

import 'moment/locale/id'

import './App.css'

import Activity from './pages/Activity'

import Company from './pages/Company'
import CompanyCreate from './pages/CompanyCreate'
import CompanyUpdate from './pages/CompanyUpdate'

import CompanyGroup from './pages/CompanyGroup'
import CompanyGroupCreate from './pages/CompanyGroupCreate'
import CompanyGroupUpdate from './pages/CompanyGroupUpdate'

import Completeness from './pages/Analytic/completeness/Emission'

import Dashboard from './pages/Dashboard'

import Emission from './pages/Emission'
import EmissionCreate from './pages/EmissionCreate'
import EmissionUpdate from './pages/EmissionUpdate'

import EmissionCreateInBulk from './pages/EmissionCreateInBulk'

import Office from './pages/Office'
import OfficeCreate from './pages/OfficeCreate'
import OfficeUpdate from './pages/OfficeUpdate'

import Onboard from './pages/Onboard'

import Program from './pages/Program'

import Revenue from './pages/Revenue'
import RevenueCreate from './pages/RevenueCreate'
import RevenueUpdate from './pages/RevenueUpdate'
import SettingRevenueAndProductionList from './pages/SettingRevenueAndProductionList'
import SettingRevenueAndProductionForm from './pages/SettingRevenueAndProductionForm'

import Setting from './pages/Setting'
import Setting2 from './pages/Setting-2'

import User from './pages/User'
import UserCreate from './pages/UserCreate'
import UserUpdate from './pages/UserUpdate'

import ReportGHG from './pages/ReportGHGInventory'
import ReportGRI from './pages/ReportGRI'
import ReportISO from './pages/ReportISO'
import ReportSummary from './pages/ReportSummary'

import SBTi from './pages/Analytic/SBTi'

import Removal from './pages/Removal'
import RemovalCreate from './pages/RemovalCreate'
import RemovalUpdate from './pages/RemovalUpdate'

import Reduction from './pages/Action/Reduction'
import ReductionCreate from './pages/Action/ReductionCreate'

import Supplychain from './pages/Supplychain'
import SupplychainCreate from './pages/SupplychainCreate'
import SupplychainUpdate from './pages/SupplychainUpdate'

import Scenario from './pages/Analytic/Scenario'
import SettingScenario from './pages/Setting/SettingScenario'
import SettingCarbonIntensity from './pages/Setting/SettingCarbonIntensity'

import AnalyticsCompany from './pages/Analytic/Company'
import AnalyticsLocation from './pages/Analytic/Location'
import AnalyticsLocationSummary from './pages/Analytic/LocationSummary'
import AnalyticsCarbonIntensity from './pages/Analytic/CarbonIntensity'

import Profile from './pages/Profile'

import Scope3Category1And2 from './pages/Scope3/Category1And2SpendBased/Dashboard'
import Scope3Category1And2Create from './pages/Scope3/Category1And2SpendBased/Create'
import Scope3Category1And2Update from './pages/Scope3/Category1And2SpendBased/Update'

import Scope3Category3 from './pages/Scope3/Category3/Dashboard'
// import Scope3Category3Create from './pages/Scope3/Category3/Create'
// import Scope3Category3Update from './pages/Scope3/Category3/Update'

import Scope3Category4And9 from './pages/Scope3/Category4And9/Dashboard'
import Scope3Category4And9Create from './pages/Scope3/Category4And9/Create'
import Scope3Category4And9Update from './pages/Scope3/Category4And9/Update'

import Scope3Category6 from './pages/Scope3/Category6/Dashboard'
import Scope3Category6Create from './pages/Scope3/Category6/Create'
import Scope3Category6Update from './pages/Scope3/Category6/Update'

import Scope3Category7 from './pages/Scope3/Category7/Dashboard'
import Scope3Category7Create from './pages/Scope3/Category7/Create'
import Scope3Category7Update from './pages/Scope3/Category7/Update'

import Scope3Category11 from './pages/Scope3/Category11/Dashboard'
import Scope3Category11Create from './pages/Scope3/Category11/Create'
import Scope3Category11Update from './pages/Scope3/Category11/Update'

import POCEmissionSearch from './pages/POCEmissionSearch'

import { connect } from "react-redux"
import { setAccount } from "./features/account/accountSlice"
import { setCompany } from "./features/account/companySlice"
import { setLoggedIn } from "./features/account/loginSlice"
import { useDispatch } from 'react-redux'

import HeaderSection from './components/HeaderSection'
import MenuSection from './components/MenuSection'

import { ROUTE_PATHS } from './library/helper'

// import OnboardFreeTier from './pages/OnboardFreeTier'
import EmissionSources from './pages/EmissionSources'
import EmissionFactorUpdates from './pages/EmissionFactorUpdates'

registerLocale('id', id)

export default function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    try {
      const persistenceLogin = async () => {
        const data = {}
        const options = {
          withCredentials: true,
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(data),
          url: `${process.env.REACT_APP_BASE_SERVER}/account/login_persistence`
        }
        const response = await axios(options)
        if (response.data.success) {
          localStorage.setItem("name", response.data.data[0].user_name)
          localStorage.setItem("company", response.data.data[0].client_name)
          let company = []
          company['id'] = response.data.data[0].user_client
          company['username'] = response.data.data[0].user_name
          company['company'] = response.data.data[0].client_name
          company['onboard'] = response.data.data[0].client_onboard
          dispatch(setLoggedIn(true))
          dispatch(setAccount(response.data.data[0]))
          dispatch(setCompany(company))
        } else {
          dispatch(setLoggedIn(false))
        }
      }
      persistenceLogin()
    } catch (error) {
      console.error(error)
    }
  }, [])

  const FooterSection = () => {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              &copy;&nbsp;{(new Date().getFullYear())} TruClimate
            </div>
            <div className="col-sm-6">
              <div className="text-sm-end d-none d-sm-block">

              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }

  return (
    <Routes>
      <Route path={ROUTE_PATHS.analyticsLocationSummary} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <LockedContent>
              <AnalyticsLocationSummary />
            </LockedContent>
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.analyticsLocation} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <LockedContent>
              <AnalyticsLocation />
            </LockedContent>
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.analyticsCompany} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <AnalyticsCompany />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.analyticsCarbonIntensity} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <AnalyticsCarbonIntensity />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.reductionCreate} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <ReductionCreate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.reduction} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <LockedContent>
              <Reduction />
            </LockedContent>
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scenario} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <LockedContent>
              <Scenario />
            </LockedContent>
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.settingScenario} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <SettingScenario />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.settingCarbonIntensity} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <SettingCarbonIntensity />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category1} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category1And2 />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category1Create} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category1And2Create />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category1Update} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category1And2Update />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category2} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category1And2 />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category2Create} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category1And2Create />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category2Update} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category1And2Update />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category3} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category3 />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category4} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category4And9 />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category4Create} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category4And9Create />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category4Update} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category4And9Update />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category5} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Dashboard />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category6} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category6 />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category6Create} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category6Create />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category6Update} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category6Update />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category7} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category7 />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category7Create} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category7Create />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category7Update} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category7Update />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category8} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Dashboard />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category9} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category4And9 />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category9Create} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category4And9Create />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category9Update} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category4And9Update />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category10} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Dashboard />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category11} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category11 />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category11Create} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category11Create />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category11Update} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Scope3Category11Update />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category12} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Dashboard />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category13} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Dashboard />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category14} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Dashboard />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category15} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Supplychain />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category15Create} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <SupplychainCreate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.scope3category15Update} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <SupplychainUpdate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.removalUpdateById} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <RemovalUpdate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.removalCreate} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <RemovalCreate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.removal} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <LockedContent>
              <Removal />
            </LockedContent>
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.reportIso} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <LockedContent>
              <ReportISO />
            </LockedContent>
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.reportGhg} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <LockedContent>
              <ReportGHG />
            </LockedContent>
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.reportGri} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <LockedContent>
              <ReportGRI />
            </LockedContent>
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.reportSummary} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <ReportSummary />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.activity} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Activity />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.divisionUpdateById} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <CompanyGroupUpdate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.divisionCreate} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <CompanyGroupCreate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.division} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <CompanyGroup />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.revenueUpdateById} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <RevenueUpdate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.revenueCreate} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <RevenueCreate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.revenue} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Revenue />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.setting} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Setting />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={'/setting-2'} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Setting2 />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.userUpdateById} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <UserUpdate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.userCreate} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <UserCreate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.user} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <User />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.locationUpdateById} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <OfficeUpdate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.locationCreate} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <OfficeCreate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.location} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Office />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.companyUpdateById} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <CompanyUpdate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.companyCreate} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <CompanyCreate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.company} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Company />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.emissionUpdateById} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <EmissionUpdate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.emissionCreate} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <EmissionCreate />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.emissionCreateInBulk} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <EmissionCreateInBulk />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.emission} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Emission />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.dashboard} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Dashboard />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.onboard} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Onboard />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.program} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Program />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.analyticsCompletion} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Completeness />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.profile} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Profile />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={'/setting/advanced/emission-sources'} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <EmissionSources />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={'/emission-factor-updates'} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <EmissionFactorUpdates />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.analyticsSBTi} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <LockedContent>
              <SBTi />
            </LockedContent>
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={'/poc-emission-search'} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <POCEmissionSearch />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.settingRevenueUpdateById} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <SettingRevenueAndProductionForm />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.settingRevenueCreate} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <SettingRevenueAndProductionForm />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path={ROUTE_PATHS.settingRevenue} element={
        <div id="layout-wrapper">
          <HeaderSection />
          <MenuSection />
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <SettingRevenueAndProductionList />
            <FooterSection />
          </div>
        </div>
      } />
      <Route path='*' element={<Navigate to={ROUTE_PATHS.dashboard} />} />
    </Routes>
  )
}

const LockedContent = withTranslation()(connect((state) => ({
  loggedIn: state.loggedIn.value,
  account: state.account.value,
}), { setAccount })(({
  t,
  account,
  setAccount,
  children,
}) => {
  const requestFullAccessRequestSubmission = async (request) => {
    await axios({
      withCredentials: true,
      method: 'POST',
      data: request,
      url: `${process.env.REACT_APP_BASE_SERVER}/full-access-request-submission`
    }).then(async response => {
      if (response.status != 200) {
        const response_ = await response.json();
        const message = response_?.message ?? ''
        Swal.fire({
          icon: 'error',
          title: 'Access Request Failed',
          text: message,
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok'
        })
      } else {
        const response = await axios({
          withCredentials: true,
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify({}),
          url: `${process.env.REACT_APP_BASE_SERVER}/account/login_persistence`
        })

        if (response.data.success) {
          setAccount(response.data.data[0]);
        }
      }
    }).catch(async error => {
      const message = error?.response?.data?.message ?? ''
      Swal.fire({
        icon: 'error',
        title: 'Access Request Failed',
        text: message,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok'
      })
    })
  }

  const [phone, setPhone] = useState()


  if (account.client_subscription != 2) {
    return <div style={{
      position: 'relative',
      height: '100vh',
      overflow: 'hidden',
    }}>
      <div
        className='card subscribe'
      >
        {(() => {
          if (!account?.full_access_request) {
            return <>
              <p
                className='fw-bold fs-4'
                style={{
                  maxWidth: '18rem',
                  fontSize: '0.95rem',
                  textAlign: 'center',
                }}
              >
                {t('locked.content.title.1')}
              </p>
              <p
                id='lockedContentBody'
                // className='w-24'
                style={{
                  maxWidth: '18rem',
                  fontSize: '0.95rem',
                  textAlign: 'justify',
                }}
              >
                {t('locked.content.body.1')}
              </p>
              <form
                disabled
                onSubmit={(event) => {
                  event.preventDefault()
                  requestFullAccessRequestSubmission({ phone })
                }}
              >
                <div className="mb-3">
                  <input
                    className="form-control border border-primary border-1 rounded"
                    placeholder={t('locked.content.phone.placeholder')}
                    type="number"
                    id='phone'
                    name='phone'
                    required
                    disabled={!!account?.full_access_request}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </div>

                <div className='d-flex justify-content-center align-items-center'>
                  <button
                    type="submit"
                    className="btn btn-primary fw-semibold fs-5 px-5"
                    disabled={!!account?.full_access_request}
                  >
                    {t('locked.content.button')}
                  </button>
                </div>
              </form>
            </>
          } else {
            return <>
              <p className='fw-bold fs-4'>
                {t('locked.content.title.2')}
              </p>
              <p className='fs-5 text-center w-25'>
                {t('locked.content.body.2')}
              </p>
            </>
          }
        })()}
      </div>
      {children}
    </div>
  } else {
    return children
  }
}))

