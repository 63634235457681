import {
  useState,
  useEffect,
} from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import html2canvas from 'html2canvas'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import Swal from 'sweetalert2'
import moment from 'moment'

import SingleRowWrapper from '../../components/Dashboard/Layouts/Row'
import MultilineChartCard from '../../components/MultilineChartCard'
import EmissionVsReduction from '../../components/Dashboard/EmissionVsReduction'

const onDownloadImage = async (element, label) => {
  const canvas = await html2canvas(document.getElementById(element))
  const image = canvas.toDataURL('image/png', 1.0)

  const fakeLink = window.document.createElement('a')
  fakeLink.style = 'display:none'
  fakeLink.download = label

  fakeLink.href = image

  document.body.appendChild(fakeLink)
  fakeLink.click()
  document.body.removeChild(fakeLink)

  fakeLink.remove()
}

function ScenarioAnalysis() {
  const account = useSelector((state) => state.account.value)
  const { t } = useTranslation()

  const [startYear, setStartYear] = useState(null)
  const [endYear, setEndYear] = useState(null)

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartYear(newStartDate)
    setEndYear(newEndDate)
  }

  useEffect(() => {
    const controller = new AbortController()

    const getYearRange = async () => {
      try {
        const response = await axios({
          withCredentials: true,
          method: 'GET',
          headers: { 'content-type': 'application/json' },
          url: `${process.env.REACT_APP_BASE_SERVER}/scenario-analysis-initial-year-range-lookup`,
          signal: controller.signal,
        })
        if (response.data.ok) {
          setStartYear(new Date(`${response.data.data.startYear}/01/01`))
          setEndYear(new Date(`${response.data.data.endYear}/01/01`))
        }
      } catch (e) {
        if (
          !e.response?.data?.ok &&
          e.response?.data?.notFound
        ) {
          Swal.fire({
            title: '',
            text: e.response.data.message,
            icon: 'error',
            confirmButtonColor: '#4c638c',
          })
        }
      }
    }
    getYearRange()

    return () => controller.abort()
  }, [])

  return (
    <div className='page-content'>
      <div className='container-fluid'>

        <div className='row'>
          <div className='col-12'>
            <div className='align-items-center d-flex justify-content-between mb-4'>
              <h4 className='mb-sm-0' style={{ color: '#09564B' }}>
                {t('scenario.scenarioAnalysisAbsoluteBasedEmissions')}
              </h4>
              <div style={{ width: '8.5rem' }}>
                <DatePicker
                  style={{ width: 'auto' }}
                  selected={startYear}
                  onChange={handleChange}
                  selectsRange
                  startDate={startYear}
                  endDate={endYear}
                  dateFormat="yyyy"
                  className="form-select"
                  showYearPicker
                  onChangeRaw={e => {
                    if (e.target.value && e.target.value.length === 11) {
                      const dates = e.target.value.split(' - ').map(date => {
                        const d = new Date(date)
                        return !isNaN(d) ? d : null
                      })
                      if (
                        moment(dates[0], 'YYYY-MM-DD').isValid() &&
                        moment(dates[1], 'YYYY-MM-DD').isValid() &&
                        dates[0] < dates[1]
                      ) {
                        setStartYear(dates[0])
                        setEndYear(dates[1])
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {account['client_subscription'] > 1 && (
          <>
            <SingleRowWrapper>
              <MultilineChartCard
                chartId='emissionVsTarget'
                onDownloadImage={onDownloadImage}
                startYear={startYear ? new Date(startYear).getFullYear() : null}
                endYear={endYear ? new Date(endYear).getFullYear() : null}
              />
            </SingleRowWrapper>

            <SingleRowWrapper>
              <EmissionVsReduction
                chartId='emissionVsReduction'
                filterLabel={t('scenario.chart.stackedbar.filterByType')}
                onDownloadImage={onDownloadImage}
                startYear={startYear ? new Date(startYear).getFullYear() : null}
                endYear={endYear ? new Date(endYear).getFullYear() : null}
              />
            </SingleRowWrapper>
          </>
        )}

      </div>
    </div>
  )
}

export default ScenarioAnalysis
