import React, { Component } from 'react'
// eslint-disable-next-line
import { Link, Navigate, Route, Routes } from 'react-router-dom'

import { connect } from "react-redux"
import { increment, decrement } from "../features/counter/counterSlice"
import { setLoggedIn } from "../features/account/loginSlice"
import { setAccount } from "../features/account/accountSlice"
import { setCompany } from "../features/account/companySlice"

import axios from "axios";
import qs from 'qs';
import Swal from 'sweetalert2';

import { hidePreloader, showPreloader } from '../library/helper';
import { withTranslation } from 'react-i18next';

export class Onboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectDashboard: false,

            listIndustry: [],
            listCategory: [],

            companyName: "",
            companyIndustry: 0,
            companyRevenue: 0,
            companyEmployee: "",
            companyAccounting: 0,
            companyGHG: 0,
            companyReductionTarget: 0,
            companyReductionInitiative: 0,

            listActivities: [],

        }
    }

    componentDidMount = async () => {
        showPreloader();

        console.log("onboard");
        
        const optionsClientIndustry = {
            withCredentials: true,
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_SERVER}/client/industry`
        };
        const responseClientIndustry = await axios(optionsClientIndustry);
        let listIndustry = [];
        if (responseClientIndustry.data.success) {
            listIndustry = responseClientIndustry.data.data;
        }
        console.log(listIndustry)

        const optionsGetEmissionCategory = {
            withCredentials: true,
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_SERVER}/emission_factor/category_industry`
        };
        const responseGetEmissionCategory = await axios(optionsGetEmissionCategory);
        let listCategory = [];
        if (responseGetEmissionCategory.data.success) {
            listCategory = responseGetEmissionCategory.data.data
        }
        console.log(listCategory)

        let listActivities = [];
        listCategory.forEach(element => {
            listActivities.push({
                activities_id: element.category_id,
                activities_enable: false,
                activities_available: 0,
                activities_manage: 0,
            })
        });

        this.setState({
            listIndustry: listIndustry,
            listCategory: listCategory,
            listActivities: listActivities,
            companyName: this.props.account['client_name']
        })
        console.log(listActivities)

        console.log("end onboard");

        hidePreloader();
    }

    onChangeCompanyName = async (e) => {
        const companyName = e.target.value;
        this.setState({
            companyName: companyName
        })

        console.log(this.state.companyName)
    }

    onChangeCompanyEmployee = async (e) => {
        const companyEmployee = e.target.value;
        this.setState({
            companyEmployee: companyEmployee
        })

        console.log(this.state.companyEmployee)
    }

    onSelectIndustry = async (e) => {
        let companyIndustry = e.target.value;
        this.setState({
            companyIndustry: companyIndustry
        })
        console.log(this.state.companyIndustry)
    }

    onSelectRevenue = async (e) => {
        let companyRevenue = e.target.value;
        this.setState({
            companyRevenue: companyRevenue
        })
        console.log(this.state.companyRevenue)
    }

    onSaveCompanyInfo = async () => {
        let invalidData = false;
        // eslint-disable-next-line
        if(this.state.companyName == ""){
            invalidData = true
        }
        // eslint-disable-next-line
        if(this.state.companyEmployee == ""){
            invalidData = true
        }

        // eslint-disable-next-line
        if(this.state.companyIndustry == 0){
            invalidData = true
        }
        // eslint-disable-next-line
        if(this.state.companyRevenue == 0){
            invalidData = true
        }

        if(invalidData){
            const { value: confirm } = await Swal.fire({
                title: '',
                html: '<p>Please fill all information needs</p>',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              })
            if(confirm) {
                console.log("not onboarded");
            }
        }else{
            const companyTabButton = document.getElementById("v-pills-bill-info-tab");
            const companyTabBody = document.getElementById("v-pills-bill-info");

            companyTabButton.classList.add("done");
            companyTabButton.classList.remove("active");
            companyTabBody.classList.remove("show");
            companyTabBody.classList.remove("active");

            const emissionTabButton = document.getElementById("v-pills-bill-address-tab");
            const emissionTabBody = document.getElementById("v-pills-bill-address");

            emissionTabButton.classList.add("active");
            emissionTabBody.classList.add("show");
            emissionTabBody.classList.add("active");
        }
    }

    onSelectAccounting = async (e) => {
        let companyAccounting = e.target.value;
        this.setState({
            companyAccounting: companyAccounting
        })
    }

    onSelectGHG = async (e) => {
        let companyGHG = e.target.value;
        this.setState({
            companyGHG: companyGHG
        })
    }

    onSelectCategory = async (e, index) => {
        let data = this.state.listActivities;
        if(data[index].activities_enable){
            data[index].activities_enable = false;
        }else{
            data[index].activities_enable = true;
        }
        this.setState({
            listActivities: data
        })

        console.log(this.state.listActivities)
    }

    onSelectAvailability = async (e, index) => {
        let data = this.state.listActivities;
        data[index].activities_available = e.target.value;
        this.setState({
            listActivities: data
        })

        console.log(this.state.listActivities)
    }

    onSelectManage = async (e, index) => {
        let data = this.state.listActivities;
        data[index].activities_manage = e.target.value;
        this.setState({
            listActivities: data
        })

        console.log(this.state.listActivities)
    }

    onBackCompanyInfo = async () => {
        const companyTabButton = document.getElementById("v-pills-bill-info-tab");
        const companyTabBody = document.getElementById("v-pills-bill-info");

        companyTabButton.classList.remove("done");
        companyTabButton.classList.add("active");
        companyTabBody.classList.add("show");
        companyTabBody.classList.add("active");

        const emissionTabButton = document.getElementById("v-pills-bill-address-tab");
        const emissionTabBody = document.getElementById("v-pills-bill-address");

        emissionTabButton.classList.remove("active");
        emissionTabBody.classList.remove("show");
        emissionTabBody.classList.remove("active");
    }

    onSaveEmissionData = async () => {
        let invalidData = false;
        // eslint-disable-next-line
        if(this.state.companyAccounting == 0){
            invalidData = true
        }
        // eslint-disable-next-line
        if(this.state.companyAccounting == 1){
            // eslint-disable-next-line
            if(this.state.companyGHG == 0){
                invalidData = true
            }
        }
        // eslint-disable-next-line
        if(this.state.companyGHG == 1){
            let listActivities = this.state.listActivities;
            listActivities.forEach(element => {
                if(element.activities_enable){
                    // eslint-disable-next-line
                    if(element.activities_available == 0){
                        invalidData = true;
                    }
                    // eslint-disable-next-line
                    if(element.activities_manage == 0){
                        invalidData = true;
                    }
                }
            });
        }

        if(invalidData){
            const { value: confirm } = await Swal.fire({
                title: '',
                html: '<p>Please fill all information needs</p>',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              })
            if(confirm) {
                console.log("not onboarded");
            }
        }else{
            const emissionTabButton = document.getElementById("v-pills-bill-address-tab");
            const emissionTabBody = document.getElementById("v-pills-bill-address");

            emissionTabButton.classList.add("done");
            emissionTabButton.classList.remove("active");
            emissionTabBody.classList.remove("show");
            emissionTabBody.classList.remove("active");

            const goalTabButton = document.getElementById("v-pills-payment-tab");
            const goalTabBody = document.getElementById("v-pills-payment");

            goalTabButton.classList.add("active");
            goalTabBody.classList.add("show");
            goalTabBody.classList.add("active");
        }
    }

    onSelectReductionTarget = async (e) => {
        let companyReductionTarget = e.target.value;
        this.setState({
            companyReductionTarget: companyReductionTarget
        })
    }

    onSelectReductionInitiative = async (e) => {
        let companyReductionInitiative = e.target.value;
        this.setState({
            companyReductionInitiative: companyReductionInitiative
        })
    }

    onBackEmissionData = async () => {
        const emissionTabButton = document.getElementById("v-pills-bill-address-tab");
        const emissionTabBody = document.getElementById("v-pills-bill-address");

        emissionTabButton.classList.remove("done");
        emissionTabButton.classList.add("active");
        emissionTabBody.classList.add("show");
        emissionTabBody.classList.add("active");

        const goalTabButton = document.getElementById("v-pills-payment-tab");
        const goalTabBody = document.getElementById("v-pills-payment");

        goalTabButton.classList.remove("active");
        goalTabBody.classList.remove("show");
        goalTabBody.classList.remove("active");
    }

    onUpdateCategory = async (element) => {
        let data = null;
        if(element.activities_enable){
            data = {
                'category': element.activities_id,
                'available': 1,
                'manage': element.activities_manage,
            };
        }else{
            data = {
                'category': element.activities_id,
                'available': 3,
                'manage': element.activities_manage,
            };
        }
        const optionUpdate = {
            withCredentials: true,
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(data),
            url: `${process.env.REACT_APP_BASE_SERVER}/client/category`
        };
        const resultUpdate = await axios(optionUpdate);
        console.log(resultUpdate)
    }

    onSubmit = async () => {
        await this.onSaveCompanyInfo();
        await this.onSaveEmissionData();
        
        let invalidData = false;
        // eslint-disable-next-line
        if(this.state.companyReductionTarget == 0){
            invalidData = true
        }
        // eslint-disable-next-line
        if(this.state.companyReductionInitiative == 0){
            invalidData = true
        }

        if(invalidData){
            const { value: confirm } = await Swal.fire({
                title: '',
                html: '<p>Please fill all information needs</p>',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              })
            if(confirm) {
                console.log("not onboarded");
            }
        }else{
            let listActivities = this.state.listActivities;
            listActivities.forEach(element => {
                if(element.activities_enable){
                    this.onUpdateCategory(element);
                }
            });

            const data = {
                'name': this.state.companyName,
                'industry': this.state.companyIndustry,
                'revenue': this.state.companyRevenue,
                'employee': this.state.companyEmployee,
                
                'accounting': this.state.companyAccounting,
                'assess': this.state.companyGHG,
                'target': this.state.companyReductionTarget,
                'initiatives': this.state.companyReductionInitiative,
            };
            const optionsOnboard = {
                withCredentials: true,
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: qs.stringify(data),
                url: `${process.env.REACT_APP_BASE_SERVER}/client/onboard`
            };
            const responseOnboard = await axios(optionsOnboard);
            console.log(responseOnboard);

            if (responseOnboard.data.success) {
                localStorage.setItem("name", responseOnboard.data.data[0].user_name);
                localStorage.setItem("company", responseOnboard.data.data[0].client_name);
                let company = [];
                company['id'] = responseOnboard.data.data[0].user_client;
                company['username'] = responseOnboard.data.data[0].user_name;
                company['company'] = responseOnboard.data.data[0].client_name;
                company['onboard'] = responseOnboard.data.data[0].client_onboard;

                this.props.setAccount(responseOnboard.data.data[0]);
                this.props.setCompany(company);

                this.setState({
                    redirectDashboard: true
                })
                // success
                Swal.fire({
                    title: '',
                    html: '<p>Thankyou</p>',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                })
            }

        }
    }

    render() {
        const { redirectDashboard,
            listIndustry, companyIndustry,
            companyRevenue,
            companyAccounting,
            companyGHG,
            listCategory,
            listActivities,
            companyReductionTarget,
            companyReductionInitiative,
        } = this.state;
        
        if(redirectDashboard){
            return <Navigate to={'/dashboard'} />
        }

        let optionIndustry = [];
        optionIndustry.push(companyIndustry > 0 ? null : <option key={0} value={0}>Select industry</option>)
        // eslint-disable-next-line
        if(listIndustry != undefined) {
            if(listIndustry.length > 0) {
                listIndustry.map(async (item, index) => {
                    optionIndustry.push(<option key={index+1} value={item.industry_id}>{item.industry_name}</option>)
                })
            }
        }

        let optionCategory = [];
        // eslint-disable-next-line
        if(listCategory != undefined) {
            if(listCategory.length > 0) {
                listCategory.map(async (item, index) => {
                    // eslint-disable-next-line
                    if(item.preset_industry == this.state.companyIndustry){
                        optionCategory.push(
                            <tr key={index}>
                                <td>{item.category_name}</td>
                                <td><input onChange={e => this.onSelectCategory(e, index)} checked={listActivities[index].activities_enable} className="form-check-input" type="checkbox"/></td>
                                <td>
                                    <select onChange={e => this.onSelectManage(e, index)} value={listActivities[index].activities_manage} className="form-select" id="companyRevenue" disabled={!listActivities[index].activities_enable}>
                                        {listActivities[index].activities_manage > 0 ? "" : <option value="0">Select option</option>}
                                        <option value="1">Internal</option>
                                        <option value="2">Third Party</option>
                                    </select>
                                </td>
                                <td>
                                    <select onChange={e => this.onSelectAvailability(e, index)} value={listActivities[index].activities_available} className="form-select" id="companyRevenue" disabled={!listActivities[index].activities_enable}>
                                        {listActivities[index].activities_available > 0 ? "" : <option value="0">Select availability</option>}
                                        <option value="1">Monthly</option>
                                        <option value="2">Yearly</option>
                                        <option value="3">None</option>
                                    </select>
                                </td>
                            </tr>
                        )
                    }
                })
            }
        }


        return (
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Onboard</h4>

                                {/*<div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="#page">Pages</a></li>
                                        <li className="breadcrumb-item active">Starter</li>
                                    </ol>
                                </div>*/}

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Preliminary Questions</h4>
                                </div>
                                <div className="card-body form-steps">
                                    <form className="vertical-navs-step">
                                        <div className="row gy-5">
                                            <div className="col-lg-3">
                                                <div className="nav flex-column custom-nav nav-pills" role="tablist" aria-orientation="vertical">
                                                    <button className="nav-link active" id="v-pills-bill-info-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bill-info" type="button" role="tab" aria-controls="v-pills-bill-info" aria-selected="true">
                                                        <span className="step-title me-2">
                                                            <i className="ri-close-circle-fill step-icon me-2"></i> Step 1
                                                        </span>
                                                        Company Info
                                                    </button>
                                                    <button className="nav-link" id="v-pills-bill-address-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bill-address" type="button" role="tab" aria-controls="v-pills-bill-address" aria-selected="false">
                                                        <span className="step-title me-2">
                                                            <i className="ri-close-circle-fill step-icon me-2"></i> Step 2
                                                        </span>
                                                        Emission Data
                                                    </button>
                                                    <button className="nav-link" id="v-pills-payment-tab" data-bs-toggle="pill" data-bs-target="#v-pills-payment" type="button" role="tab" aria-controls="v-pills-payment" aria-selected="false">
                                                        <span className="step-title me-2">
                                                            <i className="ri-close-circle-fill step-icon me-2"></i> Step 3
                                                        </span>
                                                        Goals & Target
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-lg-9">
                                                <div className="px-lg-4">
                                                    <div className="tab-content">
                                                        <div className="tab-pane fade show active" id="v-pills-bill-info" role="tabpanel" aria-labelledby="v-pills-bill-info-tab">
                                                            <div>
                                                                <h5>Company Info</h5>
                                                                {/*<p className="text-muted">Your business activity and size directly correlates with the amount of emissions.</p>*/}
                                                                <p className="text-muted">The amount of emissions your business produces is directly correlated with your business activity and size.</p>
                                                            </div>

                                                            <div>
                                                                <div className="row g-3">
                                                                    <div className="col-md-6">
                                                                        <div>
                                                                            <label htmlFor="companyName" className="form-label">Company Name</label>
                                                                            <input onChange={e => this.onChangeCompanyName(e)} value={this.state.companyName} type="text" className="form-control" id="companyName" placeholder="company name"/>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="companyIndustry" className="form-label">Industry</label>
                                                                            <select onChange={e => this.onSelectIndustry(e)} value={companyIndustry} className="form-select mb-3" id="companyIndustry">
                                                                                {optionIndustry}
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="companyRevenue" className="form-label">Annual Sales Revenue</label>
                                                                            <select onChange={e => this.onSelectRevenue(e)} value={companyRevenue} className="form-select mb-3" id="companyRevenue">
                                                                                {companyRevenue > 0 ? "" : <option value="0">Select amount revenue</option>}
                                                                                <option value="1">Less than $5M</option>
                                                                                <option value="2">$5M - $100M</option>
                                                                                <option value="3">$100M - $500M</option>
                                                                                <option value="4">More than $500M</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <div>
                                                                            <label htmlFor="companyEmployee" className="form-label">Number of Employees</label>
                                                                            <input onChange={e => this.onChangeCompanyEmployee(e)} value={this.state.companyEmployee} type="number" min={1} className="form-control" id="companyEmployee" placeholder="number of employees"/>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr className="my-4 text-muted"/>
                                                            </div>
                                                        
                                                            <div className="d-flex align-items-start gap-3 mt-4">
                                                                <button onClick={() => this.onSaveCompanyInfo()} type="button" className="btn btn-success btn-label right ms-auto nexttab nexttab" data-nexttab="v-pills-bill-address-tab"><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Go to Emission Data</button>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="tab-pane fade" id="v-pills-bill-address" role="tabpanel" aria-labelledby="v-pills-bill-address-tab">
                                                            <div>
                                                                <h5>Emission Data</h5>
                                                                {/*<p className="text-muted">How your company manages data will help calculate your emission</p>*/}
                                                                <p className="text-muted">The way in which your company manages data will have an impact on the calculation of your emissions.</p>
                                                            </div>

                                                            <div>
                                                                <div className="row g-3">
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="companyGHG" className="form-label">Has your company ever performed scope 1 and 2 emission accounting and reporting?</label>
                                                                            <select onChange={e => this.onSelectGHG(e)} value={companyGHG} className="form-select mb-3" id="companyGHG">
                                                                                {companyGHG > 0 ? "" : <option value="0">Select your answer</option>}
                                                                                <option value="1">Yes</option>
                                                                                <option value="2">No</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    {// eslint-disable-next-line
                                                                    companyGHG == 1 ? <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="companyCategory" className="form-label">Are below activities relevant to your business operation?</label>
                                                                            <div className="table-responsive">
                                                                                <table className="table table-hover table-striped align-middle table-nowrap mb-0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col">Activities</th>
                                                                                            <th scope="col"></th>
                                                                                            <th scope="col">Managed / Purchased</th>
                                                                                            <th scope="col">Data Availability</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {optionCategory}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div> : ""}
                                                                    
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="companyAccounting" className="form-label">Does your company have plans to conduct scope 3 emission accounting?</label>
                                                                            <select onChange={e => this.onSelectAccounting(e)} value={companyAccounting} className="form-select mb-3" id="companyAccounting">
                                                                                {companyAccounting > 0 ? "" : <option value="0">Select your answer</option>}
                                                                                <option value="1">Yes</option>
                                                                                <option value="2">No</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr className="my-4 text-muted"/>

                                                            </div>
                                                            <div className="d-flex align-items-start gap-3 mt-4">
                                                                <button onClick={() => this.onBackCompanyInfo()} type="button" className="btn btn-light btn-label previestab" data-previous="v-pills-bill-info-tab"><i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back to Company Info</button>
                                                                <button onClick={() => this.onSaveEmissionData()} type="button" className="btn btn-success btn-label right ms-auto nexttab nexttab" data-nexttab="v-pills-payment-tab"><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>Go to Goals & Target</button>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="tab-pane fade" id="v-pills-payment" role="tabpanel" aria-labelledby="v-pills-payment-tab">
                                                            <div>
                                                                <h5>Goals & Target</h5>
                                                                {/*<p className="text-muted">Your company goals and target.</p>*/}
                                                                <p className="text-muted">Your company goals and targets should align with your overall business strategy and vision.</p>
                                                            </div>

                                                            <div>
                                                                <div className="row gy-3">
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="companyReductionTarget" className="form-label">Does your company have any emissions reduction targets?</label>
                                                                            <select onChange={e => this.onSelectReductionTarget(e)} value={companyReductionTarget} className="form-select mb-3" id="companyReductionTarget">
                                                                                <option value="0">Select your answer</option>
                                                                                <option value="1">Yes</option>
                                                                                <option value="2">No</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <label htmlFor="companyReductionInitiative" className="form-label">Does your company have any emissions reduction activities?</label>
                                                                            <select onChange={e => this.onSelectReductionInitiative(e)} value={companyReductionInitiative}  className="form-select mb-3" id="companyReductionInitiative">
                                                                                <option value="0">Select your answer</option>
                                                                                <option value="1">Yes</option>
                                                                                <option value="2">No</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <hr className="my-4 text-muted"/>
                                                            </div>

                                                            <div className="d-flex align-items-start gap-3 mt-4">
                                                                <button onClick={() => this.onBackEmissionData()} type="button" className="btn btn-light btn-label previestab" data-previous="v-pills-bill-address-tab"><i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back to Emission Data</button>
                                                                <button onClick={() => this.onSubmit()} type="button" className="btn btn-success btn-label right ms-auto nexttab nexttab" data-nexttab="v-pills-finish-tab"><i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i> Submit</button>
                                                            </div>
                                                        </div>

                                                        <div className="tab-pane fade" id="v-pills-finish" role="tabpanel" aria-labelledby="v-pills-finish-tab">
                                                            <div className="text-center pt-4 pb-2">

                                                                <div className="mb-4">
                                                                    
                                                                </div>
                                                                <h5>Your Order is Completed !</h5>
                                                                <p className="text-muted">You Will receive an order confirmation email with details of your order.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
  count: state.counter.value,
  loggedIn: state.loggedIn.value,
  account: state.account.value,
  company: state.company.value,
});

const mapDispatchToProps = { increment, decrement, setLoggedIn, setAccount, setCompany };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Onboard));